import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
const now = new Date();


@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public productsList: any = [];
  public purchaseOrderDetails: any = [];
  public purchaseOrderReceive: any = [];
  public start: any = 0;
  public page: any = 0;
  public currentUser: any;
  public currentUserRole = '';
  public newFormFilter: FormGroup;

  public submitted = false;
  public loadingForm = false;
  public selectBasicLoading = false;
  public vendorDropDown: any = [];
  public selectBasicLoadingWarehouseLocation = false;
  public warehouseLocationDropDown: any = [];


  public pageBasicTextNon = 1;
  public collectionSizeNon = 0;
  public lengthsNon = 30;
  public loadingNon = false;
  public startNon: any = 0;
  public pageNon: any = 0;
  public progressMainTableNon: boolean = false;
  public purchaseOrderListNon: any = [];
  public NonToggleSidebar: boolean = false;
  public purchaseOrderDetailsNon: any = [];
  public purchaseOrderReceiveNon: any = [];
  public reciveOrderForm: FormGroup;
  public reciveNonOrderForm: FormGroup;
  public reciveOrderSubmitted: boolean = false;
  public reciveNonOrderSubmitted: boolean = false;
  public basicDPdata: NgbDateStruct = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  public maxDate: NgbDateStruct;
  public selectedFile: any[] = [];
  public selectedNonFile: any[] = [];

  constructor(private _formBuilder: FormBuilder, private _coreSidebarService: CoreSidebarService, private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private purchaseOrderService: PurchaseOrderService, private modalService: NgbModal) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.productList(this.start, this.lengths);
    this.purchaseNonList(this.startNon, this.lengthsNon);
    this.getVendorDropDown();
    this.getWarehouseLocationDropDown();
    this.newFormFilter = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      order_status: [''],
      vendor_id: [null],
    });

    this.reciveOrderForm = this._formBuilder.group({
      invoice_no: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      invoice_date: ['', [Validators.required]],
      location_id: [null, [Validators.required]],
      invoice_documents: [null],
    });

    this.reciveNonOrderForm = this._formBuilder.group({
      invoice_no: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      invoice_date: ['', [Validators.required]],
      location_id: [null, [Validators.required]],
      invoice_documents: [null],
    });

    this.maxDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };


  }

  get ro() {
    return this.reciveOrderForm.controls;
  }

  get roN() {
    return this.reciveNonOrderForm.controls;
  }

  resetForm() {
    return this.reciveOrderForm.reset();
  }

  resetNonForm() {
    return this.reciveNonOrderForm.reset();
  }

  dateValidator(control: any) {
    let in_date = control.value['year'] + '-' + control.value['month'] + '-' + control.value['day'];
    const date = new Date(in_date);
    const today = new Date();
    return date <= today ? null : { invalidDate: true };
  }

  fileValidator(control: any) {
    const files = control.value;
    return files && files.length > 0 ? null : { noFiles: true };
  }

  onFileChange(event: any) {
    this.selectedFile = [];
    const files = event.target.files;
    // this.reciveOrderForm.patchValue({ invoice_documents: files });
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedFile.push({
          fileName: file.name,
          fileType: file.type,
          data: reader.result
        });
      };
    };
  }

  onNonFileChange(event: any) {
    this.selectedNonFile = [];
    const files = event.target.files;
    // this.reciveOrderForm.patchValue({ invoice_documents: files });
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedNonFile.push({
          fileName: file.name,
          fileType: file.type,
          data: reader.result
        });
      };
    };
  }


  getVendorDropDown() {
    this.purchaseOrderService.getVendor().subscribe(
      data => {
        this.selectBasicLoading = true;
        if (data.status) {
          this.selectBasicLoading = false;
          this.vendorDropDown = data.vendors;
        } else {
          this._toastrService.error(data.errorMessage, 'Oops!',);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getWarehouseLocationDropDown() {
    this.purchaseOrderService.getWarehouseLocation().subscribe(
      data => {
        this.selectBasicLoadingWarehouseLocation = true;
        if (data.status) {
          this.selectBasicLoadingWarehouseLocation = false;
          this.warehouseLocationDropDown = data.warehouse_location;
        } else {
          this._toastrService.error(data.message, 'Oops!',);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  get filter() {
    return this.newFormFilter.controls;
  }

  productList(my_start: any, my_lengths: any) {

    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.purchaseOrderService.getData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, id: any, type: any = 'view') {
    this.loading = false;
    this.purchaseOrderDetails = [];
    this.purchaseOrderReceive = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.purchaseOrderService.getDetails(id).subscribe(
      response => {
        if (response.status) {
          if (type == 'view') {
            this.purchaseOrderDetails = response.purchaseOrder;
          }
          else {
            this.purchaseOrderReceive = response.purchaseOrder;
            this.purchaseOrderReceive.order_details.forEach((meta_value: any, meta_index: any) => {
              this.purchaseOrderReceive.order_details[meta_index].remaining_qty_new = this.purchaseOrderReceive.order_details[meta_index].remaining_qty;
              this.purchaseOrderReceive.order_details[meta_index].receive_qty = 0;
            });
          }
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  countChange(event: any, index: any) {
    this.purchaseOrderReceive.order_details[index].remaining_qty_new = this.purchaseOrderReceive.order_details[index].remaining_qty - event;
    this.purchaseOrderReceive.order_details[index].receive_qty = event;
  }

  onReceiveSave() {

    this.reciveOrderSubmitted = true;
    // stop here if form is invalid
    if (this.reciveOrderForm.invalid) {
      console.log('Form is invalid');
      return;
    }

    let in_date = `${this.ro.invoice_date.value['year']}-${('0' + this.ro.invoice_date.value['month']).slice(-2)}-${('0' + this.ro.invoice_date.value['day']).slice(-2)}`;
    // this.reciveOrderForm.patchValue({ invoice_date: in_date });

    const formdata = new FormData();
    formdata.append('purchaseOrderReceive', this.purchaseOrderReceive);
    const payload = {
      invoice_no: this.reciveOrderForm.get('invoice_no').value,
      invoice_date: in_date,
      location_id: this.reciveOrderForm.get('location_id').value,
      invoice_documents: { ...this.selectedFile },
      ...this.purchaseOrderReceive,
    }

    this.purchaseOrderService.saveReceive(payload).subscribe(
      response => {
        this.selectedFile = [];
        if (response.status) {
          this.reloadList();
          this.modalService.dismissAll();
          this.reciveOrderForm.reset();
          this._toastrService.clear();
          this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }

  orderchangeStatus(orderId: any, status: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to change.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {
        this.purchaseOrderService.orderchangeStatus(orderId, status).subscribe(
          data => {
            if (data.status) {
              this.reloadList();
              this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
            else {
              this._toastrService.error(data.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
            this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Order Status Not Changed',
          'error'
        )
      }
    })
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseOrderService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.productList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Purchase Order Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  download(id: any, index: any = 0) {
    this.purchaseOrderService.downloadPdf(id).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'PO.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this.NonToggleSidebar = false;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }


  /**
  * Submit
  *
  * @param form
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormFilter.invalid) {

    }
    else {
      this.loadingForm = true;
      this.page = 0;
      this.pageBasicText = 1;
      const formdata = new FormData();

      formdata.append('start', "0");
      formdata.append('length', '30');

      if (this.filter.start_date.value !== null) {
        formdata.append('start_date', this.filter.start_date.value);
      }
      if (this.filter.end_date.value !== null) {
        formdata.append('end_date', this.filter.end_date.value);
      }
      if (this.filter.order_status.value !== null) {
        formdata.append('order_status', this.filter.order_status.value);
      }
      if (this.filter.vendor_id.value !== null && this.filter.vendor_id.value !== undefined) {
        formdata.append('vendor_id', this.filter.vendor_id.value);
      }


      this.purchaseOrderService.getData(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.submitted = false;
          if (data.status) {
            this.productsList = data.data;
            this.collectionSize = data.recordsTotal;
            this.toggleSidebar('new-sidebar');
          }
          else {
            this.productsList = [];
            this.collectionSize = 0;
            if (data.error !== undefined) {
              this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }


  /*Non Product Purchase Order Start*/
  purchaseNonList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.purchaseOrderService.getDataNon(formdata).subscribe(
      response => {
        this.loadingNon = false;
        this.progressMainTableNon = false;
        if (response.status) {
          this.purchaseOrderListNon = response.data;
          this.collectionSizeNon = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadListNon() {
    this.progressMainTableNon = true;
    this.purchaseNonList(this.pageNon, this.lengthsNon);
  }

  loadPageNon(event: any) {
    this.progressMainTableNon = true;
    this.pageNon = this.lengthsNon * (event - 1);
    this.purchaseNonList(this.pageNon, this.lengthsNon);
  }

  loadPageLengthsNon(event: any) {
    this.progressMainTableNon = true;
    this.lengthsNon = event.target.value;
    this.purchaseNonList(this.startNon, this.lengthsNon);
  }

  deleteNon(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseOrderService.deleteNon(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.purchaseNonList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Purchase Order Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  orderChangeStatusNon(orderId: any, status: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to change.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {
        this.purchaseOrderService.orderChangeStatusNon(orderId, status).subscribe(
          data => {
            if (data.status) {
              this.reloadListNon();
              this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
            else {
              this._toastrService.error(data.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
            this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Order Status Not Changed',
          'error'
        )
      }
    })
  }

  downloadNon(id: any, index: any = 0) {
    this.purchaseOrderService.downloadPdfNon(id).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'NPO.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Again...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  modalOpenNon(modalBasic, id: any, type: any = 'view') {
    this.loading = false;
    this.purchaseOrderDetailsNon = [];
    this.purchaseOrderReceiveNon = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.purchaseOrderService.getDetailsNon(id).subscribe(
      response => {
        if (response.status) {
          if (type == 'view') {
            this.purchaseOrderDetailsNon = response.purchaseOrder;
          }
          else {
            this.purchaseOrderReceiveNon = response.purchaseOrder;
            this.purchaseOrderReceiveNon.order_details.forEach((meta_value: any, meta_index: any) => {
              this.purchaseOrderReceiveNon.order_details[meta_index].remaining_qty_new = this.purchaseOrderReceiveNon.order_details[meta_index].remaining_qty;
              this.purchaseOrderReceiveNon.order_details[meta_index].receive_qty = 0;
            });
          }
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  toggleSidebarNon(name): void {
    this.NonToggleSidebar = true;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  onSubmitNon() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormFilter.invalid) {

    } else {
      this.loadingForm = true;
      this.pageNon = 0;
      this.pageBasicTextNon = 1;
      const formdata = new FormData();

      formdata.append('start', "0");
      formdata.append('length', '30');

      if (this.filter.start_date.value !== null) {
        formdata.append('start_date', this.filter.start_date.value);
      }
      if (this.filter.end_date.value !== null) {
        formdata.append('end_date', this.filter.end_date.value);
      }
      if (this.filter.order_status.value !== null) {
        formdata.append('order_status', this.filter.order_status.value);
      }
      if (this.filter.vendor_id.value !== null && this.filter.vendor_id.value !== undefined) {
        formdata.append('vendor_id', this.filter.vendor_id.value);
      }


      this.purchaseOrderService.getDataNon(formdata).subscribe(
        response => {
          this.loadingForm = false;
          this.submitted = false;
          if (response.status) {
            this.purchaseOrderListNon = response.data;
            this.collectionSizeNon = response.recordsTotal;
            this.toggleSidebarNon('new-sidebar');
          }
          else {
            this.purchaseOrderListNon = [];
            this.collectionSizeNon = 0;
            if (response.error !== undefined) {
              this._toastrService.error(response.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  countChangeNon(event: any, index: any) {
    this.purchaseOrderReceiveNon.order_details[index].remaining_qty_new = this.purchaseOrderReceiveNon.order_details[index].remaining_qty - event;
    this.purchaseOrderReceiveNon.order_details[index].receive_qty = event;
  }

  onReceiveSaveNon() {

    this.reciveNonOrderSubmitted = true;
    // stop here if form is invalid
    if (this.reciveNonOrderForm.invalid) {
      console.log('Form is invalid');
      return;
    }

    let in_date = `${this.roN.invoice_date.value['year']}-${('0' + this.roN.invoice_date.value['month']).slice(-2)}-${('0' + this.roN.invoice_date.value['day']).slice(-2)}`;
    // this.reciveNonOrderForm.patchValue({ invoice_date: in_date });

    const formdata = new FormData();
    formdata.append('purchaseOrderReceive', this.purchaseOrderReceiveNon);

    const payload = {
      invoice_no: this.reciveNonOrderForm.get('invoice_no').value,
      invoice_date: in_date,
      location_id: this.reciveNonOrderForm.get('location_id').value,
      invoice_documents: { ...this.selectedNonFile },
      ...this.purchaseOrderReceiveNon,
    }

    this.purchaseOrderService.saveReceiveNon(payload).subscribe(
      response => {
        this.selectedNonFile = [];
        if (response.status) {
          this.reloadListNon();
          this.modalService.dismissAll();
          this.reciveNonOrderForm.reset();
          this._toastrService.clear();
          this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  /*Non Product Purchase Order End*/

}
