export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User',
  GeneralManager = 'GeneralManager',
  Purchase = 'Purchase',
  Store = 'Store',
  Production = 'Production',
  SalesMarketing = 'SalesMarketing',
  Molding = 'Molding',
  Tufting = 'Tufting',
  PackagingAssembly = 'PackagingAssembly',
  Dispatch = 'Dispatch',
  HR = 'HR',
  Account = 'Account',
  MoldingPlanning = 'MoldingPlanning',
  TuftingPlanning = 'TuftingPlanning',
  PackagingPlanning = 'PackagingPlanning',
  Planning = 'Planning',

  
}
