import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UnitsService } from 'app/_services/units.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import { NewUnitComponent } from "app/component/units/new-unit/new-unit.component";
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {

  @ViewChild(NewUnitComponent) child;

  unitsList: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public categoryEditData: any = [];
  public loading = false;
  
  constructor(private _router: Router, private _authenticationService: AuthenticationService,private http: HttpClient, private unitsService: UnitsService, private _coreSidebarService: CoreSidebarService) { }

  ngOnInit() {
    this.loading = true;

    this.categoryList(this.start, this.lengths);
  }

  categoryList(my_start: any, my_lengths: any) {
    this.unitsService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          this.unitsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.categoryList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.page = this.lengths * (event - 1);
    this.categoryList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.lengths = event.target.value;
    this.categoryList(this.start, this.lengths);
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  editCat(id: any) {
    this.unitsService.catEdit(id).subscribe(
      response => {
        if (response.status) {
          this.child.unitId = response.unit.id;
          this.child.f.unitName.setValue(response.unit.unit_name);
          this.toggleSidebar('new-sidebar');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {

    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.unitsService.catDelete(id).subscribe(
          response => {
            if (response.status) {
              me.categoryList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Unit Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.error,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

}
