<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Purchase Order</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" autocomplete="off" [formGroup]="newForm">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <label for="vendor_id" class="form-label">Select Vendor<span class="text-danger">*</span></label>
                                            <ng-select formControlName="vendor_id" aria-describedby="vendor_id" tabindex="1" (change)="getRowMaterialProductsDropDowns($event)" [ngClass]="{ 'is-invalid': submitted && f.vendor_id.errors }">
                                                <ng-option disabled selected value="0">Select Vendor</ng-option>
                                                <ng-option *ngFor="let vendor of vendorDropDown" [value]="vendor.id">{{vendor.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.vendor_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.vendor_id.errors.required">Vendor is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">

                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label">Date<span class="text-danger">*</span></label>
                                            <input type="text" readonly formControlName="order_date" id="order_date" placeholder="dd-mm-yyyy" class="form-control" (click)="basicDP.toggle()" [(ngModel)]="basicDPdata" ngbDatepicker #basicDP="ngbDatepicker" aria-describedby="order_date" [ngClass]="{ 'is-invalid': submitted && f.order_date.errors }">
                                            <div *ngIf="submitted && f.order_date.errors" class="invalid-feedback">
                                                <div *ngIf="f.order_date.errors.required">Date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="row" *ngFor="let product of purchaseProducts; let i = index;">

                                        <div class="col-5">
                                            <div class="form-group">
                                                <label>Raw Material Product<span class="text-danger">*</span></label>
                                                <ng-select name="raw_material_product_{{product.id}}" [(ngModel)]="product.raw_material_product" (change)="getPrice($event,i)">
                                                    <ng-option disabled selected value="0">Select Row Material Product</ng-option>
                                                    <ng-option *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown" [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label>Price<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" [(ngModel)]="product.raw_material_product_price" (keyup)="priceChange($event,product.id,i)" name="raw_material_product_price_{{product.id}}" placeholder="Price" value="0" [readonly]="currentUserRole != 'Client' ? true : null" />
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label>Quantity<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" [(ngModel)]="product.quantity" name="quantity_{{product.id}}" (keyup)="qtyChange($event,product.id,i)" placeholder="Quantity" />
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label>Total<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" [(ngModel)]="product.raw_material_product_total" name="raw_material_product_total_{{product.id}}" readonly placeholder="Total" value="0" />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-group">
                                                <br/>
                                                <button type="button" class="btn btn-danger" (click)="remove(i)" *ngIf="i != 0">
                                                        <i  data-feather="trash"></i>
                                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-end">
                                            <button type="button" class="btn btn-outline-success mb-2 float-right" (click)="addMore()"><i  data-feather="plus"></i> Add More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <label>Remarks</label>
                                    <textarea class="form-control" rows="4" id="remarks" placeholder="Remark If Any"></textarea>
                                </div>
                                <div class="col-12">
                                </div>
                                <div class="col-12 text-end">
                                    <button [disabled]="loading" class="btn btn-primary float-right" (click)="onSubmit()" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>