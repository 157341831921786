<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Delivery Challan Report</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0 mb-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                       <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">

                                    <div class="align-items-center justify-content-end pb-md-0">
                                        <button class="btn btn-success ml-1" [disabled]="dowaloadLoder" rippleEffect (click)="download()">
                                            <i data-feather="download" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span *ngIf="dowaloadLoder" class="spinner-border spinner-border-sm mr-1"></span>
                                            <span class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                    </div>

                                    <div class="align-items-center justify-content-end pb-md-0">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Customers Details</th>
                                            <th> Challan No</th>
                                            <th> Amount</th>
                                            <th> Date</th>
                                            <th class="action-column">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="challansList?.length != 0">
                                        <tr *ngFor="let challan of challansList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ challan.customer_name }} <br/> {{ challan.phone }} </td>
                                            <td>{{ challan.invoice_no }}</td>
                                            <td>{{ challan.delivery_amount | number:'1.2-2' }}</td>
                                            <td>{{ challan.delivery_date }}</td>
                                            <td>
                                                <a data-toggle="tooltip" class="btn btn-icon btn-sm btn-primary" (click)="modalOpen(modalBasic,challan.id)" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View Details">
                                                    <i data-feather="eye"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="challansList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="6" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 0" [(page)]="pageAdvancedEllipses" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length != 0">#{{ challanDetails.invoice_no }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length == 0">Delivery Challan Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="challanDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="challanDetails?.length != 0" id="print-section">
                                            <table class="table" style="width:100%;">
                                                <tbody>
                                                    <td style="padding:5px;" *ngIf="currentUser.user.profile_image == null">
                                                        <img src="assets/images/logo/logo-text.png" style="width: 150px; height: 50px;" />
                                                    </td>
                                                    <td style="padding:5px;" *ngIf="currentUser.user.profile_image != null">
                                                        <img [src]="currentUser.user.profile_image" width="100" height="auto" />
                                                    </td>
                                                    <td align="right" style="padding:5px;">
                                                        <h3 style="margin: 2px;">{{ currentUser.user.name }}</h3>
                                                        <h5 style="margin: 2px;">Mobile : {{ currentUser.user.mobile }}</h5>
                                                        <h5 style="margin: 2px;">Email : {{ currentUser.user.email }}</h5>
                                                        <h5 style="margin: 2px;" *ngIf="currentUser.user.is_gst_print == 1">GSTIN : {{ currentUser.user.gst_no | uppercase }}</h5>
                                                    </td>
                                                </tbody>
                                            </table>
                                            <hr class="text-white" style="width:100%;">
                                            <table class="table" style="width:100%;margin-top: 15px;">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding:5px;">
                                                            <b>Name : </b> {{ challanDetails.name }}<br/>
                                                            <b>Phone : </b> {{ challanDetails.phone }}<br/>
                                                            <b>Address :</b> {{ challanDetails.address_one }}
                                                            <span *ngIf="challanDetails.address_two != '' && challanDetails.address_two != null">, {{ challanDetails.address_two }}</span>
                                                            <span *ngIf="challanDetails.address_three != '' && challanDetails.address_three != null">, {{ challanDetails.address_three }}</span>
                                                            <span *ngIf="challanDetails.city_name != '' && challanDetails.city_name != null">, {{ challanDetails.city_name }}</span>
                                                            <span *ngIf="challanDetails.state_name != '' && challanDetails.state_name != null">, {{ challanDetails.state_name }}</span> <br/>
                                                            <b *ngIf="challanDetails.user_gst_no != '' && challanDetails.user_gst_no != null">GSTIN :</b> {{ challanDetails.user_gst_no }}
                                                        </td>
                                                        <td align="right " style="padding:5px; ">
                                                            <b>PO No : </b> #{{ challanDetails.order_details[0].po_number }} <br/>
                                                            <b>Challan No : </b> #{{ challanDetails.invoice_no }} <br/>
                                                            <b>Challan Date : </b> {{ challanDetails.delivery_date }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="col-12 pt-1">

                                                <table class="table table-hover table-striped" style="width:100%;margin-top:15px;">
                                                    <thead>
                                                        <tr style="font-weight:bold;">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Sr.No.</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Product</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">Rate</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Qty</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let challanProduct of challanDetails.delivery_challan_details; let sr = index">
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{sr+1}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{ challanProduct.product_code }} {{ challanProduct.product_name }}
                                                                <small *ngIf="challanProduct.size_title != '' && challanProduct.color_title != ''"><br/>{{ challanProduct.size_title }} {{ challanProduct.color_title }}</small>
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">{{challanProduct.dispatch_price}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{challanProduct.dispatch_qty}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">{{challanProduct.dispatch_sub_total}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="3" *ngIf="currentUser.user.is_show_price == 1" class="text-right">Total</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="2" *ngIf="currentUser.user.is_show_price != 1" class="text-right">Total</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ getSum('dispatch_qty') }}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">{{ getSum('dispatch_sub_total') }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table" style="width:100%;margin-top: 15px;">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="4" align="center">
                                                                <h3>Transport Details</h3>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;width:25%;">
                                                                <b>LR No : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;"><span *ngIf="challanDetails.lr_no != 'null'">{{ challanDetails.lr_no }}</span>
                                                            </td>
                                                            <td style="padding:5px;width:25%;" align="right">
                                                                <b>Transport Name : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.transport_name != 'null'">{{ challanDetails.transport_name }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;width:25%;">
                                                                <b>Mobile : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.mobile != 'null'">{{ challanDetails.mobile }}</span>
                                                            </td>
                                                            <td style="padding:5px;width:25%;" align="right">
                                                                <b>Vehicle No : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.vehicle_no != 'null'">{{ challanDetails.vehicle_no }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;padding-bottom: 15px;" colspan="4">
                                                                <b>Remarks : </b> <span *ngIf="challanDetails.remarks != 'null'">{{ challanDetails.remarks }}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table" style="width:100%;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194)">
                                                                Powered By : Pro Clean
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Delivery Challan Report Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy" class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy" class="form-control">
                        </div>

                        <div class="form-group">
                            <label class="form-label">Customer Name</label>
                            <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" formControlName="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplateStatic let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading "> <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="reloadList()" (click)="toggleSidebar('new-sidebar')" rippleEffect [disabled]="loading "> 
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>                                
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>                                
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->