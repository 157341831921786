import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import Swal from 'sweetalert2';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { ProductsService } from 'app/_services/products.service';

@Component({
  selector: 'app-row-material-stock-maintain-add',
  templateUrl: './row-material-stock-maintain-add.component.html',
  styleUrls: ['./row-material-stock-maintain-add.component.scss']
})
export class RowMaterialStockMaintainAddComponent implements OnInit {

  public loading = false;
  public loadingLoad = false;
  public start: any = 0;
  public pages: any = 0;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public searchByName: any = '';
  public rowMaterialProductList: any = [];
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public error = '';
  public page = 1;
  public allItemsLoaded = false;  // To track if all items are loaded

 constructor(private _router: Router, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService, private http: HttpClient, private rowMaterialProductService: RowMaterialProductService, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.rowStockProductList();
    this.newForm = this._formBuilder.group({
      items: this._formBuilder.array([])
    });
  }

  get items(): FormArray {
    return this.newForm.get('items') as FormArray;
  }

  rowStockProductList() {
    this.loadingLoad = true;
    this.rowMaterialProductService.getData(this.page, this.lengths, this.searchByName).subscribe(
      response => {
        if (response.status) {
          if (response.data.length < this.lengths) {
            this.allItemsLoaded = true;  // No more items to load
          }
          // Correctly append new data to the existing array
          this.rowMaterialProductList = response.data;
          this.page += 1;  // Move to the next page
          this.loadingLoad = false;
          this.loading = false;
          
          //this.items.clear();  // Clear existing form controls
          
          // Loop through the new data and add form controls
          response.data.forEach((value: any) => {
            const itemGroup = this._formBuilder.group({
              id: [value.id],
              product_name: [value.product_name],
              current_stock: [value.current_stock],
              real_stock: ['', Validators.min(0)],
              difference: [{ value: '', disabled: true }],
            });

            // Calculate the difference when real_stock changes
            itemGroup.get('real_stock')?.valueChanges.subscribe(real_stock => {
              const difference = value.current_stock - real_stock;
              itemGroup.get('difference')?.setValue(difference);
            });

            // Add the form group to the form array
            this.items.push(itemGroup);
          });

          //this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error === 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  // reloadList() {
  //   this.loading = true;
  //   this.rowStockProductList(this.page, this.lengths);
  // }

  // loadPage(event: any) {
  //   this.loading = true;
  //   this.page = this.lengths * (event - 1);
  //   this.rowStockProductList(this.page, this.lengths);
  // }

  // loadPageLengths(event: any) {
  //   this.loading = true;
  //   this.lengths = event.target.value;
  //   this.rowStockProductList(this.start, this.lengths);
  // }

  onSubmit(){
    this.submitted = true;
    if (this.newForm.valid) {
      this.loadingForm = true;
      const formData = new FormData();
      this.items.controls.forEach((item, index) => {
        if(item.get('real_stock')?.value != ''){
          formData.append('id[]', item.get('id')?.value);
          formData.append('current_stock[]', item.get('current_stock')?.value);
          formData.append('real_stock[]', item.get('real_stock')?.value);
        }
      });
      this.rowMaterialProductService.addMaintain(formData).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.items.clear();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            //this.rowStockProductList(this.start, this.lengths);
            this.items.clear(); 
            this.rowStockProductList();
          }else {
            this.error = data.error;
          }
        },err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }else {
      return false;
    }
  }

 


  getRawMaterialFilter(searchByName:any) {
    this.searchByName = searchByName;
    this.page = 0;
    this.items.clear(); 
    this.rowStockProductList();
  }

} 
