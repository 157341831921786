import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { OrdersService } from 'app/_services/orders.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-top-customer',
  templateUrl: './top-customer.component.html',
  styleUrls: ['./top-customer.component.scss']
})
export class TopCustomerComponent implements OnInit {

  public topSalesman: any = [];
  public loading: boolean = true;
  public filterForm: FormGroup;

  constructor(private ordersService: OrdersService, private _coreSidebarService: CoreSidebarService, private _formBuilder: FormBuilder, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.loading = false;
    this.filterForm = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      employee_id: [''],
    });
    this.salesmanCustomerList();
  }

  get filter() {
    return this.filterForm.controls;
  }


  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  salesmanCustomerList() {

    this.loading = false;
    if (this.filterForm.invalid) {

    }
    else {
      this.loading = true;
      const formdata = new FormData();

      if (this.filter.start_date.value !== null) {
        formdata.append('start_date', this.filter.start_date.value);
      }
      if (this.filter.end_date.value !== null) {
        formdata.append('end_date', this.filter.end_date.value);
      }
      if (this.filter.employee_id.value !== null) {
        formdata.append('employee_id', this.filter.employee_id.value);
      }


      this.ordersService.getTopCustomerList(formdata).subscribe(
        response => {
          if (response.status) {
            this.loading = false;
            this.topSalesman = response.data;
          } else {
            this.loading = false;
            this._toastrService.error(response.message, 'Oops!',);
          }
        },
        err => {
          this.loading = false;
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

}
