<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Employee List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLengths($event)">
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/factory-employee/add">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressLoading">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>PHONE NO</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="employeeList?.length != 0">
                                        <tr *ngFor="let el of employeeList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ el.name }}</td>
                                            <td>{{ el.mobile }}</td>
                                            <td>{{ el.email }}</td>
                                            <td>{{ el.address }}</td>
                                            <td>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="el.status == 0">Active</span>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="el.status == 1">Deactivate</span>
                                                <span class="badge badge-pill badge-light-danger mr-1" *ngIf="el.status == 2">Block</span>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="modalOpen(modalBasic,el.id)"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                        <a ngbDropdownItem routerLink="/factory-employee/edit/{{ el.id }}"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="deleteEmployee(el.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="employeeList?.length == 0">
                                        <tr *ngIf="!loading" class="text-center">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > lengths" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="userDetails?.length != 0">{{ userDetails.name }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="userDetails?.length == 0">Sales Executives Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="userDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="userDetails?.length != 0">
                                            <div class="card-developer-meetup">

                                                <div class="card-body">
                                                    <div class="meetup-header d-flex align-items-center">
                                                        <div class="meetup-day">
                                                            <div class="avatar bg-primary avatar-lg">
                                                                <div class="avatar-content text-uppercase">{{ userDetails.name.substring(0, 1) }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="my-auto">
                                                            <h4 class="card-title mb-25">{{ userDetails.name }}</h4>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="phone" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{ userDetails.mobile }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="userDetails.email != null && userDetails.email != ''">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="mail" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">{{ userDetails.email }}</h6>
                                                        </div>
                                                    </div>

                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">
                                                                <span *ngIf="userDetails.address_one != null">{{ userDetails.address_one }}</span>
                                                                <span *ngIf="userDetails.address_two != null">, {{ userDetails.address_two }}</span>
                                                                <span *ngIf="userDetails.address_three != null">, {{ userDetails.address_three }}</span>
                                                            </h6>
                                                            <small> 
                                                                <span>{{ userDetails.city_name }}</span>
                                                                <span>, {{ userDetails.state_name }}</span>
                                                                <span *ngIf="userDetails.pincode != null"> - {{ userDetails.pincode }}</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>