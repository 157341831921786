import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
 

@Injectable({ providedIn: 'root' })

export class CategoriesService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-categories`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(category_name: any, categoryId: any): Observable<any> {
    if (categoryId == 0) {
      return this._http
        .post(`${environment.apiUrl}save-categories`, { category_name })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-categories`, { category_name,categoryId })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  catDelete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-categories`, {id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  catEdit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-categories`,{id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}


