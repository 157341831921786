import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router, ActivatedRoute } from '@angular/router';
import { DepartmentService } from 'app/_services/department.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomvalidationService } from 'app/_services/customvalidation.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  public departmentsList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public userDetails: any;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public departmentId = 0;
  public error = '';

  constructor(private _router: Router, private route: ActivatedRoute, private _authenticationService: AuthenticationService, private _formBuilder: FormBuilder, private departmentService: DepartmentService, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private customValidator: CustomvalidationService) { }

  ngOnInit(): void {
    this.loading = true;
    this.departmentsLists(this.start, this.lengths);

    if (this.route.snapshot.params['id'] !== undefined) {
      this.departmentId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.departmentId;
    if (!this.isAddMode) {
      //this.edit(this.departmentId);
      this.newFormAdd = this._formBuilder.group({
        departmentName: ['', Validators.required],
        departmentMobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        departmentEmail: [null, [Validators.required, Validators.email]],
        departmentRole: [null, [Validators.required]],
        newPassword: ['', [Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: ['']
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }
    else {
      this.newFormAdd = this._formBuilder.group({
        departmentName: ['', Validators.required],
        departmentMobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        departmentEmail: [null, [Validators.required, Validators.email]],
        departmentRole: [null, [Validators.required]],
        newPassword: ['', [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: ['', Validators.required]
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }
    this.f.departmentRole.setValue(4);
  }
  get f() {
    return this.newFormAdd.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      this.loadingFrm = true;
      const { departmentName, departmentMobile, departmentEmail, departmentRole, newPassword } = this.newFormAdd.controls;
      this.departmentService.add(departmentName.value, departmentMobile.value, departmentEmail.value, departmentRole.value, newPassword.value,  this.departmentId).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.departmentId = 0
            this.submitted = false;
            this.newFormAdd.reset();
            this.error = '';
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
            this.toggleSidebar('new-sidebar');
          }
          else {
            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  departmentsLists(my_start: any, my_lengths: any) {
    this.departmentService.getData(my_start, my_lengths, '').subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.departmentsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.departmentService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.departmentsLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Department Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }


  reloadList() {
    this.progressMainTable = true;
    this.departmentsLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.departmentsLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.departmentsLists(this.start, this.lengths);
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.submitted = false;
    this.error = '';
    this.loadingFrm = false;
    this.isAddMode = true;
    this.departmentId = 0;

    if (id != 0 && index != null) {
      this.isAddMode = false;

      this.f.departmentName.setValue(this.departmentsList[index].name);
      this.f.departmentMobile.setValue(this.departmentsList[index].mobile);
      this.f.departmentEmail.setValue(this.departmentsList[index].email);
      this.f.departmentRole.setValue(this.departmentsList[index].role);
      this.departmentId = id;
    }

    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  addDepartment(index: any = '') {
    this.toggleSidebar('new-sidebar');
  }

  /**
  * Toggle password
  */
  togglePasswordTextType(me) {
    if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }

}
