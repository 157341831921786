import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ChallanReportService } from 'app/_services/challan-report.service';
import { OrdersService } from 'app/_services/orders.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challan-report',
  templateUrl: './challan-report.component.html',
  styleUrls: ['./challan-report.component.scss']
})
export class ChallanReportComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public challansList: any = [];
  public start: any = 0;
  public page: any = 0;

  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths: any = 30;
  public loading = false;
  public challanDetails: any = [];
  public currentUser: any;
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public allDealer: any;
  public dowaloadLoder: boolean = false;
  public pageAdvancedEllipses = 1;
  public progressMainTable:boolean = false;

  constructor(private _router: Router, private http: HttpClient, private _authenticationService: AuthenticationService, private ordersService: OrdersService, private challanReportService: ChallanReportService, private modalService: NgbModal, private _toastrService: ToastrService, private _formBuilder: FormBuilder, private _coreSidebarService: CoreSidebarService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
  }

  ngOnInit(): void {
    this.loading = true;
    this.challanList(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      order_status: [''],
      dealers: [''],
    });
  }

  get f() {
    return this.newForm.controls;
  }

  challanList(my_start: any, my_lengths: any) {

    const formdata = new FormData();

    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.challanReportService.getData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.challansList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  challanListNew(my_start: any, my_lengths: any) {

    const formdata = new FormData();

    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }

    if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
      formdata.append('dealer_id', this.f.dealers.value.id);
    }

    this.challanReportService.getData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.challansList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.challanList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.challanListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.challanListNew(this.start, this.lengths);
  }


  // modal Basic
  modalOpen(modalBasic, challanId: any) {
    this.challanDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.challanReportService.getChallanDetails(challanId).subscribe(
      response => {
        if (response.status) {
          this.challanDetails = response.delivery_challan;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }
  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.challanDetails.delivery_challan_details.length; i++) {
      sum += this.challanDetails.delivery_challan_details[i][index];
    }
    return sum;
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  /**
   * Submit
   *
   * @param form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;

      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', this.lengths);

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.f.dealers.value.id);
      }

      this.challanReportService.filter(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.submitted = false;
          if (data.status) {
            this.challansList = data.data;
            this.collectionSize = data.recordsTotal;
            this.toggleSidebar('new-sidebar');
          }
          else {
            this.challansList = [];
            this.collectionSize = 0;
            this._toastrService.error(data.error.sales_price, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }



  onChangeSearch(value: string) {
    this.isLoading = true
    this.ordersService.dealerSearchFilter(value).subscribe(
      data => {
        this.isLoading = false;
        if (data.status) {
          this.allDealer = data.dealer_data;
        }
        else {
          this.allDealer = [];
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  selectEvent(item: any) {

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }
  download() {

    this.dowaloadLoder = true;
    const formdata = new FormData();

    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }

    if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
      formdata.append('dealer_id', this.f.dealers.value.id);
    }

    this.challanReportService.downloadReportPdf(formdata).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'challan_reports.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.dowaloadLoder = false;

      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
