import { Component, OnInit } from '@angular/core';
import { HttpClient,} from '@angular/common/http';
import { FactoryEmployeeService } from 'app/_services/factory-employee.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-factory-employee',
  templateUrl: './factory-employee.component.html',
  styleUrls: ['./factory-employee.component.scss']
})
export class FactoryEmployeeComponent implements OnInit {

  
  public start: any = 0;
  public page: any = 0;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressLoading = false;
  public employeeList: any = [];
  public userDetails: any;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private factoryEmployeeService: FactoryEmployeeService, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loading = true;
    this.usersLists(this.start, this.lengths);
  }

  usersLists(my_start: any, my_lengths: any) {
    this.factoryEmployeeService.getData(my_start, my_lengths, '').subscribe(
      response => {
        this.loading = false;
        this.progressLoading = false;
        if (response.status) {
          this.employeeList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  deleteEmployee(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.factoryEmployeeService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressLoading = true;
              me.usersLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Sales Executive Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressLoading = true;
    this.usersLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressLoading = true;
    this.page = this.lengths * (event - 1);
    this.usersLists(this.page, this.lengths);
  }

  loadPageLengths(event: any) {
    this.progressLoading = true;
    this.lengths = event.target.value;
    this.usersLists(this.start, this.lengths);
  }

  modalOpen(modalBasic, userId: any) {
    this.userDetails = [];
    this.modalService.open(modalBasic, {
      size: 'sm'
    });

    this.factoryEmployeeService.dealerViewDetails(userId).subscribe(
      response => {
        if (response.status) {
          this.userDetails = response.employee;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

}
