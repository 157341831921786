import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router, ActivatedRoute } from '@angular/router';
import { VendorService } from 'app/_services/vendor.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {

  public vendorsList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public userDetails: any;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public vendorId = 0;
  public error = '';
  public vendorCategoryIdDropDown: any = [];
  public vendorSubCategoryIdDropDown: any = [];
  public addMode: boolean = true;
  public searchByName: any = '';

  constructor(private _router: Router, private route: ActivatedRoute, private _authenticationService: AuthenticationService, private _formBuilder: FormBuilder, private vendorService: VendorService, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService) { }

  ngOnInit(): void {
    this.loading = true;
    this.vendorsLists(this.start, this.lengths);
    this.getVendorCategoriesDropDown();

    if (this.route.snapshot.params['id'] !== undefined) {
      this.vendorId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.vendorId;
    if (!this.isAddMode) {
      //this.edit(this.departmentId);
      this.newFormAdd = this._formBuilder.group({
        vendorName: ['', Validators.required],
        vendorMobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        vendorMobileTwo: [null, [Validators.pattern('^[0-9]{10}')]],
        vendorMobileThree: [null, [Validators.pattern('^[0-9]{10}')]],
        vendorEmail: [null, [Validators.email]],
        vendorAddress: [null, [Validators.required]],
        vendorGSTno: [null],
        rating: [null],
        vendorCategoryId: [''],
        vendorSubCategoryId: [''],
        vendorPaymentCondition: [''],
      });
    }
    else {
      this.newFormAdd = this._formBuilder.group({
        vendorName: ['', Validators.required],
        vendorMobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        vendorMobileTwo: [null, [Validators.pattern('^[0-9]{10}')]],
        vendorMobileThree: [null, [Validators.pattern('^[0-9]{10}')]],
        vendorEmail: [null, [Validators.email]],
        vendorAddress: [null, [Validators.required]],
        vendorContactPerson: [null],
        vendorGSTno: [null],
        rating: [null],
        vendorCategoryId: [''],
        vendorSubCategoryId: [''],
        vendorPaymentCondition: [''],
      });
    }

  }
  get f() {
    return this.newFormAdd.controls;
  }


  getVendorCategoriesDropDown() {
    this.vendorService.getvendorCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorCategoryIdDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getvendorSubCategoriesDropDown(categoryId: any) {
    this.vendorService.getvendorSubCategoriesDropDown(categoryId).subscribe(
      data => {
        if (data.status) {
          this.vendorSubCategoryIdDropDown = data.categories;
        }
        else {
          this.vendorSubCategoryIdDropDown = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      console.log('error');
    }
    else {
      this.loadingFrm = true;
      const { vendorName, vendorMobile, vendorEmail, vendorAddress, vendorContactPerson, vendorGSTno, rating, vendorCategoryId, vendorMobileTwo, vendorMobileThree, vendorSubCategoryId, vendorPaymentCondition } = this.newFormAdd.controls;
      this.vendorService.add(vendorName.value, vendorMobile.value, vendorEmail.value, vendorAddress.value, vendorContactPerson.value, vendorGSTno.value, rating.value, vendorCategoryId.value, vendorMobileTwo.value, vendorMobileThree.value, vendorSubCategoryId.value, vendorPaymentCondition.value, this.vendorId).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.vendorId = 0
            this.submitted = false;
            this.newFormAdd.reset();
            this.error = '';
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
            this.toggleSidebar('new-sidebar');
          }
          else {
            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
  vendorsLists(my_start: any, my_lengths: any) {
    this.vendorService.getData(my_start, my_lengths, '',this.searchByName).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.vendorsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.vendorService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.vendorsLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Vandor Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }
  reloadList() {
    this.progressMainTable = true;
    this.vendorsLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.vendorsLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.vendorsLists(this.start, this.lengths);
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.submitted = false;
    this.error = '';
    this.loadingFrm = false;
    this.addMode = true;
    this.vendorId = 0;
    if (id != 0 && index != null) {
      this.addMode = false;
      this.f.vendorName.setValue(this.vendorsList[index].name);
      this.f.vendorMobile.setValue(this.vendorsList[index].mobile);
      this.f.vendorMobileTwo.setValue(this.vendorsList[index].mobile_two);
      this.f.vendorMobileThree.setValue(this.vendorsList[index].mobile_three);
      this.f.vendorEmail.setValue(this.vendorsList[index].email);
      this.f.vendorAddress.setValue(this.vendorsList[index].address);
      this.f.vendorPaymentCondition.setValue(this.vendorsList[index].paymentCondition);
      this.f.vendorContactPerson.setValue(this.vendorsList[index].contact_person);
      this.f.vendorGSTno.setValue(this.vendorsList[index].gst_no);
      this.f.rating.setValue(this.vendorsList[index].rating);
      this.f.vendorCategoryId.setValue(this.vendorsList[index].category_id);
      this.vendorId = id;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  addVendor(index: any = '') {
    this.toggleSidebar('new-sidebar');
  }

  getVendorFilter(searchByName) {
    this.searchByName = searchByName;
    this.reloadList();
  }


}
