import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DealersService } from 'app/_services/dealers.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-price-per-customer',
  templateUrl: './price-per-customer.component.html',
  styleUrls: ['./price-per-customer.component.scss']
})
export class PricePerCustomerComponent implements OnInit {

  public dealersList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable:boolean = false;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private http: HttpClient, private dealersService: DealersService) { }

  ngOnInit(): void {
    this.loading = true;
    this.dealerList(this.start, this.lengths);
  }

  dealerList(my_start: any, my_lengths: any) {
    this.dealersService.getData(my_start, my_lengths, 1).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.dealersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.dealerList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.dealerList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.dealerList(this.start, this.lengths);
  }
}


