<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="col p-0">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Outstanding Report</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <div class="row m-0">
                            <div class="form-group col-lg-6 ">
                                <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                    [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplateStatic let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                            <div class="form-group col-lg-6 text-right pt-2 " *ngIf="dealerOutStanding && dealerOutStanding?.length > 0">
                                <button type="button" class="btn btn-success mr-1" printSectionId="pdfTable" ngxPrint><i class="fa fa-print"></i> Print</button>
                                <button class="btn btn-primary" (click)="downloadAsPDF(dealerDetails.name)">Export To PDF</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col p-0" *ngIf="dealerOpeningBalance && dealerOpeningBalance.balance_type" id="pdfTable" #pdfTable>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ dealerDetails.name }}</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <table class="table table-border table-striped">
                            <thead>
                                <tr>
                                    <th>Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th>Refrence &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th>Account Name &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Credit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Debit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Closing Balance &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Opening Balance</th>
                                    <th class="text-right" *ngIf="dealerOpeningBalance.balance_type == 'C'">{{ dealerOpeningBalance.balance | number:'1.2-2' }}</th>
                                    <th></th>
                                    <th class="text-right" *ngIf="dealerOpeningBalance.balance_type == 'D'">{{ dealerOpeningBalance.balance | number:'1.2-2' }}</th>
                                    <th class="text-right">{{ dealerOpeningBalance.balance | number:'1.2-2' }} <span *ngIf="dealerOpeningBalance.balance_type == 'D'">DB</span><span *ngIf="dealerOpeningBalance.balance_type == 'C'">CR</span> </th>
                                </tr>
                                <tr *ngFor="let outStanding of dealerOutStanding; let ndx = index">
                                    <td>{{ outStanding.payment_at }}</td>
                                    <td>{{ outStanding.refrence }}</td>
                                    <td>{{ outStanding.account_name }}</td>
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'C'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <td></td>
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'D'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing < 0"> {{(outStanding.closing | number:'1.2-2' + '').replace('-', '')}} DB </th>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing >= 0">{{ outStanding.closing | number:'1.2-2' }} <span *ngIf="outStanding.closing > 0">CR</span> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>