import { Component, OnInit } from '@angular/core';
import { VendorService } from 'app/_services/vendor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-add',
  templateUrl: './vendor-add.component.html',
  styleUrls: ['./vendor-add.component.scss']
})
export class VendorAddComponent implements OnInit {

  public vendorId: any = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public rowMaterialProductsDropDown: any = [];
  public isAddMode: boolean = true;
  public currentUser: any;
  public currentUserRole = '';
  
  public purchaseProducts: any[] = [{
    id: 1,
    raw_material_product: '0',
    price: '0'
  }];

  constructor(private _authenticationService: AuthenticationService, private purchaseOrderService: PurchaseOrderService, private vendorService: VendorService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
   }

  ngOnInit(): void {

    if (this.route.snapshot.params['id'] !== undefined) {
      this.vendorId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.vendorId;

    if (!this.isAddMode) {
      this.edit(this.vendorId);
    }
    this.getRowMaterialProductsDropDowns(0);
    this.newForm = this._formBuilder.group({
      vendorName: ['', Validators.required],
      vendorMobile: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      vendorMobileTwo: [null, [Validators.pattern('^[0-9]{10}')]],
      vendorMobileThree: [null, [Validators.pattern('^[0-9]{10}')]],
      vendorEmail: [null, [Validators.email]],
      vendorAddress: [null, [Validators.required]],
      vendorGSTno: [null],
      rating: [null],
      vendorCategoryId: [null],
      vendorSubCategoryId: [null],
      vendorContactPerson: [null],
      vendorPaymentCondition: [null],
    });

  }

  edit(id: any) {
    this.vendorService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.vendorId = response.vendor.id;
          this.f.vendorName.setValue(response.vendor.name);
          this.f.vendorMobile.setValue(response.vendor.mobile);
          this.f.vendorMobileTwo.setValue(response.vendor.mobile_two);
          this.f.vendorMobileThree.setValue(response.vendor.mobile_three);
          this.f.vendorEmail.setValue(response.vendor.email);
          this.f.vendorAddress.setValue(response.vendor.address);
          this.f.vendorGSTno.setValue(response.vendor.gst_no);
          this.f.vendorPaymentCondition.setValue(response.vendor.paymentCondition);

          response.vendorRawMaterialProducts.forEach((value: any, index: any) => {
            this.purchaseProducts.push({
              id: this.purchaseProducts.length + 1,
              raw_material_product: value.row_material_product_id,
              price: value.price
            });

          });
          if (response.vendorRawMaterialProducts.length > 0) {
            this.remove(0);
          }
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  addMore() {
    this.purchaseProducts.push({
      id: this.purchaseProducts.length + 1,
      raw_material_product: '0',
      price: '0'
    });
  }

  remove(i: number, productId: any = 0) {
    if (productId != 0) {
      const me = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.value) {
          me.vendorService.deleteVendorRawMaterialProduct(productId, me.vendorId).subscribe(
            data => {
              if (data.status) {
                me.purchaseProducts.splice(i, 1);
                Swal.fire({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Product Deleted Successfully',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                });
              }
              else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: data.message,
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                });
              }
            },
            err => {
              if (err.error.error == 'Unauthenticated.') {
                this._authenticationService.logout();
                this._router.navigate(['/login']);
              }
            });
        }
      });
    }
    else {
      this.purchaseProducts.splice(i, 1);
    }
  }

  get f() {
    return this.newForm.controls;
  }


  getRowMaterialProductsDropDowns(i: any) {
    this.purchaseOrderService.getRowMaterialProductsDropDown('').subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.products;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      console.log('error');
    }
    else {
      this.loading = true;
      const { vendorName, vendorMobile, vendorEmail, vendorAddress, vendorContactPerson, vendorGSTno, rating, vendorCategoryId, vendorMobileTwo, vendorMobileThree, vendorPaymentCondition, vendorSubCategoryId } = this.newForm.controls;
      this.vendorService.add(vendorName.value, vendorMobile.value, vendorEmail.value, vendorAddress.value, vendorContactPerson.value, vendorGSTno.value, rating.value, vendorCategoryId.value, vendorMobileTwo.value, vendorMobileThree.value, vendorSubCategoryId.value, vendorPaymentCondition.value, this.vendorId, this.purchaseProducts,).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.vendorId = 0
            this.submitted = false;
            this.newForm.reset();
            this.error = '';
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/vendors']);

          }
          else {
            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }


}
