import { Component, OnInit } from '@angular/core';
import { FactoryEmployeeService } from 'app/_services/factory-employee.service';
import { FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-factory-employee-add',
  templateUrl: './factory-employee-add.component.html',
  styleUrls: ['./factory-employee-add.component.scss']
})
export class FactoryEmployeeAddComponent implements OnInit {

  public userId = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;

constructor(private _authenticationService: AuthenticationService,  private factoryEmployeeService: FactoryEmployeeService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder, private customValidator: CustomvalidationService) { }

  ngOnInit(): void {
    if (this.route.snapshot.params['id'] !== undefined) {
      this.userId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.userId;
    if (!this.isAddMode) {
      this.edit(this.userId);
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        email: [null, [Validators.email]],
        address: [null, [Validators.required]],
        status: ['0'],
        newPassword: ['', [Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: [''],
        //documents: this._formBuilder.array([]),
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }
    else {
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern('^[0-9]{10}')]],
        email: [null, [Validators.email]],
        address: [null, [Validators.required]],
        status: ['0'],
        newPassword: ['', [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
        confirmPassword: ['', Validators.required],
        //documents: this._formBuilder.array([]),
      }, {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      });
    }
  }

  get f() {
    return this.newForm.controls;
  }

  get documents(): FormArray {
    return this.newForm.get('documents') as FormArray;
  }

  togglePasswordTextType(me) {
    if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.valid) {
      this.loading = true;
      const { name, phone, email, address, newPassword, confirmPassword, status} = this.newForm.controls;
      this.factoryEmployeeService.add(name.value, phone.value, email.value, address.value, newPassword.value, confirmPassword.value, status.value, this.userId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.userId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/factory-employee']);
          }
          else {
            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }else {
      this._toastrService.error('Validation Error', 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
  }


  edit(id: any) {
    this.factoryEmployeeService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.userId = response.employee.id;
          this.f.name.setValue(response.employee.name);
          this.f.phone.setValue(response.employee.mobile);
          this.f.email.setValue(response.employee.email);
          this.f.address.setValue(response.employee.address);
          this.f.status.setValue(response.employee.status);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

}
