<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="collapsible">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Top Salesman & Customer List</h4>

                            <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('filter')">
                                <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Filter</span>
                            </button>
                        </div>
                        <div class="row custom-collapsible">
                            <div class="col-12 col-sm-12 col-md-12 col-lg12">
                                <div class="card-body collapse-icon">
                                    <div class="card-body p-0 pb-1" *ngIf="loading">
                                        <div class="text-center">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div>
                                                Please Wait...
                                            </div>
                                        </div>
                                    </div>
                                    <ngb-accordion [closeOthers]="true" activeIds="accordion-1" *ngIf="!loading">
                                        <ngb-panel id="accordion-{{ndx+1}}"
                                            *ngFor="let ts of topSalesman; let ndx = index">
                                            <ng-template ngbPanelTitle>
                                                <div class="lead collapse-title">
                                                    <div class="d-flex align-items-center justify-content-between pr-2">
                                                        <div>
                                                            {{ts.employee_name}}
                                                        </div>
                                                        <div>
                                                            <span class="badge badge-light-primary mr-1">Orders:
                                                                {{ts.total_order}} </span>
                                                            <span class="badge badge-light-dark">Amounts:
                                                                {{ts.total_amount}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <h5 class="text-danger">Top 10 Customers</h5>
                                                <table
                                                    class="table table-striped table-hover valign-middle text-dark w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Customer</th>
                                                            <th>Order</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let tc of ts.customer; let tcx = index">
                                                            <td>{{tcx+1}}</td>
                                                            <td>{{tc.customer_name}}</td>
                                                            <td>{{tc.total_order}}</td>
                                                            <td>{{tc.total_amount}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <div *ngIf="topSalesman?.length == 0 && !loading">
                                        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                            <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'"
                                                style="width:35%" alt="No Data Found!" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Filter Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="filter" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog modal-xl">
                <form class="add-new modal-content pt-0" [formGroup]="filterForm" (ngSubmit)="salesmanCustomerList()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('filter')">
                        ×
                    </button>

                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Status</label>
                            <ng-select formControlName="employee_id" aria-describedby="employee_id">
                                <ng-option value="">All</ng-option>
                            </ng-select>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                           
                            <a class="btn btn-secondary" (click)="toggleSidebar('filter')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ Filter Sidebar -->