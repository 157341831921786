<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Employee</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Employee</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-name" class="form-label">Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="name" class="form-control" placeholder="Name" aria-describedby="register-name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-phone" class="form-label">Phone Number<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number" minlength=10 maxlength=10 aria-describedby="register-phone" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                            <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-email" class="form-label">Email</label>
                                        <input type="text" formControlName="email" class="form-control" placeholder="Email" aria-describedby="register-email" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.email">Please Enter Valid Email</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-12 ">
                                        <label for="address" class="form-label">Address<span class="text-danger">*</span></label>
                                        <textarea formControlName="address" class="form-control" placeholder="Address" aria-describedby="register-address_one" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" ></textarea>
                                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                            <div *ngIf="f.address.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                  
                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                                        <div class="col-lg-12 p-0">
                                            <label for="register-newPassword" class="form-label">Password<span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                            <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="newPassword" class="form-control form-control-merge" tabindex="12" [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" placeholder="············" aria-describedby="register-newPassword"
                                                autocomplete="off" />

                                            <div class="input-group-append">
                                                <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(2)" ></i>
                                            </span>
                                            </div>
                                            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.newPassword.errors.required">Password is required</div>
                                                <div *ngIf="f.newPassword.touched && f.newPassword.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">

                                        <div class="col-lg-12 p-0">
                                            <label for="register-confirmPassword" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                            <input [type]="passwordTextType3 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" tabindex="13" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" placeholder="············" aria-describedby="register-confirmPassword"
                                                autocomplete="off" />

                                            <div class="input-group-append">
                                                <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType3, 'icon-eye': !passwordTextType3 }" (click)="togglePasswordTextType(3)" ></i>
                                            </span>
                                            </div>
                                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group  col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-status" class="form-label">Status<span class="text-danger">*</span></label>
                                        <select formControlName="status" class="form-control" aria-describedby="register-status" tabindex="14" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                            <option value="0" selected >Active</option>
                                            <option value="1">Deactivate</option>
                                            <option value="2">Block</option>
                                        </select>
                                    </div>

                                    <!-- <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12" formArrayName="items">
                                        <tbody *ngIf="allProductList?.length != 0" >
                                            <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i" >
                                                <td>{{i+1}}</td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <img [src]="item.get('product_thum')?.value" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                        <div *ngIf="item.get('category_name')?.value != '' && item.get('category_name')?.value != null" class="font-small-2 badge badge-light-primary">{{ item.get('category_name')?.value }} </div>
                                                    </div>
                                                    <div class="text-left">
                                                        <b>{{ item.get('product_code')?.value }} {{ item.get('product_name')?.value }}</b>
                                                    </div>
                                                </td>
                                                <td>₹ {{ item.get('sales_price')?.value | number:'1.3-3' }} / {{ item.get('purchase_price')?.value  | number:'1.3-3' }}</td>
                                                <td>{{ item.get('size_title')?.value}}</td>
                                                <td>{{ item.get('color_title')?.value}}</td>
                                                <td *ngIf="item.get('unit')?.value == 'null'"></td>
                                                <td *ngIf="item.get('unit')?.value != 'null'">{{ item.get('unit')?.value }}</td>
                                                <td>{{ item.get('current_stock')?.value }}</td>
                                                <td>
                                                    <input type="number" formControlName="real_stock" class="form-control" placeholder="Real stock"  [ngClass]="{ 'is-invalid': submitted && item.get('real_stock').errors }" minlength="0" />
                                                    <div *ngIf="submitted && item.get('real_stock').errors" class="invalid-feedback">
                                                        <div *ngIf="item.get('real_stock').errors.required">Real stock is required</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="number" formControlName="difference" class="form-control" placeholder="Difference"  [ngClass]="{ 'is-invalid': submitted && item.get('difference').errors }" disabled />
                                                    <div *ngIf="submitted && item.get('difference').errors" class="invalid-feedback">
                                                        <div *ngIf="item.get('difference').errors.required">Difference stock is required</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div> -->

                                    <div class="col col-sm-12 col-xs-12 col-lg-8">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/employees" class="btn btn-secondary btn-block" tabindex="17" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode" tabindex="15" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="!isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>