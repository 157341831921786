<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title text-bold">Customers List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-2 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                       <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-8 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <ng-select [items]="employeeDropDown" [loading]="selectBasicLoading" (change)="getEmployeeFilter($event)" bindValue="emp_id" bindLabel="name" placeholder="Select Sales Executive" formControlName="employee_id" class="mr-1 w-50"> </ng-select>
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Name / Mobile..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getNameFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-md-2 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/dealers/add">
                                      <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                      ><span class="d-none d-sm-inline-block">Add New</span>
                                    </button>
                                    </div>
                                </div>
                                <div class="col-12 d-sm-none d-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <ng-select [items]="employeeDropDown" [loading]="selectBasicLoading" (change)="getEmployeeFilter($event)" bindValue="emp_id" bindLabel="name" placeholder="Select Sales Executive" formControlName="employee_id" class="w-100"> </ng-select>
                                    </div>
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Name / Mobile..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getNameFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Enterprise Name</th>
                                            <th>Owner Detail</th>
                                            <th>City / State</th>
                                            <th>Sales Executive</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="dealersList?.length != 0">
                                        <tr *ngFor="let dealer of dealersList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ dealer.name }}</td>
                                            <td><span class="font-small-2 text-muted font-small-2 badge badge-light-primary" *ngIf="dealer.owner_name != null">{{ dealer.owner_name }} </span> <br *ngIf="dealer.owner_name != null" /> {{ dealer.phone }}</td>
                                            <td>{{ dealer.city_name }} / {{ dealer.state_name }}</td>
                                            <td><span class="font-small-2 text-muted font-small-2 badge badge-light-info" *ngIf="dealer.employee_name != null">{{ dealer.employee_name }} </span> <br *ngIf="dealer.employee_name != null" /> {{ dealer.employee_mobile
                                                }}
                                            </td>
                                            <td>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="dealer.status == 0">Active</span>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="dealer.status == 1">Deactivate</span>
                                                <span class="badge badge-pill badge-light-danger mr-1" *ngIf="dealer.status == 2">Block</span>
                                                <span class="badge badge-pill badge-light-info mr-1" *ngIf="dealer.status == 3">Guest</span>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="paymentAdd(dealer.id,ndx)" *ngIf="dealer.status != 3"><i class="fa fa-rupee mr-50"></i><span>Add Payment</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="modalOpen(modalBasic,dealer.id)"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                        <a ngbDropdownItem routerLink="/dealers/edit/{{ dealer.id }}"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(dealer.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="dealersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr *ngIf="loading">
                                            <td colspan="10" class="no-data-available">
                                                <div class="col">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 30" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                <!-- <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>  -->

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="dealerDetails?.length != 0">{{ dealerDetails.name }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="dealerDetails?.length == 0">Customer Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="dealerDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="dealerDetails?.length != 0">
                                            <div class="card-developer-meetup">
                                                <div class="card-body">
                                                    <div class="meetup-header d-flex align-items-center">
                                                        <div class="meetup-day">
                                                            <div class="avatar bg-primary avatar-lg">
                                                                <div class="avatar-content text-uppercase">{{ dealerDetails.name | initials }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="my-auto">
                                                            <h4 class="card-title mb-25">{{ dealerDetails.name }}</h4>
                                                            <p class="card-text mb-0">{{ dealerDetails.owner_name }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="smartphone" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">{{ dealerDetails.phone }}</h6>
                                                            <small *ngIf="dealerDetails.alternate_phone != null">{{ dealerDetails.alternate_phone }}</small>
                                                            <small *ngIf="dealerDetails.alternate_phone == null"></small>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="dealerDetails.landline != null">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="phone" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">{{ dealerDetails.landline }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="dealerDetails.email != null">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="mail" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">{{ dealerDetails.email }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media" *ngIf="dealerDetails.gst_no != null && dealerDetails.gst_no != ''">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="percent" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0" style="padding-top:7px;">GSTIN : {{ dealerDetails.gst_no }}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="media">
                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                            <div class="avatar-content">
                                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                                            </div>
                                                        </div>
                                                        <div class="media-body">
                                                            <h6 class="mb-0">
                                                                <span *ngIf="dealerDetails.address_one != null">{{ dealerDetails.address_one }}</span>
                                                                <span *ngIf="dealerDetails.address_two != null">, {{ dealerDetails.address_two }}</span>
                                                                <span *ngIf="dealerDetails.address_three != null">, {{ dealerDetails.address_three }}</span>
                                                            </h6>
                                                            <small> 
                                                                <span>{{ dealerDetails.city_name }}</span>
                                                                <span>, {{ dealerDetails.state_name }}</span>
                                                                <span *ngIf="dealerDetails.pincode != null"> - {{ dealerDetails.pincode }}</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormPayment" (ngSubmit)="onSubmitPayment()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Customer Outstanding</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="bs-stepper-content">
                            <!-- Checkout Place order starts -->
                            <div id="step-cart" class="content">
                                <div id="place-order" class="list-view product-checkout">
                                    <!-- Checkout Place Order Left starts -->
                                    <div class="checkout-items">
                                        <div class="row ecommerce-card">
                                            <div class="item-img col-3 col-lg-2">
                                                <div class="avatar avatar-new bg-primary avatar-lg">
                                                    <div class="avatar-content text-uppercase">{{ delerdetails.name | initials }}</div>
                                                </div>
                                            </div>
                                            <div class="card-body col-9 col-lg-10 pt-0">
                                                <div class="item-name ">
                                                    <h5 class="mb-0 ">
                                                        {{ delerdetails.name }}
                                                    </h5>
                                                    <span class="item-company">{{ delerdetails.phone }}</span>
                                                    <h6>Balance : ₹ {{ delerdetails.current_balance | number:'1.2-2' }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Payment Choice<span class="text-danger">*</span></label>
                            <select id="payment_choice" formControlName="payment_choice" class="form-control" [ngClass]="{ 'is-invalid': submittedPayment && f.payment_choice.errors }">
                                <option value="" selected >Select Payment Choice</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="Bhim UPI">Bhim UPI</option>
                                <option value="G Pay">G Pay</option>
                                <option value="Phone Pay">Phone Pay</option>
                                <option value="Paytm">Paytm</option>
                                <option value="Other">Other</option>                                
                            </select>
                            <div *ngIf="submittedPayment && f.payment_choice.errors" class="invalid-feedback">
                                <div *ngIf="f.payment_choice.errors.required">Payment Choice is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Paying Amount<span class="text-danger">*</span></label>
                            <input type="number" id="paying_amount" formControlName="paying_amount" value="0" placeholder="Paying Amount" class="form-control" [ngClass]="{ 'is-invalid': submittedPayment && f.paying_amount.errors }">
                            <div *ngIf="submittedPayment && f.paying_amount.errors" class="invalid-feedback">
                                <div *ngIf="f.paying_amount.errors.required">Paying Amount is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Remarks</label>
                            <textarea id="remarks" rows="2" formControlName="remarks" placeholder="Remarks If Any..." class="form-control"></textarea>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrmPayment"> <span *ngIf="loadingFrmPayment" class="spinner-border spinner-border-sm mr-1 "></span>Save</button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>


                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">
                                <div class="overlay" *ngIf="progressPaymentTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th> Amount</th>
                                            <th> Payment At</th>
                                            <th> Remarks</th>
                                            <th> #</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="paymentList?.length != 0">
                                        <tr *ngFor="let payment of paymentList; let ndx = index">
                                            <td>{{ payment.amount | number:'1.2-2' }}</td>
                                            <td>{{ payment.payment_at }}</td>
                                            <td>{{ payment.remarks }}</td>
                                            <td>
                                                <a class="btn btn-icon btn-sm btn-xs btn-warning d-none" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                                <a class="btn btn-icon btn-sm btn-xs btn-danger" (click)="deletePayment(payment.id)" style="margin-top:5px;" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="paymentList?.length == 0">
                                        <tr *ngIf="!loadingPayment">
                                            <td colspan="4" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="no-data-available">
                                                <div class="col" *ngIf="loadingPayment">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSizePayment > 5" [collectionSize]="collectionSizePayment" [pageSize]="lengthsPayment" [(page)]="pagePaymentBasicText" (pageChange)="loadPaymentPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->
<!--/ New User Sidebar -->