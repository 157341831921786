import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}order-report`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-order`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  dealerSearch(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}search-dealer`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  dealerSearchFilter(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}search-filter-dealer`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  productSearch(id: any, searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-manufacturer-products`, { id, searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  storeOrder(id: any, orderData: any, remarks: any,poNumber:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}manufacturers-direct-order`, { id, orderData, remarks,poNumber })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getOrderDetails(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}view-order-details`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  orderchangeStatus(id: string, status: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-order-status`, { status,id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getReportData(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}order-report`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  filter(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}order-report`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  getOrderEditDetails(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}manufacturers-edit-order`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  updateOrderSave(orderData, remarks, id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}manufacturers-update-order`, { orderData, remarks,id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  downloadPdf(id: string): Observable<Blob> {
    return this._http.post<Blob>(`${environment.apiUrl}get-order-pdf`,{ id }, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }


  downloadReportPdf(formdata): Observable<Blob> {
    return this._http
      .post<Blob>(`${environment.apiUrl}get-order-report-pdf`, formdata, {
        headers: headers, responseType:
          'blob' as 'json'
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  productSearchAll(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-manufacturer-products-all`, {  searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getEmployeeList(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-employees-list`)
      .pipe(
      map(data => {
        return data;
      })
    );
  }

  getTopCustomerList(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}top-ten-salesman-list`)
      .pipe(
      map(data => {
        return data;
      })
    );
  }
}

