import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GatePassService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-gate-pass`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDataFilter(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-gate-pass`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  notifyData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}notification-gate-pass`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  receiveGatePass(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}receive-gate-pass`, data)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  removeProduct(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-gate-pass-product`, data)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getGatePassStock(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}receive-stock-gate-pass`, data)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(data: any, id): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-gate-pass`, data)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-gate-pass`, data)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-gate-pass`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-gate-pass`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
