import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DeliveryChallanService } from 'app/_services/delivery-challan.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-outstanding-report',
  templateUrl: './outstanding-report.component.html',
  styleUrls: ['./outstanding-report.component.scss']
})
export class OutstandingReportComponent implements OnInit {

  @ViewChild('auto') auto: any;
  @ViewChild('pdfTable') pdfTable: ElementRef;

  constructor(private _authenticationService: AuthenticationService, private http: HttpClient, private deliveryChallanService: DeliveryChallanService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { }

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public dealerId: any = 0;
  public dealerDetails: any = '';
  public allDealer: any;
  public loadingReport: boolean = false;
  public dealerOutStanding: any = [];
  public dealerOpeningBalance: any;

  downloadAsPDF(name:any = '') {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download('outstanding-report-'+name+'.pdf');
  }

  ngOnInit(): void {
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.deliveryChallanService.dealerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allDealer = data.dealer_data;
        }
        else {
          this.isLoading = false;
          this.allDealer = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allDealer = [];
      }
    );
  }

  selectEvent(item: any) {
    this.dealerId = item.id;
    this.dealerDetails = item;
    this.dealerOpeningBalance = '';
    this.dealerOutStanding = [];
    this.loadReport();
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  loadReport() {
    if (this.dealerId > 0) {
      this.loadingReport = true;
      this.dealerOpeningBalance = '';
      this.dealerOutStanding = [];

      this.deliveryChallanService.loadOutStandingReport(this.dealerId).subscribe(
        data => {
          this.loadingReport = false;
          if (data.status) {
            let total = 0;
            if (data.opening_balance.balance_type == 'D') {
              total = -data.opening_balance.balance;
            }
            else {
              total = data.opening_balance.balance;
            }
            data.data.forEach((value: any, index: any) => {
              if (value.payment_type == "C") {
                total += value.amount;
              }
              else {
                total -= value.amount;
              }
              data.data[index].closing = total;
            });

            this.dealerOpeningBalance = data.opening_balance;
            this.dealerOutStanding = data.data;

          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.dealerOpeningBalance = '';
          this.dealerOutStanding = [];
          this.loadingReport = false;
        }
      );

    }

  }

}
