import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ProductionGeneralService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getOperatorsDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-operator-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getMachinesDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-machine-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  productionSubmitRemark(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}production-submit-remark`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getcolorsDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-color-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  /* START :: For Production Given */
  givenAdd(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-production-given`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  givenList(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-production-given`, {start, length})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  givenDelete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-production-given`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  /* END :: For Production Given */

  /* START :: For Production Submit */

  getGivenBatchsDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}production-given-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  submitAdd(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-production-submit`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }  

  submitList(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-production-submit`, {start, length})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDepartmentsDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-department-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDepartmentsTransferDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-department-transfer-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

   /* END :: For Production Submit */

   stockTransfer(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}department-wise-stock-transfer`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }  

}
