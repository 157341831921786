<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add New Stock</h4>
                        </div>
                        <div class="card-body pb-1">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="row" *ngFor="let product of purchaseProducts; let i = index;">
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Raw Material Category<span class="text-danger">*</span></label>
                                                <ng-select name="row_material_category_{{product.id}}"
                                                    (change)="getRowMaterialProductsDropDowns($event,i)"
                                                    [(ngModel)]="product.row_material_category">
                                                    <ng-option disabled selected value="0">Select Raw Material
                                                        Category</ng-option>
                                                    <ng-option
                                                        *ngFor="let rowMaterialCategory of rowMaterialCategoryDropDown"
                                                        [value]="rowMaterialCategory.id">{{rowMaterialCategory.category_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-group">
                                                <label>Raw Material Product<span class="text-danger">*</span></label>
                                                <ng-select name="raw_material_product_{{product.id}}"
                                                    [(ngModel)]="product.raw_material_product">
                                                    <ng-option disabled selected value="0">Select Raw Material
                                                        Product</ng-option>
                                                    <ng-option
                                                        *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown"
                                                        [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-group">
                                                <label>Qty<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" [(ngModel)]="product.quantity"
                                                    name="quantity_{{product.id}}" placeholder="Quantity" />
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-group">
                                                <br />
                                                <button type="button" class="btn btn-danger" (click)="remove(i)"
                                                    *ngIf="i != 0">
                                                    <i data-feather="trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-end">
                                            <button type="button" class="btn btn-outline-success mb-2 float-right"
                                                (click)="addMore()"><i data-feather="plus"></i> Add More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-end">
                                    <button [disabled]="loading" class="btn btn-primary float-right"
                                        (click)="onSubmit()" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>