<div class="slideout-content">
    <!-- Modal to add new starts-->
    <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmit()">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                  ×
                </button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">New Unit</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <div class="form-group">
                        <label for="register-unitName" class="form-label">Unit Name<span class="text-danger">*</span> </label>
                        <input type="text" formControlName="unitName" class="form-control" placeholder="Unit Name" aria-describedby="register-unitName" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.unitName.errors }" />
                        <div *ngIf="submitted && f.unitName.errors" class="invalid-feedback">
                            <div *ngIf="f.unitName.errors.required">Unit Name is required</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mr-1" rippleEffect [disabled]="loading"> <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                    <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                        <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                        <span class="d-none d-sm-inline-block">Cancel </span>                        
                  </button>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add new Ends-->
</div>