import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ProfileService } from 'app/_services/profile.service';
import { DealersService } from 'app/_services/dealers.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public error = '';
  public profile: any = '';
  public newForm: FormGroup;
  public sms_alert: any = 0;
  public stock_alert: any = 0;
  public gst_display: any = 0;
  public price_display: any = 0;
  public is_deduct_stock: any = 0;
  public is_active_sms_customer: any = 0;
  public is_deduct_stock_order: any = 0;
  public order_master_link: any = 0;
  public submitted = false;
  public loading = false;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private dealersService: DealersService, private clipboardApi: ClipboardService, private _toastrService: ToastrService, private http: HttpClient, private profileService: ProfileService, private _formBuilder: FormBuilder, private customValidator: CustomvalidationService) { }

  ngOnInit(): void {
    this.getProfile();
    this.newForm = this._formBuilder.group({
      sms_alert: [''],
      stock_alert: [''],
      gst_display: [''],
      price_display: [''],
      is_deduct_stock: [''],
      is_active_sms_customer: [''],
      is_deduct_stock_order: [''],
      order_master_link: ['']
    });
  }
  get f() {
    return this.newForm.controls;
  }

  getProfile() {
    this.profileService.getProfileSettingData().subscribe(
      response => {
        if (response.status) {
          this.profile = response.settings;
          this.f.sms_alert.setValue(response.settings.is_active_sms);
          this.f.stock_alert.setValue(response.settings.is_stock_handle);
          this.f.gst_display.setValue(response.settings.is_gst_print);
          this.f.price_display.setValue(response.settings.is_show_price);
          this.f.is_deduct_stock.setValue(response.settings.is_deduct_stock);
          this.f.is_active_sms_customer.setValue(response.settings.is_active_sms_customer);
          this.f.is_deduct_stock_order.setValue(response.settings.is_deduct_stock_order);
          this.f.order_master_link.setValue(response.settings.order_master_link);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
    }
    else {
      this.loading = true;
      this.sms_alert = (this.f.sms_alert.value == true) ? 1 : 0;
      this.stock_alert = (this.f.stock_alert.value == true) ? 1 : 0;
      this.gst_display = (this.f.gst_display.value == true) ? 1 : 0;
      this.price_display = (this.f.price_display.value == true) ? 1 : 0;
      this.is_deduct_stock = (this.f.is_deduct_stock.value == true) ? 1 : 0;
      this.is_active_sms_customer = (this.f.is_active_sms_customer.value == true) ? 1 : 0;
      this.is_deduct_stock_order = (this.f.is_deduct_stock_order.value == true) ? 1 : 0;
      this.order_master_link = (this.f.order_master_link.value == true) ? 1 : 0;

      const formdata = new FormData();

      formdata.append('is_active_sms', this.sms_alert);
      formdata.append('is_stock_handle', this.stock_alert);
      formdata.append('is_gst_print', this.gst_display);
      formdata.append('is_show_price', this.price_display);
      formdata.append('is_deduct_stock', this.is_deduct_stock);
      formdata.append('is_active_sms_customer', this.is_active_sms_customer);
      formdata.append('is_deduct_stock_order', this.is_deduct_stock_order);
      formdata.append('order_master_link', this.order_master_link);
      
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    
      currentUser.user.is_stock_handle =this.stock_alert;
      currentUser.user.is_gst_print = this.gst_display;
      currentUser.user.is_show_price = this.price_display;
      localStorage.setItem("currentUser", JSON.stringify(currentUser));

      this.profileService.saveProfileSetting(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.getProfile();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  stockCheckbox(type:any)
  {
    if(type == 1){
      this.f.is_deduct_stock_order.setValue(0);
    }
    else {
      this.f.is_deduct_stock.setValue(0);
    }
  }

}
