import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ProductsService } from 'app/_services/products.service';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-product-wise-raw-material-stock',
  templateUrl: './product-wise-raw-material-stock.component.html',
  styleUrls: ['./product-wise-raw-material-stock.component.scss']
})
export class ProductWiseRawMaterialStockComponent implements OnInit {

  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allProduct: any;
  public loading: boolean = false;
  public stockDetails:any= [];
  public productId: any = 0;
  public orderQty: any = 0;

  constructor(private _authenticationService: AuthenticationService, private productMasterComboService: ProductMasterComboService, private productsService: ProductsService, private http: HttpClient, private ordersService: OrdersService, private _toastrService: ToastrService, private _router: Router) { }

  ngOnInit(): void {  }

  onChangeSearchProduct(value: string) {
    if (value.length >= 3) {
      this.isLoadingProduct = true
      this.ordersService.productSearchAll(value).subscribe(
        data => {
          this.isLoadingProduct = false
          if (data.status) {
            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }
  selectEventProduct(item: any) {
    this.productId = item.id;
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  getDetails() {
    this.loading = true;
    this.stockDetails = [];
    this.productMasterComboService.viewDetails(this.productId).subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          response.details.comboProducts.forEach((value: any, index: any) => {
            value.require = this.orderQty * value.useQuantity;
            value.need = value.require - value.current_stock;
            if(value.need < 0)
            {
              value.need = 0;
            }
          });
          this.stockDetails = response.details;          
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  countChange(qty: any) {
    this.orderQty = qty;
    this.stockDetails = [];    
  }
}
