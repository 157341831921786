import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RowMaterialSubCategoryService {

  /**
  *
  * @param {HttpClient} _http
  */
   constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

   getData(start, length): Observable<any> {
     return this._http
       .post(`${environment.apiUrl}get-row-material-sub-categories`, { start, length })
       .pipe(
         map(data => {
           return data;
         })
       );
   }
   delete(id): Observable<any> {
     return this._http
       .post(`${environment.apiUrl}delete-row-material-sub-categories`, { id })
       .pipe(
         map(data => {
           return data;
         })
       );
   }
 
   add(row_material_category_id:any,sub_category_name: any, description: any, categoryId: any): Observable<any> {
     if (categoryId == 0) {
       return this._http
         .post(`${environment.apiUrl}save-row-material-sub-categories`, { row_material_category_id,sub_category_name, description })
         .pipe(
           map(data => {
             return data;
           })
         );
     }
     else {
       return this._http
         .post(`${environment.apiUrl}update-row-material-sub-categories`, { row_material_category_id,sub_category_name, description, categoryId })
         .pipe(
           map(data => {
             return data;
           })
         );
     }
   }

}
