import { CoreMenu } from '@core/types'
import { notEqual } from 'assert'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME',
    type: 'item',
    role: ['Client', 'Admin'],
    icon: 'home',
    url: 'home'
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME',
    type: 'item',
    role: ['SalesMarketing', 'Dispatch', 'HR', 'Account', 'Purchase', 'Store'],
    icon: 'home',
    url: 'manage'
  },

  {
    id: 'factory_employee',
    title: 'Factory Employee',
    translate: 'MENU.FACTORY_EMPLOYEES',
    type: 'item',
    role: ['HR'],
    icon: 'users',
    url: 'factory-employee'
  },

  {
    id: 'master',
    title: 'Master',
    translate: 'MENU.MASTER',
    type: 'collapsible',
    role: ['Client','Store', 'Purchase'],
    icon: 'box',
    badge: {
      title: '6',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'sales_executive',
        title: 'Sales Executive',
        translate: 'MENU.EMPLOYEES',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'employees'
      },
      {
        id: 'factory_employee',
        title: 'Factory Employee',
        translate: 'MENU.FACTORY_EMPLOYEES',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'factory-employee'
      },
      {
        id: 'Departments',
        title: 'Departments',
        translate: 'MENU.DEPARTMENTS',
        type: 'item',
        role: ['Client'],
        icon: 'grid',
        url: 'departments'
      },
      {
        id: 'Vendors',
        title: 'Vendors',
        translate: 'MENU.VENDOR',
        type: 'item',
        role: ['Client','Store', 'Purchase'],
        icon: 'users',
        url: 'vendors'
      },
      {
        id: 'transport',
        title: 'Transport',
        translate: 'MENU.TRANSPORT',
        type: 'item',
        role: ['Client','Store', 'Purchase'],
        icon: 'truck',
        url: 'transport'
      },
      {
        id: 'warehouse',
        title: 'Warehouse',
        translate: 'MENU.WAREHOUSE',
        type: 'item',
        role: ['Client','Store', 'Purchase'],
        icon: 'database',
        url: 'warehouse'
      }
    ]
  },

  {
    id: 'dealers_master',
    title: 'Customer Master',
    translate: 'MENU.DEALERS',
    type: 'collapsible',
    role: ['Client'],
    icon: 'users',
    badge: {
      title: '2',
      translate: 'MENU.DEALERS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'dealers',
        title: 'Customers',
        translate: 'MENU.ORDER_REPORTS',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'dealers'
      },
      {
        id: 'price_per_customer',
        title: 'Price Per Customers',
        translate: 'MENU.PRICEPERCUSTOMER',
        type: 'item',
        role: ['Client'],
        icon: 'list',
        url: 'price-per-customer'
      },
    ]
  },
  {
    id: 'dealers_master',
    title: 'Order & Delivery',
    translate: 'MENU.ORDERS',
    type: 'collapsible',
    role: ['Client', 'User','Store', 'Purchase'],
    icon: 'truck',
    badge: {
      title: '3',
      translate: 'MENU.ORDERS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'orders',
        title: 'Orders',
        translate: 'MENU.ORDERS',
        type: 'item',
        role: ['Client', 'User'],
        icon: 'shopping-cart',
        url: 'orders'
      },
      {
        id: 'delivery_challan',
        title: 'Delivery Challan',
        translate: 'MENU.DELIVERYCHALLAN',
        type: 'item',
        role: ['Client'],
        icon: 'truck',
        url: 'delivery-challan'
      },
      {
        id: 'gate_pass',
        title: 'Gate Pass',
        translate: 'MENU.GATEPASS',
        type: 'item',
       // role: ['Client','Store','Purchase'],
        icon: 'users',
        url: 'gate-pass'
      },
    ]
  },
  {
    id: 'reports',
    title: 'Reports',
    translate: 'MENU.REPORTS',
    type: 'collapsible',
    role: ['Client','Store', 'Purchase'],
    icon: 'file',
    badge: {
      title: '3',
      translate: 'MENU.REPORTS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'order_reports',
        title: 'Order Reports',
        translate: 'MENU.ORDER_REPORTS',
        type: 'item',
        role: ['Client'],
        icon: 'file',
        url: 'reports'
      },
      {
        id: 'challan_reports',
        title: 'Challan Reports',
        translate: 'MENU.CHALLAN_REPORTS',
        type: 'item',
        role: ['Client'],
        icon: 'file',
        url: 'challan-reports'
      },
      {
        id: 'outstanding_report',
        title: 'Outstanding Report',
        translate: 'MENU.OUTSTANDING_REPORT',
        type: 'item',
        role: ['Client'],
        icon: 'file',
        url: 'outstanding-report'
      },
      {
        id: 'product_combo_report',
        title: 'Product Combo Report',
        translate: 'MENU.PRODUCT_COMBO_REPORT',
        type: 'item',
        role: ['Client','Store'],
        icon: 'file',
        url: 'product-combo-report'
      },
      {
        id: 'top_customer_list',
        title: 'Top Customers Report',
        translate: 'MENU.TOP_CUSTOMERS_REPORT',
        type: 'item',
        role: ['Client'],
        icon: 'file',
        url: 'top-customer-list'
      },
      {
        id: 'product_wise_rawmaterial_stock',
        title: 'Raw Material For Order',
        translate: 'MENU.PRODUCTWISERAWMATERIALSTOCK',
        type: 'item',
        role: ['Client','Store', 'Purchase'],
        icon: 'shopping-cart',
        url: 'product-wise-rawmaterial-stock'
      }
    ]
  },

  {
    id: 'products_mater',
    title: 'Products',
    translate: 'MENU.PRODUCTS',
    type: 'collapsible',
    role: ['Client', 'User','Store', 'Purchase'],
    icon: 'box',
    badge: {
      title: '5',
      translate: 'MENU.PRODUCTS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'categories',
        title: 'Categories',
        translate: 'MENU.CATEGORIES',
        type: 'item',
        role: ['Client'],
        icon: 'tag',
        url: 'categories'
      },
      {
        id: 'products',
        title: 'Products',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        role: ['Client', 'User','Store'],
        icon: 'box',
        url: 'products'
      },
      {
        id: 'rowmaterialproduct',
        title: 'Raw Material Product',
        translate: 'MENU.ROWMATERIALPRODUCT',
        type: 'item',
        role: ['Client','Store', 'Purchase'],
        icon: 'box',
        url: 'row-material-product'
      },

      {
        id: 'product_combo',
        title: 'Product Combo',
        translate: 'MENU.PRODUCTCOMBO',
        type: 'item',
        role: ['Client','Store'],
        icon: 'box',
        url: 'product-combo'
      },
      {
        id: 'stock_transfer',
        title: 'Stock Transfer',
        translate: 'MENU.STOCKTRANSFER',
        type: 'item',
        role: ['Client','Store'],
        icon: 'database',
        url: 'stock-transfer-finished-product'
      },
      {
        id: 'stock_list',
        title: 'Stock List',
        translate: 'MENU.STOCKTRANSFER',
        type: 'item',
        role: ['Client','Store'],
        icon: 'database',
        url: 'stock-list'
      },
      
    ]
  },

  {
    id: 'purchase_department',
    title: 'Purchase',
    translate: 'MENU.PURCHASEDEPARTMENT',
    type: 'collapsible',
    role: ['Client', 'User','Store', 'Purchase'],
    icon: 'shopping-cart',
    badge: {
      title: '2',
      translate: 'MENU.PURCHASEDEPARTMENT',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'purchaseorder',
        title: 'Purchase Order',
        translate: 'MENU.PURCHASE_ORDER',
        type: 'item',
        role: ['Client', 'Purchase', 'Store'],
        icon: 'shopping-cart',
        url: 'purchase-order'
      },
      {
        id: 'purchase_requisition',
        title: 'Purchase Requisition',
        translate: 'MENU.PURCHASEREQUISITION',
        type: 'item',
        role: ['Client', 'Admin', 'Purchase', 'Store'],
        icon: 'shopping-cart',
        url: 'purchase-requisition'
      },
    ]
  },
]
