<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="product-combo-report-page">
            <div class="row  ">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="card-title">Product Reports</h6>
                        </div>
                        <div class="card-body p-0 pb-1">

                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row p-1 align-items-center">
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                                        <ng-select formControlName="category_id" name="category_id[]"
                                                placeholder="Category Filter" (change)="getCategoryFilter($event)"
                                                id="category_id" multiple="true" class="">
                                                <ng-option [value]="" selected>Select Category</ng-option>
                                                <ng-option *ngFor="let category of categoryDropDown"
                                                    [value]="category.id">{{category.category_name}}
                                                </ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <!-- <input type="text" formControlName="product_id" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Product name..." /> -->

                                        <ng-autocomplete formControlName="product_id" #auto [data]="allProduct"
                                            name="products" [initialValue]="" [minQueryLength]="3"
                                            placeholder="Search Product, Min. 3 Characters"
                                            [searchKeyword]="keywordProduct" [historyListMaxNumber]="0"
                                            notFoundText="Not found" (selected)='selectEventProduct($event)'
                                            (inputChanged)='onChangeSearchProduct($event)'
                                            (inputFocused)='onFocusedProduct($event)'
                                            [itemTemplate]="itemTemplateStaticProduct" [isLoading]="isLoadingProduct"
                                            [notFoundTemplate]="notFoundTemplateProduct">
                                        </ng-autocomplete>
                                        <ng-template #itemTemplateStaticProduct let-items>
                                            <a [innerHTML]="items.full_name"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplateProduct let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>

                                    </div>
                                    <div class="col-12 col-sm-6 col-md-1 col-lg-1">
                                        <div class="custom-control custom-control-primary custom-switch">
                                            <input type="checkbox" class="custom-control-input" formControlName="is_moq"
                                                value="1" id="is_moq" />
                                            <label class="custom-control-label" for="is_moq"> MOQ</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Download Report
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-9">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>