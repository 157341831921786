import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) { }

  getProfileData(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-profile`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getProfileSettingData(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-manufacturer-setting`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  saveProfile(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-profile`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  saveProfileSetting(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}update-manufacturer-setting`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  changePassword(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-password`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  paymentHistory(start:any = 0, length:any = 100): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-payment-history`,{start,length})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getBankDetailsData(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-bank-details`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  saveBankDetailsData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-bank-details`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
