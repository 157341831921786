<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-3 d-none d-sm-inline-block pending-delivery-challan">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Pending Delivery Challan</h4>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="!loadingPaddingOrderWiseDealer">
                            <div class="d-flex align-items-center  p-50 border-bottom" *ngFor="let paddingOrderDealer of paddingOrderWiseDealerData; let ndx = index">
                                <div class="avatar mr-1 ml-0 bg-light-success">
                                    <div class="avatar-content text-uppercase ">{{ paddingOrderDealer.enterprise_name | initials }}</div>
                                </div>
                                <div class="cell-line-height w-100 line-height" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}">
                                    <div class="row">
                                        <div class="col">
                                            <a routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}" class="font-medium-1 d-block"><span class="font-weight-bold text-capitalize">{{ paddingOrderDealer.enterprise_name }}</span></a>
                                            <span class="font-small-2 text-capitalize"> {{ paddingOrderDealer.owner_name }} {{ paddingOrderDealer.mobile }}</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="btn btn-primary btn-sm btn-plush" routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }}"><i data-feather="plus" [class]="'font-weight-bolder'"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="!loadingPaddingOrderWiseDealer && paddingOrderWiseDealerData?.length == 0">
                            <div class="d-flex align-items-center  p-50 border-bottom">
                                No Data!
                            </div>
                        </div>
                        <div class="card-body p-50 pb-1" *ngIf="loadingPaddingOrderWiseDealer">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-9">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Delivery Challan List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/delivery-challan/add">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        <button class="btn btn-success ml-1 d-sm-none d-inline-block" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="list" class="mr-0 mr-sm-1"></i>
                                          </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive ">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Dealer Details</th>
                                            <th> Challan No</th>
                                            <!-- <th> Amount</th> -->
                                            <th> Date</th>
                                            <th> Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="challansList?.length != 0">
                                        <tr *ngFor="let challan of challansList; let ndx = index">
                                            <td *ngIf="challan.status == 1">
                                                <div class="text-center badge badge-pill badge-light-success" style="width: 30px;height: 30px;border-radius: 15px;padding-top: 7px;font-size: 1rem;">{{ndx+page+1}}</div>
                                            </td>
                                            <td *ngIf="challan.status == 0">
                                                <div class="text-center badge badge-pill badge-light-danger" style="width: 30px;height: 30px;border-radius: 15px;padding-top: 7px;font-size: 1rem;">{{ndx+page+1}}</div>
                                            </td>
                                            <td class="text-success" *ngIf="challan.status == 1">{{ challan.customer_name }} <br/> {{ challan.phone }} </td>
                                            <td class="text-danger" *ngIf="challan.status == 0">{{ challan.customer_name }} <br/> {{ challan.phone }} </td>
                                            <td class="text-success" *ngIf="challan.status == 1">{{ challan.invoice_no }}</td>
                                            <td class="text-danger" *ngIf="challan.status == 0">{{ challan.invoice_no }}</td>
                                            <!-- <td>₹ {{ challan.delivery_amount | number:'1.3-3' }}</td> -->
                                            <td class="text-success" *ngIf="challan.status == 1">{{ challan.delivery_date }}</td>
                                            <td class="text-danger" *ngIf="challan.status == 0">{{ challan.delivery_date }}</td>
                                            <td>
                                                <div ngbDropdown *ngIf="challan.status == 0" container="body">
                                                    <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                        Not Reached
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="changeStatus(challan.id,1,myModalLabelremark)">Reached</a>
                                                    </div>
                                                </div>
                                                <div ngbDropdown *ngIf="challan.status == 1" container="body">
                                                    <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                       Reached
                                                  </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101 ">
                                                        <a ngbDropdownItem (click)="changeStatus(challan.id,0,myModalLabelremark)"> Not  Reached</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="editTransport(challan.id,ndx,modalTransport)"><i data-feather="truck" class="mr-50"></i><span>Edit Transport</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="download(challan.id,ndx)"><i data-feather="download" class="mr-50"></i><span>Download</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="modalOpen(modalBasic,challan.id)"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(challan.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="challansList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 0" [(page)]="pageAdvancedEllipses" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true">

                                </ngb-pagination>

                                <!-- Transport Modal -->
                                <ng-template #modalTransport let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Transport Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col">

                                            <form name="form" [formGroup]="transportform" autocomplete="off">
                                                <div class="row">
                                                    <div class="form-group col-lg-4">
                                                        <label for="register-transport_id" class="form-label">Select Transport</label>
                                                        <ng-select aria-describedby="register-transport_id" formControlName="transport_id" (change)="changeTransport($event)" tabindex="1">
                                                            <ng-option [value]="" selected disabled>Select Transport</ng-option>
                                                            <ng-option *ngFor="let transport of transportDropDown" [value]="transport.id">{{transport.name}}</ng-option>

                                                        </ng-select>
                                                    </div>

                                                    <div class="form-group col-lg-4 d-none ">
                                                        <label for="register-transport_name" class="form-label">Transport Name</label>
                                                        <input type="text" formControlName="transport_name" class="form-control" placeholder="Enter Transport Name" aria-describedby="register-transport_name" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.transport_name.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-4 d-none">
                                                        <label for="register-mobile_no" class="form-label">Mobile</label>
                                                        <input type="text" formControlName="mobile_no" class="form-control" placeholder="Enter Mobile" aria-describedby="register-mobile_no" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-4 ">
                                                        <label for="register-lr_no" class="form-label">LR No</label>
                                                        <input type="text" formControlName="lr_no" class="form-control" placeholder="Enter LR No" aria-describedby="register-lr_no" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.lr_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-4 ">
                                                        <label for="register-vehicle_no" class="form-label">Vehicle No</label>
                                                        <input type="text" formControlName="vehicle_no" class="form-control" placeholder="Enter Vehicle No" aria-describedby="register-vehicle_no" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.vehicle_no.errors }" />
                                                    </div>
                                                    <div class="form-group col-lg-12 ">
                                                        <label for="register-remarks" class="form-label">Remarks</label>
                                                        <textarea rows="3" formControlName="remarks" class="form-control" placeholder="Enter Remarks" aria-describedby="register-remarks" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                                    Close
                                                </button>
                                        <button type="button" class="btn btn-success" [disabled]="loading" (click)="updateTransportDetails()" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                             Update</button>
                                    </div>
                                </ng-template>
                                <!-- / Transport Modal -->

                                <!-- Modal -->
                                <ng-template #myModalLabelremark let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabelremark">Change Status</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col">

                                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                                <textarea formControlName="remarks" class="form-control" rows="4" id="remarks" placeholder="Remark If Any"></textarea>
                                            </form>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                                    Close
                                                </button>
                                        <button type="button" class="btn btn-success" [disabled]="loading" (click)="changeChallanStatus()" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Change</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length != 0">#{{ challanDetails.invoice_no }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="challanDetails?.length == 0">Delivery Challan Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="challanDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="challanDetails?.length != 0" id="print-section">
                                            <table class="table" style="width:100%;">
                                                <tbody>
                                                    <td style="padding:5px;" *ngIf="currentUser.user.profile_image == null">
                                                        <img src="assets/images/logo/logo-text.png" style="width: 150px; height: 50px;" />
                                                    </td>
                                                    <td style="padding:5px;" *ngIf="currentUser.user.profile_image != null">
                                                        <img [src]="currentUser.user.profile_image" width="100" height="auto" />
                                                    </td>
                                                    <td align="right" style="padding:5px;">
                                                        <h3 style="margin: 2px;">{{ currentUser.user.name }}</h3>
                                                        <h5 style="margin: 2px;">Mobile : {{ currentUser.user.mobile }}</h5>
                                                        <h5 style="margin: 2px;">Email : {{ currentUser.user.email }}</h5>
                                                        <h5 style="margin: 2px;" *ngIf="currentUser.user.is_gst_print == 1">GST : {{ currentUser.user.gst_no | uppercase }}</h5>
                                                    </td>
                                                </tbody>
                                            </table>
                                            <hr class="text-white" style="width:100%;">
                                            <table class="table" style="width:100%;margin-top: 15px;">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding:5px;">
                                                            <b>Name : </b> {{ challanDetails.name }}<br/>
                                                            <b>Phone : </b> {{ challanDetails.phone }}<br/>
                                                            <b>Address :</b> {{ challanDetails.address_one }}
                                                            <span *ngIf="challanDetails.address_two != '' && challanDetails.address_two != null">, {{ challanDetails.address_two }}</span>
                                                            <span *ngIf="challanDetails.address_three != '' && challanDetails.address_three != null">, {{ challanDetails.address_three }}</span>
                                                            <span *ngIf="challanDetails.city_name != '' && challanDetails.city_name != null">, {{ challanDetails.city_name }}</span>
                                                            <span *ngIf="challanDetails.state_name != '' && challanDetails.state_name != null">, {{ challanDetails.state_name }}</span> <br/>
                                                            <b *ngIf="challanDetails.user_gst_no != '' && challanDetails.user_gst_no != null">GSTIN :</b> {{ challanDetails.user_gst_no }}
                                                        </td>
                                                        <td align="right " style="padding:5px; ">
                                                            <b>PO No : </b> #{{ challanDetails.order_details[0].po_number }} <br/>
                                                            <b>Challan No : </b> #{{ challanDetails.invoice_no }} <br/>
                                                            <b>Challan Date : </b> {{ challanDetails.delivery_date }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="col-12 pt-1 overflow-auto ">
                                                <table class="table table-hover table-striped " style="width:100%;margin-top:15px; ">
                                                    <thead>
                                                        <tr>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">Sr.No.</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">Product</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; " *ngIf="currentUser.user.is_show_price==1 ">Rate</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">Qty</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; " *ngIf="currentUser.user.is_show_price==1 ">Sub Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let challanProduct of challanDetails.delivery_challan_details;  let ndx = index ">
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">{{ndx+1}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">
                                                                {{ challanProduct.product_code }} {{ challanProduct.product_name }}
                                                                <small *ngIf="challanProduct.size_title !='' && challanProduct.color_title !='' "><br/>{{ challanProduct.size_title }} {{ challanProduct.color_title }}</small>
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px; " *ngIf="currentUser.user.is_show_price==1 ">₹ {{challanProduct.dispatch_price | number:'1.3-3' }}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">{{challanProduct.dispatch_qty}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right; " *ngIf="currentUser.user.is_show_price==1 ">₹ {{challanProduct.dispatch_sub_total | number:'1.3-3'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; " *ngIf="currentUser.user.is_show_price==1 " colspan="3 " class="text-right ">Total</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; " *ngIf="currentUser.user.is_show_price==0 " colspan="2 " class="text-right ">Total</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px; ">{{ getSum('dispatch_qty') }}</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right; " *ngIf="currentUser.user.is_show_price==1 ">₹ {{ getSum('dispatch_sub_total') | number:'1.3-3' }}</th>
                                                        </tr>
                                                        <tr *ngIf="currentUser.user.is_show_price==1 && challanDetails.discount_pre> 0">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="4" class="text-right">Discount ( {{ challanDetails.discount_pre }} %)</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right;">₹ {{ challanDetails.discount_rs | number:'1.3-3' }}</th>
                                                        </tr>
                                                        <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.tax_one_pre > 0">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="4" class="text-right">{{ challanDetails.tax_one }} ( {{ challanDetails.tax_one_pre }} %)</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right;">₹ {{ challanDetails.tax_one_rs | number:'1.3-3' }}</th>
                                                        </tr>
                                                        <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.tax_two_pre > 0">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="4" class="text-right">{{ challanDetails.tax_two }} ( {{ challanDetails.tax_two_pre }} %)</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right;">₹ {{ challanDetails.tax_two_rs | number:'1.3-3'}}</th>
                                                        </tr>
                                                        <tr *ngIf="currentUser.user.is_show_price == 1 && challanDetails.round_off > 0">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="4" class="text-right">Round Off</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right;">₹ {{ challanDetails.round_off }}</th>
                                                        </tr>
                                                        <tr *ngIf="currentUser.user.is_show_price == 1">
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="4" class="text-right">Grand Total</th>
                                                            <th style="border-bottom: 1px dashed #ebe9f1;padding:5px;text-align: right;">₹ {{ challanDetails.delivery_amount | number:'1.3-3' }}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table" style="width:100%;margin-top: 15px;">
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="4" align="center">
                                                                <h3>Transport Details</h3>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;width:25%;">
                                                                <b>LR No : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;"><span *ngIf="challanDetails.lr_no != 'null'">{{ challanDetails.lr_no }}</span>
                                                            </td>
                                                            <td style="padding:5px;width:25%;" align="right">
                                                                <b>Transport Name : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.transport_name != 'null'">{{ challanDetails.transport_name }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;width:25%;">
                                                                <b>Mobile : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.mobile != 'null'">{{ challanDetails.mobile }}</span>
                                                            </td>
                                                            <td style="padding:5px;width:25%;" align="right">
                                                                <b>Vehicle No : </b>
                                                            </td>
                                                            <td style="padding:5px;width:25%;">
                                                                <span *ngIf="challanDetails.vehicle_no != 'null'">{{ challanDetails.vehicle_no }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;width:25%;"><b>GST No : </b></td>
                                                            <td colspan="3">{{ challanDetails.transport_gst_no }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style="padding:5px;padding-bottom: 15px; " colspan="4 ">
                                                                <b>Remarks : </b>
                                                                <span *ngIf="challanDetails.order_details.remarks !='null' ">{{ challanDetails.order_details[0].remarks }}</span>
                                                                <span *ngIf="challanDetails.remarks !='null' "><br/>{{ challanDetails.remarks }}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table " style="width:100%; ">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194) ">
                                                                Powered By : Pro Clean
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer ">
                                        <button type="button " class="btn btn-secondary " (click)="modal.close( 'Accept click') " rippleEffect>
                                            Close
                                        </button>
                                        <button type="button " class="btn btn-success " printSectionId="print-section " ngxPrint><i class="fa fa-print "></i> Print</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade " name="new-sidebar " overlayClass="modal-backdrop ">
    <div class="slideout-content ">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal " id="modals-slide-in ">
            <div class="modal-dialog ">
                <form class="add-new modal-content pt-0 ">
                    <button type="button " class="close " data-dismiss="modal " aria-label="Close " (click)="toggleSidebar( 'new-sidebar') ">
                      ×
                    </button>
                    <div class="modal-header mb-1 ">
                        <h5 class="modal-title " id="exampleModalLabel ">Pending Delivery Challan</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="row " *ngIf="!loadingPaddingOrderWiseDealer ">
                            <div class="d-flex align-items-center p-50 border-bottom w-100 " *ngFor="let paddingOrderDealer of paddingOrderWiseDealerData; let ndx=i ndex ">
                                <div class="avatar mr-1 ml-0 bg-light-success ">
                                    <div class="avatar-content text-uppercase ">{{ paddingOrderDealer.enterprise_name | initials }}</div>
                                </div>
                                <div class="cell-line-height w-100 line-height " routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }} ">
                                    <a routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }} " class="font-medium-1 d-block "><span class="font-weight-bold text-capitalize ">{{ paddingOrderDealer.enterprise_name }}</span></a>
                                    <span class="btn btn-primary btn-sm btn-plush " routerLink="/delivery-challan/add/{{ paddingOrderDealer.id }} "><i data-feather="plus " [class]=" 'font-weight-bolder' "></i></span>
                                    <span class="font-small-2 text-capitalize "> {{ paddingOrderDealer.owner_name }} {{ paddingOrderDealer.mobile }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row " *ngIf="!loadingPaddingOrderWiseDealer && paddingOrderWiseDealerData?.length==0 ">
                            <div class="d-flex align-items-center p-50 border-bottom ">
                                No Data!
                            </div>
                        </div>
                        <div class="row " *ngIf="loadingPaddingOrderWiseDealer ">
                            <div class="text-center ">
                                <div class="spinner-border " role="status ">
                                    <span class="sr-only ">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->