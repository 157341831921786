import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PricePerCustomerService {

  constructor(private _http: HttpClient) { }

  dealerSearch(searchText: string): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}search-dealer`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  storePrices(formdata: any): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}save-customer-price`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getProductsById(start, length, dealer_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-customer-price`, { start, length, dealer_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  delete(id): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}delete-customer-price/` + id,)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id, sales_price): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}update-customer-price/` + id, { sales_price })
      .pipe(
        map(data => {
          return data;
        })
      );
  }


}
