import { Component, OnInit, ViewChild, Injectable } from '@angular/core'; import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { ProductionGeneralService } from 'app/_services/production-general.service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StockReturnService } from 'app/_services/stock-return.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-store-return',
  templateUrl: './store-return.component.html',
  styleUrls: ['./store-return.component.scss']
})
export class StoreReturnComponent implements OnInit {

  constructor(private _coreConfigService: CoreConfigService, private stockReturnService: StockReturnService, private purchaseOrderService: PurchaseOrderService, private productionGeneralService: ProductionGeneralService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  public error = '';
  public newForm: FormGroup;
  public givenFrm: FormGroup;
  public loading = false;
  public rowMaterialProductsDropDown: any;
  public submitted: boolean = false;
  public returnList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public progressDetails: boolean = false;

  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public showDetails: any = [];
  public givenDropDown: any;

  public today = new Date();
  public getDate = String(this.today.getDate()).padStart(2, '0');
  public getMonth = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  public getYear = this.today.getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = { day: this.maxDate, month: this.maxMonth, year: this.maxYear };

  public returnProducts: any[] = [{
    id: 1,
    raw_material_product: '0',
    return_qty: '0'
  }];


  ngOnInit(): void {
    this.loading = true;
    this.getreturnList(this.start, this.lengths);
    this.newForm = this._formBuilder.group({
      return_date: ['', [Validators.required]],
      description: [null]
    });

    this.givenFrm = this._formBuilder.group({
      given_id: ['', [Validators.required]]
    });
  }

  get f() {
    return this.newForm.controls;
  }
  get gf() {
    return this.givenFrm.controls;
  }

  getreturnList(my_start: any, my_lengths: any) {

    this.stockReturnService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.returnList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.getreturnList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getreturnList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getreturnList(this.start, this.lengths);
  }

  getRowMaterialProductsDropDowns(id: any) {
    this.stockReturnService.givenRawMaretialDropdown(id).subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.given;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
  // modal Basic
  modalOpen(modalBasic) {
    this.getgivenDropDown();
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }

  // modal Basic
  viewDetails(modalBasicDetails, givenId: any) {
    this.progressDetails = true;
    this.showDetails = [];
    this.modalService.open(modalBasicDetails, {
      size: 'lg'
    });

    this.stockReturnService.viewDetails(givenId).subscribe(
      response => {
        if (response.status) {
          this.showDetails = response.stockReturn;
          this.progressDetails = false;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      let return_dates = this.f.return_date.value['year'] + '-' + this.f.return_date.value['month'] + '-' + this.f.return_date.value['day'];
      this.stockReturnService.add(return_dates, this.f.description.value, this.returnProducts, this.gf.given_id.value).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.reloadList();
            this.modalService.dismissAll('modalBasic');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          console.log(err);
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.stockReturnService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getreturnList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Stock Return Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  addMore() {
    this.returnProducts.push({
      id: this.returnProducts.length + 1,
      raw_material_product: '0',
      price: '0'
    });
  }

  remove(i: number) {
    this.returnProducts.splice(i, 1);
  }

  getgivenDropDown() {
    this.stockReturnService.givenDropdown().subscribe(
      data => {
        if (data.status) {
          this.givenDropDown = data.given;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }


}