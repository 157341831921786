import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, category, searchByName): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-products`, { start, length, category, searchByName })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getProductsList(data): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-products-list`, data)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDataPrice(start, length, customer_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-products-price`, { start, length, customer_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(productData: any, id: any): Observable<any> {

    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-product`, productData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-product`, productData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-product`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-product`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCategoriesDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-categories-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );

  }
  getUnitDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-unit-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  fileDownload(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-products`, {  })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  fileDownloadFull(category: any = '', name: any = ''): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-all-products`, { category, name })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  uploadFile(productData: any): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}import-products`, productData)
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  addStock(product_id: any, product_qty: any): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}save-product-stock`, { product_id, product_qty })
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  getProductsStockById(start, length, id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-stock`, { start, length, id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  deleteStock(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-product-stock`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getMaintainList(start, length, searchByName): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-stock-maintain-list`, { start, length, searchByName })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addMaintain(formData: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-stock-maintain-store`, formData)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  maintainHistory(date, user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-stock-maintain-history`, { date, user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
