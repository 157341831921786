import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GatePassService } from 'app/_services/gate-pass.service';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gate-pass',
  templateUrl: './gate-pass.component.html',
  styleUrls: ['./gate-pass.component.scss']
})
export class GatePassComponent implements OnInit {

  gatePass: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageStockBasicText = 1;
  public pageText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public userDetails: any;
  public progressMainTable: boolean = false;
  public newFormStock: FormGroup;
  public loadingFrmStock = false;
  public submittedStock = false;
  public progressStockTable = false;
  public productDropDown = [];
  public stockData = [];
  public collectionSizeStock = 0;
  public startStock: any = 0;
  public lengthsStock = 5;
  public statusLabel = ["Pending", "Half Received", "Completed", "Cancel", "Manualy Closed"];
  public currentUser: any;

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _gatePassService: GatePassService,
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.newFormStock = this._formBuilder.group({
      gate_pass_product_id: [null, Validators.required],
      gate_pass_id: [null, Validators.required],
      product_id: [null, Validators.required],
      receive_qty: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*([.][0-9]+)?$')]],
    });
  }

  get fs() {
    return this.newFormStock.controls;
  }

  ngOnInit(): void {
    this.loading = true;
    //this.progressMainTable = true;
    this.gatePassLists(this.start, this.lengths);
  }

  gatePassLists(my_start: any, my_lengths: any) {
    this._gatePassService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.gatePass = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me._gatePassService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.gatePassLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Gate Pass Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.gatePassLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.gatePassLists(this.page, this.lengths);
  }
  loadPageLengths(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.gatePassLists(this.start, this.lengths);
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  // modal Basic
  modalOpen(modalBasic, selectedData: any) {
    this.userDetails = selectedData;
    this.modalService.open(modalBasic, {
      size: 'xl'
    });
  }

  manageStock(selectedData: any) {
    this.userDetails = selectedData;
    this.startStock = 0;
    this.lengthsStock = 5;
    this.pageStockBasicText = 1;
    this.toggleSidebar('new-sidebar');
    this.productStockList(this.startStock, this.lengthsStock)
  }

  loadStockPage(event: any) {
    this.progressStockTable = true;
    this.startStock = this.lengthsStock * (event - 1);
    this.productStockList(this.startStock, this.lengthsStock);
  }

  productStockList(start, length) {
    this.progressStockTable = true;
    this._gatePassService.getGatePassStock({
      "gate_pass_id": this.userDetails.id,
      "start": start,
      "length": length
    }).subscribe(
      response => {
        this.progressStockTable = false;
        if (response.status) {
          this.newFormStock.reset();
          this.stockData = response.data;
          this.collectionSizeStock = response.recordsTotal;
          // this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          // this._toastrService.error(response.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  onSelectProduct(val: HTMLSelectElement) {
    const selectedOption = val.options[val.selectedIndex];
    let gate_pass_product_id = selectedOption.getAttribute('data-gate_pass_product_id');
    let gate_pass_data: any = selectedOption.getAttribute('data-gate_pass_data');
    gate_pass_data = JSON.parse(gate_pass_data);

    this.newFormStock.get('gate_pass_product_id').setValue(gate_pass_product_id);

    this.newFormStock.get('receive_qty').setValue(null);

    this.newFormStock.get('receive_qty').setValidators(Validators.max(Number(gate_pass_data.remaining_qty)));
    this.newFormStock.get('receive_qty').updateValueAndValidity()
  }

  onSubmitStock() {
    this.newFormStock.get('gate_pass_id').setValue(this.userDetails.id);
    this.submittedStock = true;
    // stop here if form is invalid
    if (this.newFormStock.invalid) {

    }
    else {
      this.loadingFrmStock = true;

      this._gatePassService.receiveGatePass(this.newFormStock.value).subscribe(
        data => {
          this.loadingFrmStock = false;
          if (data.status) {
            this.submittedStock = false;
            this.newFormStock.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
}
