import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'app/_services/products.service';

@Component({
  selector: 'app-product-stock-maintain',
  templateUrl: './product-stock-maintain.component.html',
  styleUrls: ['./product-stock-maintain.component.scss']
})
export class ProductStockMaintainComponent implements OnInit {

  public loading = false;
  public modalLoading = false;
  public start: any = 0;
  public page: any = 0;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public searchByName: any = '';
  public productList: any = [];
  public productHistory: any = [];
  public userDate = '';
  public username = '';

  public currentUser: any;
  public currentUserRole = '';

  constructor( private productsService: ProductsService, private _toastrService: ToastrService, private _router: Router, private _authenticationService: AuthenticationService,  private modalService: NgbModal) { 
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.rowStockProductList(this.start, this.lengths);
  }

  rowStockProductList(my_start: any, my_lengths: any) {
    this.productsService.getMaintainList(my_start, my_lengths, this.searchByName).subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          this.productList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.loading = true;
    this.rowStockProductList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.loading = true;
    this.page = this.lengths * (event - 1);
    this.rowStockProductList(this.page, this.lengths);
  }

  loadPageLengths(event: any) {
    this.loading = true;
    this.lengths = event.target.value;
    this.rowStockProductList(this.start, this.lengths);
  }

  getFilter(searchByName:any) {
    this.searchByName = searchByName;
    this.reloadList();
  }

  modalOpen(modalBasic, date:any, user_id:any,username:any){
    this.userDate = date;
    this.username = username;
    this.modalLoading = true;
    this.productHistory = [];
    this.modalService.open(modalBasic, {
      size: 'xl'
    });

    this.productsService.maintainHistory(date,user_id).subscribe(
      response => {
        if (response.status) {
          this.modalLoading = false;
          this.productHistory = response.data;
        }else{
          this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
}
