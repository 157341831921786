import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CategoriesService } from 'app/_services/categories.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import { NewSidebarComponent } from "./new-sidebar/new-sidebar.component";
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  @ViewChild(NewSidebarComponent) child;

  categoriesList: any = [];
  start: any = 0;
  page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public categoryEditData: any = [];
  public loading = false;
  public progressMainTable:boolean = false;

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private http: HttpClient, private categoryService: CategoriesService, private _coreSidebarService: CoreSidebarService) { }

  ngOnInit() {
    this.loading = true;

    this.categoryList(this.start, this.lengths);
  }

  categoryList(my_start: any, my_lengths: any) {
    this.categoryService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.categoriesList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.categoryList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.categoryList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.categoryList(this.start, this.lengths);
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name, type: string = 'add'): void {
    this.child.error = '';
    this.child.submitted = false;
    if (type == 'add') {
      this.child.categoryId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  editCat(id: any) {
    this.categoryService.catEdit(id).subscribe(
      response => {
        if (response.status) {
          this.child.categoryId = response.category.id;
          this.child.f.categoryName.setValue(response.category.category_name);
          this.toggleSidebar('new-sidebar', 'edit');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {

    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.categoryService.catDelete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.categoryList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Category Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

}
