import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { ProductsService } from 'app/_services/products.service';


@Component({
  selector: 'app-row-material-product',
  templateUrl: './row-material-product.component.html',
  styleUrls: ['./row-material-product.component.scss']
})
export class RowMaterialProductComponent implements OnInit {
  public rowMaterialProductList: any = [];
  public rowMaterialCategoryDropDown: any = [];
  public rowMaterialSubCategoryDropDown: any = [];
  public start: any = 0;
  public page: any = 0;

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public rowMaterialProductId: any = 0;
 
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public progressMainTable: boolean = false;
  public addMode: boolean = true;
  public productsDropDown: any = [];
  public currentUser: any;
  public currentUserRole = '';
  public searchByName: any = '';
  public unitDropDown: any;
  public categoryFilter: any = [];
  public is_moq: any = '';

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService, private http: HttpClient, private rowMaterialProductService: RowMaterialProductService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.productList(this.start, this.lengths);
    this.getRowMaterialProductCategoriesDropDown();
    this.getProductsDropDown();
    this.getUnitDropDown();
    this.newForm = this._formBuilder.group({
      row_material_category_id: [null, Validators.required],
      // rowMaterialSubCategoryId: [0, Validators.required],
      rowmaterialName: ['', Validators.required],
      //rowmaterialPrice: ['', Validators.required],
      rowmaterialDescription: [''],
      useQuantity: ['', Validators.required],
      current_stock: ['', Validators.required],
      moq_level: ['', Validators.required],
      products_id: ['', Validators.required],
      unit: ['', Validators.required]
    });
  }

  getProductsDropDown() {
    this.rowMaterialProductService.getProducts().subscribe(
      data => {
        if (data.status) {
          this.progressMainTable = false;
          this.productsDropDown = data.products;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  get f() {
    return this.newForm.controls;
  }

  getRowMaterialProductCategoriesDropDown() {
    this.rowMaterialProductService.getRowMaterialProductCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.rowMaterialCategoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  productList(my_start: any, my_lengths: any) {

    this.rowMaterialProductService.getData(my_start, my_lengths, this.searchByName,this.is_moq).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.rowMaterialProductList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.rowMaterialProductService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.productList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.error = '';
    }
    else {
      this.error = '';
      this.loadingForm = true;

      const formdata = new FormData();
      formdata.append('row_material_category_id', this.f.row_material_category_id.value);
      //formdata.append('row_material_sub_category_id', this.f.rowMaterialSubCategoryId.value);
      formdata.append('product_name', this.f.rowmaterialName.value);
      //formdata.append('price', this.f.rowmaterialPrice.value);
      formdata.append('description', this.f.rowmaterialDescription.value);
      formdata.append('useQuantity', this.f.useQuantity.value);
      formdata.append('current_stock', this.f.current_stock.value);
      formdata.append('moq_level', this.f.moq_level.value);
      formdata.append('products_id', this.f.products_id.value);
      formdata.append('unit', this.f.unit.value);
      formdata.append('id', this.rowMaterialProductId);

      this.rowMaterialProductService.add(formdata, this.rowMaterialProductId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.productList(this.page, this.lengths);

          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  /**
 * Toggle the sidebar
 *
 * @param name
 */
  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.submitted = false;
    this.error = '';
    this.loadingForm = false;
    this.addMode = true;
    this.rowMaterialProductId = 0;

    if (id != 0 && index != null) {
      this.addMode = false;
      this.f.row_material_category_id.setValue(this.rowMaterialProductList[index].row_material_category_id);
      // this.f.rowMaterialSubCategoryId.setValue(this.rowMaterialProductList[index].row_material_sub_category_id);
      this.f.rowmaterialName.setValue(this.rowMaterialProductList[index].product_name);
      // this.f.rowmaterialPrice.setValue(this.rowMaterialProductList[index].price);
      this.f.rowmaterialDescription.setValue(this.rowMaterialProductList[index].description);
      this.f.useQuantity.setValue(this.rowMaterialProductList[index].useQuantity);
      this.f.current_stock.setValue(this.rowMaterialProductList[index].current_stock);
      this.f.moq_level.setValue(this.rowMaterialProductList[index].moq_level);
      this.f.unit.setValue(this.rowMaterialProductList[index].unit);
      this.rowMaterialProductId = id;

      let products = this.rowMaterialProductList[index].products_id.split(",").map(x => Number.parseInt(x, 10));
      this.f.products_id.setValue(products);
    }

    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  getRowMaterialSubCategoriesDropDown(categoryId: any) {
    this.rowMaterialProductService.getRowMaterialSubCategoriesList(categoryId).subscribe(
      data => {
        if (data.status) {
          this.rowMaterialSubCategoryDropDown = data.categories;
        }
        else {
          this.rowMaterialSubCategoryDropDown = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getRawMaterialFilter(searchByName) {
    this.page = this.start;
    this.pageBasicText = 1;
    this.searchByName = searchByName;
    this.reloadList();
  }

  getmoqFilter(is_moq) {
    this.page = this.start;
    this.pageBasicText = 1;
    this.is_moq = is_moq;
    this.reloadList();
  }

  getUnitDropDown() {
    this.productsService.getUnitDropDown().subscribe(
      data => {
        if (data.status) {
          this.unitDropDown = data.units;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  excelDownload() {

    this.rowMaterialProductService.rawmaterialFileDownload(this.is_moq, this.searchByName).subscribe(
      response => {
        if (response.status) {
          const fileUrl = response.url;
          const a = document.createElement('a');
          a.href = fileUrl;
          a.target = '_blank';
          a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
}
