<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Products List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-2 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                           <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>

                                <div class="col-md-5 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <ng-select name="category_id[]" placeholder="Category Filter" (change)="getCategoryFilter($event)" id="category_id" multiple="true" class="mr-1 w-50">
                                            <ng-option [value]="" selected>Select Category</ng-option>
                                            <ng-option *ngFor="let category of categoryDropDown" [value]="category.id">{{category.category_name}}</ng-option>
                                        </ng-select>
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Product name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getProductFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-md-5 col-12 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1" *ngIf="currentUserRole == 'Client'" style="padding: 0.786rem 1rem;" rippleEffect routerLink="/products/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Product">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        <button class="btn btn-success ml-1" *ngIf="currentUserRole == 'Client'" style="padding: 0.786rem 1rem;" rippleEffect (click)="modalOpen(modalBasic)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Upload Multiple Products">
                                            <i data-feather="upload" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Excel Upload</span>
                                        </button>
                                        <a class="btn btn-warning ml-1" style="padding: 0.786rem 1rem;" routerLink="/product-stock-maintain" rippleEffect  data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Stock Maintain">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Stock Maintain</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12 d-sm-none d-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <ng-select name="category_id[]" placeholder="Category Filter" (change)="getCategoryFilter($event)" id="category_id" multiple="true" class="w-100">
                                            <ng-option [value]="" selected>Select Category</ng-option>
                                            <ng-option *ngFor="let category of categoryDropDown" [value]="category.id">{{category.category_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Product name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getProductFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style="width:35%"> Product Name</th>
                                            <th> Sale / Purchase Price</th>
                                            <th> Sizes</th>
                                            <th> Colors</th>
                                            <th> Units</th>
                                            <th> Stock</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsList?.length != 0">
                                        <tr *ngFor="let product of productsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>
                                                <div style="width:45px;float:left;">
                                                    <img src="assets/images/no-img.png" *ngIf="product.product_image?.length == 0" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                    <img [src]="product.product_image[0].product_thum" *ngIf="product.product_image?.length != 0" height="40" width="40" alt="IMG" class="rounded mr-75">
                                                </div>
                                                <div style="float:left;">
                                                    <div *ngIf="product.category_name != '' && product.category_name != null" class="font-small-2 badge badge-light-primary">{{ product.category_name }} </div>
                                                    <br *ngIf="product.category_name != '' && product.category_name != null" />
                                                    <b>{{  product.product_code }} {{ product.product_name }}</b>
                                                </div>
                                            </td>
                                            <td>₹ {{ product.sales_price | number:'1.3-3' }} / {{ product.purchase_price | number:'1.3-3' }}</td>
                                            <td>{{ product.size_title }}</td>
                                            <td>{{ product.color_title }}</td>
                                            <td *ngIf="product.unit == 'null'"></td>
                                            <td *ngIf="product.unit != 'null'">{{ product.unit }}</td>
                                            <td>{{ product.current_stock }}</td>
                                            <td>

                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="manageStock(ndx)"><i data-feather="grid" class="mr-50"></i><span>Stock Manage</span></a>
                                                        <a ngbDropdownItem routerLink="/products/edit/{{ product.id }}" *ngIf="currentUserRole == 'Client'"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"  *ngIf="currentUserRole == 'Client'" (click)="delete(product.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 30" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
</div>

<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Upload Multiple Products</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body  overflow-auto" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="card-body">
                <form method="post" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <fieldset class="form-group">
                        <label for="file-upload-single" class="form-label">Upload Excel File:</label>
                        <div class="custom-file">
                            <input type="file" formControlName="products" class="custom-file-input" [ngClass]="{ 'is-invalid': submitted && f.products.errors }" (change)="uploadFile($event)" type="file" id="file-upload-single" />
                            <label class="custom-file-label">Choose file</label>
                        </div>
                        <div *ngIf="submitted && f.products.errors" class="invalid-feedback">
                            <div *ngIf="f.products.errors.required">Please Upload File</div>
                        </div>
                    </fieldset>
                    <fieldset class="form-group" *ngIf="product_selected_name != ''">
                        <b>File : </b> {{ product_selected_name }} <br/> <b> Size : </b> {{ product_selected_size | number: '.2'}} MB
                    </fieldset>
                    <div class="form-group" *ngIf="error">
                        <ngb-alert [type]="'danger'" [dismissible]="false">
                            <div class="alert-body">
                                <p>{{ error }}</p>
                            </div>
                        </ngb-alert>
                    </div>
                    <div class="form-group">
                        <button [disabled]="loadingFrm" class="btn btn-primary" tabindex="14" rippleEffect>
                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                            <i data-feather="upload" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                            Upload
                        </button>
                        <a href="assets/sample/import_products.csv" download="digital_order_book_import_products.csv" class="pull-right btn btn-info" rippleEffect>
                            <i data-feather="download" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                            <span class="d-none d-sm-inline-block">Download </span> Sample

                        </a>
                    </div>
                </form>
            </div>
            <div class="card-body">
                <table class="table table-hover table-responsive table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Product Code</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Product Name</th>
                            <td>
                                <div class="badge badge-light-success">
                                    This Field is Required
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Sales Price</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>MRP</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Purchase Price</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Size Title</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Color Title</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Packing Qty</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Master Packing Qty</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Unit</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Stock</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Stock Alert</th>
                            <td>
                                <div class="badge badge-light-warning">
                                    Field is optional
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
    </div>
</ng-template>
<!-- / Modal -->


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormStock" (ngSubmit)="onSubmitStock()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Product Stock Manage</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="bs-stepper-content">
                            <!-- Checkout Place order starts -->
                            <div id="step-cart" class="content">
                                <div id="place-order" class="list-view product-checkout">
                                    <!-- Checkout Place Order Left starts -->
                                    <div class="checkout-items">
                                        <div class="row ecommerce-card">
                                            <div class="item-img col-3">
                                                <!-- "editProduct.product_image[0].product_photo <img *ngIf="editProduct.product_image?.length == 0" src="assets/images/no-img.png" width="80" alt="img" class="img-responsive " /> -->

                                                <img src="assets/images/no-img.png" height="50" width="50" alt="IMG" class="rounded mr-75">
                                                <!-- <img [src]="" height="50" width="50" *ngIf="editProduct.product_image?.length == 0" alt="IMG" class="rounded mr-75"> -->
                                            </div>
                                            <div class="card-body col-9 ">
                                                <div class="item-name ">
                                                    <h6 class="mb-0 ">
                                                        {{ editProduct.product_name }}
                                                    </h6>
                                                    <span class="item-company "> {{ editProduct.size_title }}  {{ editProduct.color_title }}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-product_qty" class="form-label">Stock Add</label>
                                    <input type="number" class="form-control" formControlName="product_qty" placeholder="Stock Add" aria-describedby="register-product_qty" [ngClass]="{ 'is-invalid': submittedStock && fs.product_qty.errors }" />
                                    <div *ngIf="submittedStock && fs.product_qty.errors" class="invalid-feedback">
                                        <div *ngIf="fs.product_qty.errors.required">Stock is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrmStock"> <span *ngIf="loadingFrmStock " class="spinner-border spinner-border-sm mr-1 "></span>Submit</button>
                                <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrmStock" rippleEffect>
                                    <span *ngIf="loadingFrmStock" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>                                    
                              </button>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">
                                <div class="overlay" *ngIf="progressStockTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th> Stock</th>
                                            <th> Added</th>
                                            <th> #</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsStockList?.length != 0">
                                        <tr *ngFor="let productStock of productsStockList; let ndx = index">

                                            <td>{{ productStock.product_qty }}</td>
                                            <td>{{ productStock.create_date }}</td>
                                            <td>
                                                <a class="btn btn-icon btn-sm btn-xs btn-warning d-none" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                                <a class="btn btn-icon btn-sm btn-xs btn-danger" (click)="deleteStock(productStock.id)" style="margin-top:5px;" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsStockList?.length == 0">
                                        <tr *ngIf="!loadingStock">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loadingStock">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSizeStock > 5" [collectionSize]="collectionSizeStock" [pageSize]="lengthsStock" [(page)]="pageBasicText" (pageChange)="loadStockPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->