<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Productions Give List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                 <option value="50">50</option>
                                                 <option value="100">100</option>
                                             </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic)" *ngIf="currentUserRole != 'Packaging' && currentUserRole != 'Tufting' && currentUserRole != 'Molding'">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Batch No</th>
                                            <th *ngIf="currentUserRole != 'PackagingPlanning' && currentUserRole != 'TuftingPlanning' && currentUserRole != 'MoldingPlanning' && currentUserRole != 'Packaging' && currentUserRole != 'Tufting' && currentUserRole != 'Molding'">Department</th>
                                            <th *ngIf="currentUserRole != 'PackagingPlanning'">Operator</th>
                                            <th *ngIf="currentUserRole != 'PackagingPlanning'">Machine</th>
                                            <th>Product</th>
                                            <th>Color</th>
                                            <th>Qty </th>
                                            <th>Require Date </th>
                                            <th>Ok Qty </th>
                                            <th>Status</th>
                                            <th *ngIf="currentUserRole != 'Packaging' && currentUserRole != 'Tufting' && currentUserRole != 'Molding'">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="givenList?.length != 0">
                                        <tr *ngFor="let given of givenList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ given.batch_no }}</td>
                                            <td *ngIf="currentUserRole != 'PackagingPlanning' && currentUserRole != 'TuftingPlanning' && currentUserRole != 'MoldingPlanning' && currentUserRole != 'Packaging' && currentUserRole != 'Tufting' && currentUserRole != 'Molding'">{{ given.department_name }}</td>
                                            <td *ngIf="currentUserRole != 'PackagingPlanning' && given.operator_name != null">{{ given.operator_name }}</td>

                                            <td *ngIf="currentUserRole != 'PackagingPlanning' && given.operator_name == null">-</td>
                                            <td *ngIf="currentUserRole != 'PackagingPlanning' && given.machine_name != null">{{ given.machine_name }} ({{ given.machine_number }})</td>
                                            <td *ngIf="currentUserRole != 'PackagingPlanning' && given.machine_name == null">-</td>
                                            <td>{{ given.product_name }}</td>
                                            <td>{{ given.color_name }}</td>
                                            <td>{{ given.required_qty }}</td>
                                            <td>{{ given.require_date }}</td>
                                            <td>{{ given.ok_qty }}</td>
                                            <td>
                                                <span class="badge badge-pill badge-light-primary mr-1" *ngIf="given.status == 3">Completed With Extra Qty</span>
                                                <span class="badge badge-pill badge-light-info mr-1" *ngIf="given.status == 2">Half Completed</span>
                                                <span class="badge badge-pill badge-light-success mr-1" *ngIf="given.status == 1">Complated</span>
                                                <span class="badge badge-pill badge-light-warning mr-1" *ngIf="given.status == 0">Pending</span>
                                            </td>
                                            <td *ngIf="currentUserRole != 'Packaging' && currentUserRole != 'Tufting' && currentUserRole != 'Molding'">
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(given.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="givenList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Production Given</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-12 pt-1 overflow-auto">
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">

                        <div class="form-group col-lg-8">
                            <label for="register-department_id" class="form-label">Select Department<span class="text-danger">*</span></label>
                            <ng-select formControlName="department_id" aria-describedby="register-department_id" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.operator_id.errors }">
                                <ng-option [value]="" selected disabled>Select Department</ng-option>
                                <ng-option *ngFor="let department of departmentsDropDown" [value]="department.id">{{department.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.department_id.errors" class="invalid-feedback">
                                <div *ngIf="f.department_id.errors.required">Department is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4">
                            <label class="form-label">Require Date<span class="text-danger">*</span></label>
                            <ng2-flatpickr [config]="basicDateOptions" formControlName="require_date" tabindex="2" name="require_date" [ngClass]="{ 'is-invalid': submitted && f.require_date.errors }"></ng2-flatpickr>
                            <div *ngIf="submitted && f.require_date.errors" class="invalid-feedback">
                                <div *ngIf="f.require_date.errors.required">Require Date is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6" *ngIf="currentUserRole != 'PackagingPlanning'">
                            <label for="register-operator_id" class="form-label">Select Operator </label>
                            <ng-select formControlName="operator_id" aria-describedby="register-operator_id" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.operator_id.errors }">
                                <ng-option [value]="" selected disabled>Select Operator</ng-option>
                                <ng-option *ngFor="let operator of operatorsDropDown" [value]="operator.id">{{operator.operator_name}}</ng-option>
                            </ng-select>

                        </div>
                        <div class="form-group col-lg-6" *ngIf="currentUserRole != 'PackagingPlanning'">
                            <label for="register-machine_id" class="form-label">Select Machine </label>
                            <ng-select formControlName="machine_id" aria-describedby="register-machine_id" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.machine_id.errors }">
                                <ng-option [value]="" selected disabled>Select Machine</ng-option>
                                <ng-option *ngFor="let machine of machinesDropDown" [value]="machine.id">{{machine.machine_name}}</ng-option>
                            </ng-select>

                        </div>
                        <div class="form-group col-lg-6">
                            <label for="register-raw_material_product" class="form-label">Select Raw Material Product <span class="text-danger">*</span></label>
                            <ng-select formControlName="raw_material_product" aria-describedby="register-raw_material_product" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.raw_material_product.errors }">
                                <ng-option [value]="" selected disabled>Select Raw Material Product</ng-option>
                                <ng-option *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown" [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.raw_material_product.errors" class="invalid-feedback">
                                <div *ngIf="f.raw_material_product.errors.required">Raw Material Product is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <label for="register-color_id" class="form-label">Select Color <span class="text-danger">*</span></label>
                            <ng-select formControlName="color_id" aria-describedby="register-color_id" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.color_id.errors }">
                                <ng-option [value]="" selected disabled>Select Color</ng-option>
                                <ng-option *ngFor="let color of colorsDropDown" [value]="color.id">{{color.color_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.color_id.errors" class="invalid-feedback">
                                <div *ngIf="f.color_id.errors.required">Color is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3">
                            <label for="register-required_qty" class="form-label">Required Qty<span class="text-danger">*</span></label>
                            <input type="number" formControlName="required_qty" class="form-control" placeholder="Required Qty" aria-describedby="register-required_qty" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.required_qty.errors }" />
                            <div *ngIf="submitted && f.required_qty.errors" class="invalid-feedback">
                                <div *ngIf="f.required_qty.errors.required">Required Qty is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <div class="form-group">
                                <label for="register-description" class="form-label">Description </label>
                                <textarea rows="5" formControlName="description" class="form-control" placeholder="description" aria-describedby="register-description" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            </div>
                        </div>
                        <div class="col col-sm-12 col-xs-12 col-lg-12">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>
                        <div class="col col-sm-6 col-xs-6 col-lg-12 pt-1 text-right">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" tabindex="9" rippleEffect>
                                Close
                            </button>
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="8" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>
<!-- / Modal -->