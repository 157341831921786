import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { ProductsService } from 'app/_services/products.service';
import { ProductionGeneralService } from 'app/_services/production-general.service';


@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnInit {

  public rowMaterialProductList: any = [];
  public start: any = 0;
  public page: any = 0;

  public loading = false;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public progressMainTable: boolean = false;
  public currentUser: any;
  public currentUserRole = '';
  public searchByName: any = '';
  public departmentId: any = 0;
  public departmentsDropDown: any;
  public departmentsTransferDropDown: any;

  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public user_wise_raw_material_product_stocks_id: any = 0;


  constructor(private _router: Router, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService, private http: HttpClient, private rowMaterialProductService: RowMaterialProductService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder, private productionGeneralService: ProductionGeneralService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.productList(this.start, this.lengths);
    this.getDepartmentsDropDown();
    this.getDepartmentsTransferDropDown();

    this.newForm = this._formBuilder.group({
      total_qty: [0, [Validators.required]],
      transfer_qty: [0, [Validators.required]],
      transfer_department_id: [null, [Validators.required]]
    });

  }
  get f() {
    return this.newForm.controls;
  }

  productList(my_start: any, my_lengths: any) {
    this.rowMaterialProductService.getStockLog(my_start, my_lengths, this.searchByName, this.departmentId).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.rowMaterialProductList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }

  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }

  getRawMaterialFilter(searchByName) {
    this.searchByName = searchByName;
    this.reloadList();
  }

  getRawMaterialFilterDepartment(departmentId) {
    this.departmentId = departmentId;
    this.reloadList();
  }

  getDepartmentsDropDown() {
    this.productionGeneralService.getDepartmentsDropDown().subscribe(
      data => {
        if (data.status) {
          this.departmentsDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getDepartmentsTransferDropDown() {

    this.productionGeneralService.getDepartmentsTransferDropDown().subscribe(
      data => {
        if (data.status) {
          this.departmentsTransferDropDown = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  downloadReport(type) {
    if (type == 'PDF') {
      this.rowMaterialProductService.downloadPdf(this.searchByName, this.departmentId).subscribe(
        (data: Blob) => {
          let url = window.URL.createObjectURL(data);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'stock-report.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          this._toastrService.error('Something went wrong! Please Try Againg...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
    else {
      this.rowMaterialProductService.downloadExcel(this.searchByName, this.departmentId).subscribe(
        (data: Blob) => {
          let url = window.URL.createObjectURL(data);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'stock-report.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          this._toastrService.error('Something went wrong! Please Try Againg...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }

  modalOpen(modalBasic, rowMaterialProductId, index) {
    this.user_wise_raw_material_product_stocks_id = rowMaterialProductId;
    this.f.total_qty.setValue(this.rowMaterialProductList[index].qty);
    this.f.transfer_qty.setValue(this.rowMaterialProductList[index].qty);
    this.modalService.open(modalBasic, {
      size: 'md'
    });
  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const formdata = new FormData();

      formdata.append('user_wise_raw_material_product_stocks_id', this.user_wise_raw_material_product_stocks_id);
      formdata.append('total_qty', this.f.total_qty.value);
      formdata.append('transfer_qty', this.f.transfer_qty.value);
      formdata.append('transfer_department_id', this.f.transfer_department_id.value);

      this.productionGeneralService.stockTransfer(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.reloadList();
            this.modalService.dismissAll('modalBasic');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

}
