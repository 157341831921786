import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DeliveryChallanService } from 'app/_services/delivery-challan.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { DealersService } from 'app/_services/dealers.service';

@Component({
  selector: 'app-delivery-challan-add',
  templateUrl: './delivery-challan-add.component.html',
  styleUrls: ['./delivery-challan-add.component.scss']
})
export class DeliveryChallanAddComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public allDealer: any;
  public dealerId: any = 0;
  public dealerName: any = '';
  public order_details: any = [];
  public orderDeliveryOrder: any = [];
  public dispatchOrder: any = [];
  public dispatchProductsOrder: any = [];
  public lr_form: boolean = false;
  public set_order: boolean = true;
  public dispatchProductsFinalOrder: any = [];
  public dispatchProductsNewFinalOrder: any = [];
  public pusheditems: any = [];
  public dispatchProductsFinalOrder_submit: any = [];
  public dispatchOrder_submit: any = [];
  public dispatchProductsNewFinalOrder_challan: any = [];
  public grand_total: any = 0.00;
  public main_discount: any = 0.00;
  public tax_one_amt: any = 0.00;
  public tax_two_amt: any = 0.00;
  public round_off: any = 0.00;
  public viewDealerName: boolean = false;
  public isAddMode: boolean = true;
  public loadingViewDealerName: boolean = true;
  public transportDropDown: any = [];
  public dealerGST: any = '';

  constructor(private _authenticationService: AuthenticationService, private http: HttpClient, private deliveryChallanService: DeliveryChallanService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder, private dealersService: DealersService) { }

  ngOnInit(): void {

    if (this.route.snapshot.params['id'] !== undefined) {
      this.dealerId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.dealerId;
    if (!this.isAddMode) {
      this.getPreCustomer(this.dealerId);
      this.viewDealerName = true;
    }

    this.newForm = this._formBuilder.group({
      lr_no: [''],
      transport_id: [''],
      transport_name: [''],
      mobile_no: [''],
      vehicle_no: [''],
      remarks: [''],
      delivery_details: [''],
      main_discount: [0],
      tax_one: [''],
      tax_one_pre: [0],
      tax_two: [''],
      tax_two_pre: [0]
    });
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.deliveryChallanService.dealerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allDealer = data.dealer_data;
        }
        else {
          this.isLoading = false;
          //this._toastrService.error(data.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.allDealer = [];
          this.orderDeliveryOrder = [];
          this.dispatchOrder = [];
          this.dispatchProductsOrder = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allDealer = [];
        this.orderDeliveryOrder = [];
        this.dispatchOrder = [];
        this.dispatchProductsOrder = [];
      }
    );
  }

  selectEvent(item: any) {
    this.dealerId = item.id;
    this.dealerName = item.name;
    this.getRemainingOrder(item.id);
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  getRemainingOrder(orderId: any) {

    this.order_details = [];
    this.orderDeliveryOrder = [];
    this.dispatchOrder = [];
    this.dispatchProductsOrder = [];
    this.dispatchProductsFinalOrder = [];
    this.dispatchProductsNewFinalOrder = [];
    this.pusheditems = [];
    this.dispatchProductsFinalOrder_submit = [];
    this.dispatchOrder_submit = [];
    this.dispatchProductsNewFinalOrder_challan = [];

    this.deliveryChallanService.getDeliveryOrder(orderId).subscribe(
      data => {
        if (data.status) {
          if (data.order.length > 0) {
            this.orderDeliveryOrder = data.order;
            for (var i = 0; i < data.order.length; i++) {
              for (var j = 0; j < data.order[i].order_details.length; j++) {
                this.dispatchOrder = [];
                this.dispatchOrder['order_id'] = data.order[i].order_details[j].order_id;
                this.dispatchOrder['order_meta_id'] = data.order[i].order_details[j].id;
                this.dispatchOrder['product_id'] = data.order[i].order_details[j].product_id;
                this.dispatchOrder['product_code'] = data.order[i].order_details[j].product_code;
                this.dispatchOrder['product_name'] = data.order[i].order_details[j].product_name;
                this.dispatchOrder['size_title'] = data.order[i].order_details[j].size_title;
                this.dispatchOrder['color_title'] = data.order[i].order_details[j].color_title;
                this.dispatchOrder['order_qty'] = data.order[i].order_details[j].order_qty;
                this.dispatchOrder['dispatch_qty'] = data.order[i].order_details[j].dispatch_qty;
              
                this.dispatchOrder['sales_price'] = data.order[i].order_details[j].sales_price;
              
                this.dispatchOrder['discount_amt'] =  0;
                
                if(data.order[i].order_details[j].discount > 0){
                  this.dispatchOrder['discount_amt'] = (data.order[i].order_details[j].sales_price * data.order[i].order_details[j].discount) / 100;
                }

                this.dispatchOrder['gst_per'] = data.order[i].order_details[j].gst_per;
                this.dispatchOrder['gst_amt'] = data.order[i].order_details[j].gst_amt;
                this.dispatchOrder['other_tax_per'] = data.order[i].order_details[j].other_tax_per;
                this.dispatchOrder['other_tax_amt'] = data.order[i].order_details[j].other_tax_amt;

                if (data.order[i].order_details[j].gst_per > 0) {
                  this.dispatchOrder['igst_per'] = data.order[i].order_details[j].gst_per;
                  this.dispatchOrder['cgst_per'] = data.order[i].order_details[j].gst_per / 2;
                  this.dispatchOrder['sgst_per'] = data.order[i].order_details[j].gst_per / 2;
                  this.dispatchOrder['igst_amt'] = (data.order[i].order_details[j].gst_amt) * data.order[i].order_details[j].dispatch_qty;
                  this.dispatchOrder['cgst_amt'] = (data.order[i].order_details[j].gst_amt / 2) * data.order[i].order_details[j].dispatch_qty;
                  this.dispatchOrder['sgst_amt'] = (data.order[i].order_details[j].gst_amt / 2) * data.order[i].order_details[j].dispatch_qty;
                }
                else {
                  this.dispatchOrder['igst_per'] = 0;
                  this.dispatchOrder['cgst_per'] = 0;
                  this.dispatchOrder['sgst_per'] = 0;
                  this.dispatchOrder['igst_amt'] = 0;
                  this.dispatchOrder['cgst_amt'] = 0;
                  this.dispatchOrder['sgst_amt'] = 0;
                }
                this.dispatchOrder['old_sales_price'] = data.order[i].order_details[j].sales_price;
                this.dispatchOrder['discount'] = data.order[i].order_details[j].discount;
                
                this.dispatchOrder['sub_total'] = data.order[i].order_details[j].sub_total;
                this.dispatchOrder['invoice_no'] = data.order[i].invoice_no;
                this.dispatchOrder['display_price'] = data.order[i].order_details[j].sales_price - ((data.order[i].order_details[j].sales_price * data.order[i].order_details[j].discount) / 100);

                this.dispatchProductsOrder.push(this.dispatchOrder);
              }
            }
          }
          else {
            this.allDealer = [];
            this.orderDeliveryOrder = [];
            this.dispatchOrder = [];
            this.dispatchProductsOrder = [];
            this._toastrService.clear();
            this._toastrService.warning('No Pending Order!', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  countChange(qty: any, orderId: any, metaId: any) {

    this.dispatchProductsOrder.forEach((value: any, index: any) => {
      if (value.order_meta_id == metaId) {
        this.dispatchProductsOrder[index]['dispatch_qty'] = parseFloat(qty);
      }
    });

    for (var i = 0; i < this.orderDeliveryOrder.length; i++) {
      for (var j = 0; j < this.orderDeliveryOrder[i].order_details.length; j++) {
        if (this.orderDeliveryOrder[i].order_details[j].id == metaId) {
          this.orderDeliveryOrder[i].order_details[j].dispatch_qty = parseFloat(qty);
          break;
        }
      }
    }
  }

  previewDispatchProducts() {
    this.getTransportDropDown();
    this.dispatchProductsFinalOrder = [];
    let totalDispatch_qty = 0;
    this.dispatchProductsOrder.forEach((values: any, index: any) => {
    if (values.dispatch_qty > 0) {
        let sales_price = this.dispatchProductsOrder[index].sales_price;
        let gst = 0;
        let other_tax = 0;  
        let taxable = 0;      
        this.dispatchProductsOrder[index].discount_amt = 0;
        if (values.discount > 0) {
          this.dispatchProductsOrder[index].discount_amt = ((sales_price * values.discount) / 100);// * this.dispatchProductsOrder[index].dispatch_qty
          sales_price = sales_price - ((sales_price * values.discount) / 100);
        }
        this.dispatchProductsOrder[index].taxable = taxable = sales_price * values.dispatch_qty;
        if (values.gst_per > 0) {
          gst = ((taxable * values.gst_per) / 100);
        }
        if (values.other_tax_per > 0) {
          other_tax = ((taxable * values.other_tax_per) / 100);
        }
        sales_price = sales_price + gst + other_tax;
        let taxSubTotal = values.taxable + gst  + other_tax;
        if (values.gst_per > 0) {
          this.dispatchProductsOrder[index].igst_per = values.gst_per;
          this.dispatchProductsOrder[index].cgst_per = values.gst_per / 2;
          this.dispatchProductsOrder[index].sgst_per = values.gst_per / 2;
          this.dispatchProductsOrder[index].cgst_amt = (gst / 2);
          this.dispatchProductsOrder[index].sgst_amt = (gst / 2);
          this.dispatchProductsOrder[index].igst_amt = gst;
        }
        else {
          this.dispatchProductsOrder[index].igst_per = 0;
          this.dispatchProductsOrder[index].cgst_per = 0;
          this.dispatchProductsOrder[index].sgst_per = 0;
          this.dispatchProductsOrder[index].cgst_amt = 0;
          this.dispatchProductsOrder[index].sgst_amt = 0;
          this.dispatchProductsOrder[index].igst_amt = 0;
        }
        this.dispatchProductsOrder[index].other_tax_amt = other_tax;
        this.dispatchProductsOrder[index].sub_total = taxSubTotal;
        this.dispatchProductsFinalOrder.push(this.dispatchProductsOrder[index]);
        totalDispatch_qty += values.dispatch_qty;
      }

    });
    if (totalDispatch_qty == 0) {
      this._toastrService.clear();
      this._toastrService.warning('Select Dispatch Quantity At Least One', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });

    } else {
      this.dispatchProductsNewFinalOrder = [];
      this.pusheditems = [];
      this.dispatchProductsFinalOrder.forEach((value: any, index: any) => {
       if (this.pusheditems[value.product_id] !== undefined) {
        
         
          this.pusheditems[value.product_id]['dispatch_qty'] = parseFloat(this.pusheditems[value.product_id]['dispatch_qty']) + parseFloat(value.dispatch_qty);
          let sales_price = value.sales_price;
          let gst = 0;
          let other_tax = 0;     

          this.pusheditems[value.product_id]['discount_amt'] = 0;

          if (value.discount > 0) {
            this.pusheditems[value.product_id]['discount_amt'] = ((sales_price * value.discount) / 100);
            sales_price = sales_price - ((sales_price * value.discount) / 100);
           
          }
          this.pusheditems[value.product_id]['taxable'] = sales_price * this.pusheditems[value.product_id]['dispatch_qty'];

          if (value.gst_per > 0) {
            gst = ((this.pusheditems[value.product_id]['taxable'] * value.gst_per) / 100);
          }
          if (value.other_tax_per > 0) {
            other_tax = (( this.pusheditems[value.product_id]['taxable'] * value.other_tax_per) / 100);
          }
          if (value.gst_per > 0) {
            this.pusheditems[value.product_id]['igst_per'] = value.gst_per;
            this.pusheditems[value.product_id]['cgst_per'] = value.gst_per / 2;
            this.pusheditems[value.product_id]['sgst_per'] = value.gst_per / 2;
            this.pusheditems[value.product_id]['igst_amt'] = gst;
            this.pusheditems[value.product_id]['cgst_amt'] = (gst / 2);
            this.pusheditems[value.product_id]['sgst_amt'] = (gst / 2);
          }
          else {
            this.pusheditems[value.product_id]['igst_per'] = 0;
            this.pusheditems[value.product_id]['cgst_per'] = 0;
            this.pusheditems[value.product_id]['sgst_per'] = 0;
            this.pusheditems[value.product_id]['igst_amt'] = 0;
            this.pusheditems[value.product_id]['cgst_amt'] = 0;
            this.pusheditems[value.product_id]['sgst_amt'] = 0;
          }
          this.pusheditems[value.product_id]['other_tax_amt'] = other_tax;


          this.pusheditems[value.product_id]['sub_total'] = this.pusheditems[value.product_id]['taxable'] + gst + other_tax;
        } else {
          this.pusheditems[value.product_id] = value;
          this.dispatchProductsNewFinalOrder.push(this.pusheditems[value.product_id]);
        }
      });

      this.lr_form = true;
      this.set_order = false;
    }

  }
  backDispatchProducts() {
    this.dispatchProductsOrder = [];
    // if (this.orderDeliveryOrder.length > 0) {
    //   for (var i = 0; i < this.orderDeliveryOrder.length; i++) {
    //     for (var j = 0; j < this.orderDeliveryOrder[i].order_details.length; j++) {
    //       this.dispatchOrder = [];
    //       this.dispatchOrder = [];
    //       this.dispatchOrder['order_id'] = this.orderDeliveryOrder.order[i].order_details[j].order_id;
    //       this.dispatchOrder['order_meta_id'] = this.orderDeliveryOrder.order[i].order_details[j].id;
    //       this.dispatchOrder['product_id'] = this.orderDeliveryOrder.order[i].order_details[j].product_id;
    //       this.dispatchOrder['product_code'] = this.orderDeliveryOrder.order[i].order_details[j].product_code;
    //       this.dispatchOrder['product_name'] = this.orderDeliveryOrder.order[i].order_details[j].product_name;
    //       this.dispatchOrder['size_title'] = this.orderDeliveryOrder.order[i].order_details[j].size_title;
    //       this.dispatchOrder['color_title'] = this.orderDeliveryOrder.order[i].order_details[j].color_title;
    //       this.dispatchOrder['order_qty'] = this.orderDeliveryOrder.order[i].order_details[j].order_qty;
    //       this.dispatchOrder['dispatch_qty'] = this.orderDeliveryOrder.order[i].order_details[j].dispatch_qty;       
    //       this.dispatchOrder['sales_price'] = this.orderDeliveryOrder.order[i].order_details[j].sales_price;       
    //       this.dispatchOrder['gst_per'] = this.orderDeliveryOrder.order[i].order_details[j].gst_per;
    //       this.dispatchOrder['other_tax_per'] = this.orderDeliveryOrder.order[i].order_details[j].other_tax_per;
    //       this.dispatchOrder['other_tax_amt'] = this.orderDeliveryOrder.order[i].order_details[j].other_tax_amt * this.orderDeliveryOrder.order[i].order_details[j].dispatch_qty;
    //       this.dispatchOrder['gst_amt'] = this.orderDeliveryOrder.order[i].order_details[j].gst_amt;
    //       if (this.orderDeliveryOrder.order[i].order_details[j].gst_per > 0) {
    //         this.dispatchOrder['igst_per'] = this.orderDeliveryOrder.order[i].order_details[j].gst_per;
    //         this.dispatchOrder['cgst_per'] = this.orderDeliveryOrder.order[i].order_details[j].gst_per / 2;
    //         this.dispatchOrder['sgst_per'] = this.orderDeliveryOrder.order[i].order_details[j].gst_per / 2;
    //         this.dispatchOrder['igst_amt'] = (this.orderDeliveryOrder.order[i].order_details[j].gst_amt) * this.orderDeliveryOrder.order[i].order_details[j].dispatch_qty;
    //         this.dispatchOrder['cgst_amt'] = (this.orderDeliveryOrder.order[i].order_details[j].gst_amt / 2) * this.orderDeliveryOrder.order[i].order_details[j].dispatch_qty;
    //         this.dispatchOrder['sgst_amt'] = (this.orderDeliveryOrder.order[i].order_details[j].gst_amt / 2) * this.orderDeliveryOrder.order[i].order_details[j].dispatch_qty;
    //       }
    //       else {
    //         this.dispatchOrder['igst_per'] = 0;
    //         this.dispatchOrder['cgst_per'] = 0;
    //         this.dispatchOrder['sgst_per'] = 0;
    //         this.dispatchOrder['igst_amt'] = 0;
    //         this.dispatchOrder['cgst_amt'] = 0;
    //         this.dispatchOrder['sgst_amt'] = 0;
    //       }
    //       this.dispatchOrder['discount_amt'] =  0;
    //       if(this.orderDeliveryOrder.order[i].order_details[j].discount > 0){
    //         this.dispatchOrder['discount_amt'] = (this.orderDeliveryOrder.order[i].order_details[j].sales_price * this.orderDeliveryOrder.order[i].order_details[j].discount) / 100;
    //       }
    //       this.dispatchOrder['old_sales_price'] = this.orderDeliveryOrder.order[i].order_details[j].sales_price;
    //       this.dispatchOrder['discount'] = this.orderDeliveryOrder.order[i].order_details[j].discount;
    //       this.dispatchOrder['sub_total'] = this.orderDeliveryOrder.order[i].order_details[j].sub_total;
    //       this.dispatchOrder['invoice_no'] = this.orderDeliveryOrder.order[i].invoice_no;
    //       this.dispatchOrder['display_price'] = this.orderDeliveryOrder.order[i].order_details[j].sales_price - ((this.orderDeliveryOrder.order[i].order_details[j].sales_price * this.orderDeliveryOrder.order[i].order_details[j].discount) / 100);
    //       this.dispatchProductsOrder.push(this.dispatchOrder);
    //     }
    //   }
    // }
    // this.dispatchProductsFinalOrder = [];
    // this.dispatchProductsNewFinalOrder = [];
    // this.pusheditems = [];

    // this.lr_form = false;
    // this.set_order = true;
    if (this.orderDeliveryOrder.length > 0) {
      this.dispatchProductsOrder = []; // Initialize the array for dispatch orders
      this.orderDeliveryOrder.forEach(order => {
        order.order_details.forEach(detail => {
          const dispatchOrder = {}; // Create a new object for each dispatch order
          const productDetails = detail; // Alias for cleaner code
          
          // Basic order details
          dispatchOrder['order_id'] = productDetails.order_id;
          dispatchOrder['order_meta_id'] = productDetails.id;
          dispatchOrder['product_id'] = productDetails.product_id;
          dispatchOrder['product_code'] = productDetails.product_code;
          dispatchOrder['product_name'] = productDetails.product_name;
          dispatchOrder['size_title'] = productDetails.size_title;
          dispatchOrder['color_title'] = productDetails.color_title;
          dispatchOrder['order_qty'] = productDetails.order_qty;
          dispatchOrder['dispatch_qty'] = productDetails.dispatch_qty;
          dispatchOrder['sales_price'] = productDetails.sales_price;
          dispatchOrder['gst_per'] = productDetails.gst_per;
          dispatchOrder['other_tax_per'] = productDetails.other_tax_per;
          dispatchOrder['other_tax_amt'] = productDetails.other_tax_amt * productDetails.dispatch_qty;
          dispatchOrder['gst_amt'] = productDetails.gst_amt;
    
          // GST calculations
          if (productDetails.gst_per > 0) {
            const gstAmt = productDetails.gst_amt * productDetails.dispatch_qty;
            dispatchOrder['igst_per'] = productDetails.gst_per;
            dispatchOrder['cgst_per'] = dispatchOrder['igst_per'] / 2;
            dispatchOrder['sgst_per'] = dispatchOrder['igst_per'] / 2;
            dispatchOrder['igst_amt'] = gstAmt;
            dispatchOrder['cgst_amt'] = gstAmt / 2;
            dispatchOrder['sgst_amt'] = gstAmt / 2;
          } else {
            // No GST case
            dispatchOrder['igst_per'] = 0;
            dispatchOrder['cgst_per'] = 0;
            dispatchOrder['sgst_per'] = 0;
            dispatchOrder['igst_amt'] = 0;
            dispatchOrder['cgst_amt'] = 0;
            dispatchOrder['sgst_amt'] = 0;
          }
    
          // Discount calculations
          const discount = productDetails.discount > 0 
            ? (productDetails.sales_price * productDetails.discount) / 100 
            : 0;
          dispatchOrder['discount_amt'] = discount;
          dispatchOrder['old_sales_price'] = productDetails.sales_price;
          dispatchOrder['discount'] = productDetails.discount;
          dispatchOrder['sub_total'] = productDetails.sub_total;
          dispatchOrder['invoice_no'] = order.invoice_no;
    
          // Calculate display price after discount
          dispatchOrder['display_price'] = productDetails.sales_price - discount;
    
          // Push to final dispatch products array
          this.dispatchProductsOrder.push(dispatchOrder);
        });
      });
    }
    
    // Reset other variables after processing
    this.dispatchProductsFinalOrder = [];
    this.dispatchProductsNewFinalOrder = [];
    this.pusheditems = [];
    this.lr_form = false;
    this.set_order = true;
    
  }

  get f() {
    return this.newForm.controls;
  }

  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      //  this.loading = true;
      this.dispatchProductsFinalOrder_submit = [];
      this.orderDeliveryOrder.forEach((value: any, index: any) => {

        value.order_details.forEach((meta_value: any, meta_index: any) => {
          if (meta_value.dispatch_qty > 0) {
            this.dispatchOrder_submit = [];
            this.dispatchOrder_submit['meta_id'] = meta_value.id;
            this.dispatchOrder_submit['order_id'] = meta_value.order_id;
            this.dispatchOrder_submit['product_id'] = meta_value.product_id;
            this.dispatchOrder_submit['dispatch_qty'] = meta_value.dispatch_qty;
            this.dispatchOrder_submit['sales_price'] = meta_value.sales_price;
            this.dispatchOrder_submit['display_price'] = meta_value.display_price;
            this.dispatchOrder_submit['sub_total'] = meta_value.sub_total;
            this.dispatchProductsFinalOrder_submit.push(this.dispatchOrder_submit);
          }
        });
      });

      this.dispatchProductsNewFinalOrder.forEach((challan_value: any, challan_index: any) => {
        this.dispatchOrder_submit = [];
        this.dispatchOrder_submit['order_id'] = challan_value.order_id;
        this.dispatchOrder_submit['product_id'] = challan_value.product_id;
        this.dispatchOrder_submit['dispatch_qty'] = challan_value.dispatch_qty;
        this.dispatchOrder_submit['dispatch_price'] = challan_value.sales_price;
        this.dispatchOrder_submit['dispatch_discount'] = challan_value.discount;
        this.dispatchOrder_submit['dispatch_sub_total'] = challan_value.sub_total;
        this.dispatchProductsNewFinalOrder_challan.push(this.dispatchOrder_submit);
      });

      let challanProductsArray: any = [];
      this.dispatchProductsNewFinalOrder_challan.forEach((value: any, index: any) => {

        challanProductsArray.push({
          'order_id': value.order_id,
          'meta_id': value.meta_id,
          'product_id': value.product_id,
          'dispatch_qty': value.dispatch_qty,
          'dispatch_price': value.dispatch_price,
          'dispatch_discount': value.dispatch_discount,
          'dispatch_sub_total': value.dispatch_sub_total
        });
      });

      let orderProductsArray: any = [];
      this.dispatchProductsFinalOrder_submit.forEach((value: any, index: any) => {
        orderProductsArray.push({
          'meta_id': value.meta_id,
          'product_id': value.product_id,
          'dispatch_qty': value.dispatch_qty,
          'sales_price': value.sales_price,
          'display_price': value.display_price,
          'discount': 0,
          'sub_total': value.sub_total
        });
      });

      const formdata = new FormData();
      formdata.append('lr_no', this.f.lr_no.value);
      formdata.append('transport_id', this.f.transport_id.value);
      formdata.append('transport_name', this.f.transport_name.value);
      formdata.append('mobile_no', this.f.mobile_no.value);
      formdata.append('vehicle_no', this.f.vehicle_no.value);
      formdata.append('remarks', this.f.remarks.value);
      formdata.append('delivery_details', JSON.stringify(challanProductsArray));
      formdata.append('order_details', JSON.stringify(orderProductsArray));
      formdata.append('main_discount', this.f.main_discount.value);
      formdata.append('tax_one', this.f.tax_one.value);
      formdata.append('tax_one_pre', this.f.tax_one_pre.value);
      formdata.append('tax_two', this.f.tax_two.value);
      formdata.append('tax_two_pre', this.f.tax_two_pre.value);

      this.deliveryChallanService.add(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/delivery-challan']);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          this.loading = true;
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

      this.submitted = false;
    }
  }

  priceChange(event: any, id: any, key: any) {

    if (event.target.value < 0) {
      this.dispatchProductsNewFinalOrder[key].sales_price = 0;
      this.dispatchProductsNewFinalOrder[key].sub_total = 0;
      this._toastrService.clear();
      this._toastrService.warning('Rate must be 0 (zero) or more than 0 (zero)', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
    else {
      this.dispatchProductsNewFinalOrder[key].sales_price = event.target.value;

      this.dispatchProductsNewFinalOrder[key].sub_total = event.target.value * this.dispatchProductsNewFinalOrder[key].dispatch_qty;
    }

  }


  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.dispatchProductsNewFinalOrder.length; i++) {
      sum += this.dispatchProductsNewFinalOrder[i][index];
    }
    return sum;
  }

  getGrandTotal() {
    let sub_total = this.getSum('sub_total');
    this.round_off = Math.round((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt) - ((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt);
    return Math.round((sub_total - this.main_discount) + this.tax_one_amt + this.tax_two_amt);
  }

  totalCalc(e, me: any = '') {

    const charCode = e.which ? e.which : e.keyCode;



    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {

      if (charCode != 110) {
        if (me == "main_discount") {
          this.f.main_discount.setValue(0);
        }
        if (me == "tax_one_pre") {
          this.f.tax_one_pre.setValue(0);
        }
        if (me == "tax_two_pre") {
          this.f.tax_two_pre.setValue(0);
        }

        this._toastrService.clear();
        this._toastrService.warning('OOPs! Only numeric values or digits allowed', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    }


    /* Start For Main Dicount */
    if (this.f.main_discount.value > 0) {
      let sub_total = this.getSum('sub_total');
      let dicount_per = this.f.main_discount.value;
      this.main_discount = (sub_total * dicount_per) / 100;
    }
    else if (this.f.main_discount.value < 0) {
      this.f.main_discount.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Discount must be 0 (zero) or more than 0 (zero)', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.main_discount = 0;
    }
    else {
      this.main_discount = 0;
    }
    /* END For Main Dicount */

    /* Start For Main Tax One */
    if (this.f.tax_one_pre.value > 0) {
      let sub_total = this.getSum('sub_total') - this.main_discount;
      let tax_one_pre = this.f.tax_one_pre.value;
      this.tax_one_amt = ((sub_total * tax_one_pre) / 100);
    }
    else if (this.f.tax_one_pre.value < 0) {
      this.f.tax_one_pre.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Tax must be 0 (zero) or more than 0 (zero)', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.tax_one_amt = 0;
    }
    else {
      this.tax_one_amt = 0;
    }
    /* END For Main Tax One */

    /* Start For Main Tax Two */
    if (this.f.tax_two_pre.value > 0) {
      let sub_total = this.getSum('sub_total') - this.main_discount;
      let tax_two_pre = this.f.tax_two_pre.value;
      this.tax_two_amt = ((sub_total * tax_two_pre) / 100);
    }
    else if (this.f.tax_one_pre.value < 0) {
      this.f.tax_two_pre.setValue(0);
      this.f.main_discount.setValue(0);
      this._toastrService.clear();
      this._toastrService.warning('Tax must be 0 (zero) or more than 0 (zero)', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      this.tax_two_amt = 0;
    }
    else {
      this.tax_two_amt = 0;
    }
    /* END For Main Tax Two */

  }


  getPreCustomer(dealerId) {
    this.loadingViewDealerName = true;
    this.dealersService.dealerViewDetails(dealerId).subscribe(
      response => {
        this.loadingViewDealerName = false;
        if (response.status) {
          this.dealerId = response.dealer.id;
          this.dealerGST = response.dealer.state_code;
          if (response.dealer.owner_name !== null) {
            this.dealerName = response.dealer.name + '(' + response.dealer.phone + ') - ' + response.dealer.owner_name;
          }
          else {
            this.dealerName = response.dealer.name + '(' + response.dealer.phone + ')';
          }
          this.getRemainingOrder(response.dealer.id);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getTransportDropDown() {
    this.dealersService.getTransportsDropDown().subscribe(
      data => {
        if (data.status) {
          this.transportDropDown = data.transport;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  changeTransport(transportId: any) {
    this.transportDropDown.forEach((value: any, index: any) => {
      if (value.id == transportId) {
        this.f.transport_name.setValue(value.name);
        this.f.mobile_no.setValue(value.mobile);
      }
    });

  }
}
