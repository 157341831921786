<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Raw Material Stock Maintain</h4>
                            <div class="col-12 col-md-6 d-none d-sm-inline-block pb-1">
                                <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                    <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Raw Material name..." />
                                    <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                </div>
                            </div>
                            <div class="col-12 d-sm-none d-inline-block pb-1">
                                <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                    <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by Raw Material name..." />
                                    <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>
                        <div class="card-body p-0"  *ngIf="!loading" >
                            <form name="form" autocomplete="off" [formGroup]="newForm">
                                <div class="col p-0 mb-1 table-responsive">
                                    <table class="table table-striped table-hover valign-middle text-dark mb-1 pr-1">
                                        <thead>
                                            <tr>
                                                <th class="action-column">#</th>
                                                <th>Name</th>
                                                <th class="text-nowrap">Current Stock</th>
                                                <th class="text-nowrap">Real Stock</th>
                                                <th class="text-nowrap">Difference</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="rowMaterialProductList?.length != 0" formArrayName="items">
                                            <tr *ngFor="let item of items.controls; let i = index" [formGroupName]="i" >
                                                <td>{{i+1}}</td>
                                                <td>{{ item.get('product_name')?.value }}</td>
                                                <td>{{ item.get('current_stock')?.value }}</td>
                                                <td>
                                                    <input type="number" formControlName="real_stock" class="form-control" placeholder="Real stock"  [ngClass]="{ 'is-invalid': submitted && item.get('real_stock').errors }" minlength="0" />
                                                    <div *ngIf="submitted && item.get('real_stock').errors" class="invalid-feedback">
                                                        <div *ngIf="item.get('real_stock').errors.required">Real stock is required</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="number" formControlName="difference" class="form-control" placeholder="Difference"  [ngClass]="{ 'is-invalid': submitted && item.get('difference').errors }" disabled />
                                                    <div *ngIf="submitted && item.get('difference').errors" class="invalid-feedback">
                                                        <div *ngIf="item.get('difference').errors.required">Difference stock is required</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="rowMaterialProductList?.length == 0">
                                            <tr *ngIf="!loading">
                                                <td colspan="8" class="no-data-available">No Data!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                    <div class="d-flex align-items-center justify-content-center">
                                        <button *ngIf="!allItemsLoaded" (click)="rowStockProductList()" class="btn btn-dark mr-1">
                                            <span *ngIf="loadingLoad" class="spinner-border spinner-border-sm mr-1"></span>Load More
                                        </button>
                                        <button [disabled]="loadingForm" class="btn btn-primary mr-1" (click)="onSubmit()" rippleEffect>
                                            <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                    <!-- <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 30" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
