import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { MachineService } from 'app/_services/machine.service';

@Component({
  selector: 'app-machine-master',
  templateUrl: './machine-master.component.html',
  styleUrls: ['./machine-master.component.scss']
})
export class MachineMasterComponent implements OnInit {

  constructor(private _coreConfigService: CoreConfigService, private machineService: MachineService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }


  public machineList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public submitted: boolean = false;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public machineId = 0;
  public machine_selected_photo: any = '';

  public error = '';
  public newForm: FormGroup;
  public loading = false;
  public selected_photo_AvatarImage: string = 'assets/images/machine.jpg';

  ngOnInit(): void {

    this.loading = true;
    this.machinesLists(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      name: ['', Validators.required],
      machine_no: [null, [Validators.required]],
      description: [null]
    });
  }

  
  machinesLists(my_start: any, my_lengths: any) {
    this.machineService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.machineList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  // modal Basic
  modalOpen(modalBasic) {
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }

  uploadFile(event: any) {
    this.machine_selected_photo = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.selected_photo_AvatarImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.machineService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.machinesLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Machine Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.machinesLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.machinesLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.machinesLists(this.start, this.lengths);
  }
  get f() {
    return this.newForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;

      const formdata = new FormData();
      formdata.append('machine_photos', this.machine_selected_photo);
      formdata.append('machine_name', this.f.name.value);
      formdata.append('machine_number', this.f.machine_no.value);
      formdata.append('description', this.f.description.value);

      this.machineService.add(formdata, this.machineId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.machineId = 0;
            this.submitted = false;
            this.newForm.reset();
            this.modalService.dismissAll('modalBasic');

            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this.error = data.error;
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

}
