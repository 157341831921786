import { Component, ViewChild, AfterViewInit, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboadService } from 'app/_services/dashboad.service'
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from 'app/_services/orders.service';
import { CoreConfigService } from '@core/services/config.service';
import { ClipboardService } from 'ngx-clipboard';
import { colors } from 'app/colors.const';
import Swal from 'sweetalert2';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import { GatePassService } from 'app/_services/gate-pass.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  constructor(private clipboardApi: ClipboardService, private _coreConfigService: CoreConfigService, private modalService: NgbModal, private ordersService: OrdersService, private _router: Router, private _authenticationService: AuthenticationService, public dashboadService: DashboadService, private _toastrService: ToastrService,
    private _gatePassService: GatePassService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }

  public todayOrder: any = 0;
  public allOrder: any = 0;
  public allProduct: any = 0;
  public allDealer: any = 0;
  public contentHeader: object
  public fastMoving: any = [];
  public slowMoving: any = [];
  public lastTowDayOrder: any = [];
  public pendingOrderWithDueDay: any = [];
  public stockAlert: any = [];

  public start: any = 0;
  public page: any = 0;
  public pageAdvancedEllipses = 1;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 5;
  public loading = false;
  public loadingDueDay = false;

  public pageStockAlert = 0;
  public lengthsStockAlert = 5;
  public loadingStockAlert = false;
  public collectionSizeStockAlert = 0;
  public pageAdvancedEllipsesStockAlert = 1;
  public pageBasicTextStockAlert = 1;
  public currentUserRole: any;

  public pageOrder = 0;
  public lengthsOrder = 5;
  public loadingOrder = false;
  public collectionSizeOrder = 0;
  public pageAdvancedEllipsesOrder = 1;
  public pageBasicTextOrder = 1;
  public is_stock_handle = 0;

  public orderDetails: any = [];

  public currentUser: any;
  public storeLink: any = '';

  // Bar Chart
  public loadingBar = true;
  private successColorShade = '#1e9dd6';
  private successColorShadeSalesman = '#17a2b8';
  private successColorShade2 = '#2A945D';
  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout
  private labelColor = '#000000';

  public barChart: any = [];
  public barChartSalesman: any = [];

  public planExpired = false;
  public planExpiredDay = 0;

  public progressLatestOrder:boolean = false;
  public progressPendingOrder:boolean = false;
  public progressStockAlerts:boolean = false;
  public progressGatePass:boolean = false;
  public pageGatePass = 0;
  public lengthsGatePass = 5;
  public collectionSizeGatePass = 0;
  public gatePassData: any = [];
  public selectedGatePass;

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {

    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.is_stock_handle = JSON.parse(localStorage.getItem('currentUser')).user.is_stock_handle;

    if (JSON.parse(localStorage.getItem('currentUser')).user.remainingDays <= 10) {
      this.planExpired = true;
      this.planExpiredDay = JSON.parse(localStorage.getItem('currentUser')).user.remainingDays;

      if (this.planExpiredDay <= 3) {
        Swal.fire({
          icon: 'warning',
          title: 'Plan Expiry Alert!',
          text: 'Your plan is expiring in ' + this.planExpiredDay + ' days.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });    
      }
    }


    if (this.currentUserRole == 'Client') {
      this.loading = true;
      this.loadingDueDay = true;
      this.loadingStockAlert = true;
      this.getStatistics();
      this.getPendingOrderList(this.page, this.lengths);

      if (this.is_stock_handle == 1) {
        this.getStockAlertList(this.pageStockAlert, this.lengthsStockAlert);
      }
      this.progressLatestOrder = true;
      this.getOrdersList(this.pageOrder, this.lengthsOrder);

      this.progressGatePass = true;
      this.getGetPassList(this.pageGatePass, this.lengthsGatePass);
    }
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }

  }

  getStatistics() {
    this.dashboadService.getData().subscribe(
      response => {
        this.loading = false;

        if (response.status) {
          this.todayOrder = response.data.todayOrder;
          this.allOrder = response.data.allOrder;
          this.allProduct = response.data.allProduct;
          this.allDealer = response.data.allDealer;
          this.fastMoving = response.data.fastMoving;
          this.slowMoving = response.data.slowMoving;

          this.barChart = {
            chartType: 'bar',
            datasets: [
              {
                data: response.data.orderChart.order,
                backgroundColor: this.successColorShade,
                borderColor: 'transparent',
                hoverBackgroundColor: this.successColorShade,
                hoverBorderColor: this.successColorShade,
                label: 'Order'
              },
              {
                data: response.data.orderChart.challan,
                backgroundColor: this.successColorShade2,
                borderColor: 'transparent',
                hoverBackgroundColor: this.successColorShade2,
                hoverBorderColor: this.successColorShade2,
                label: 'Dispatched'
              }
            ],
            labels: response.data.orderChart.date,
            options: {
              elements: {
                rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom'
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                display: false
              },
              tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: this.tooltipShadow,
                backgroundColor: colors.solid.white,
                titleFontColor: colors.solid.black,
                bodyFontColor: colors.solid.black
              },
              scales: {
                xAxes: [
                  {
                    barThickness: 20,
                    display: true,
                    gridLines: {
                      display: true,
                      color: this.grid_line_color,
                      zeroLineColor: this.grid_line_color
                    },
                    scaleLabel: {
                      display: true
                    },
                    ticks: {
                      fontColor: this.labelColor
                    }
                  }
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      color: this.grid_line_color,
                      zeroLineColor: this.grid_line_color
                    },
                    ticks: {
                      stepSize: 1,
                      min: 0,
                      // max: 5,
                      fontColor: this.labelColor
                    }
                  }
                ]
              }
            },
            legend: false
          };

         

          //salesman chart start
          this.barChartSalesman = {
            chartType: 'horizontalBar',
            datasets: [
              {
                data: response.data.salesmanOrderChart.total_orders,
                backgroundColor: this.successColorShadeSalesman,
                borderColor: 'transparent',
                hoverBackgroundColor: this.successColorShadeSalesman,
                hoverBorderColor: this.successColorShadeSalesman,
                label: 'Total Orders',
                barThickness: 20
              }
            ],
            labels: response.data.salesmanOrderChart.employee_name,
            options: {
              elements: {
                rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom'
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                display: false
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    const employeeIndex = tooltipItem.index;
                    const totalOrders = data.datasets[0].data[employeeIndex];
                    const totalAmount = response.data.salesmanOrderChart.total_amount[employeeIndex];
                    return [
                      `Total Orders: ${totalOrders}`,
                      `Total Amount: ${totalAmount}`
                    ];
                  }
                }
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    zeroLineColor: this.grid_line_color
                  },
                  ticks: {
                    fontColor: this.labelColor,
                    beginAtZero: true,
                    stepSize: 1,
                  }
                }],
                yAxes: [{
                  gridLines: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)',
                    zeroLineColor: this.grid_line_color
                  },
                  ticks: {
                    fontColor: this.labelColor,
                    // autoSkip: false,
                    // maxRotation: 90,
                    // minRotation: 45,
                  }
                }]
              }
            }
          };
          
          
          //salesman chart end
          this.loadingBar = false;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getPendingOrderList(my_start: any, my_lengths: any) {
    this.dashboadService.getPendingOrder(my_start, my_lengths).subscribe(
      response => {
        this.loadingDueDay = false;
        if (response.status) {
          
          this.progressPendingOrder = false;

          this.pendingOrderWithDueDay = response.data;
          this.collectionSize = response.recordsTotal;
        }
        else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }


      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadPage(event: any) {
    this.progressPendingOrder = true
    this.page = this.lengths * (event - 1);
    this.getPendingOrderList(this.page, this.lengths);
  }


  getStockAlertList(my_start: any, my_lengths: any) {
    this.dashboadService.getStockAlertList(my_start, my_lengths).subscribe(
      response => {
        this.loadingStockAlert = false;
        this.progressStockAlerts = false;
        if (response.status) {
          this.stockAlert = response.data;
          this.collectionSizeStockAlert = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }

      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadStockAlertPage(event: any) {
    this.progressStockAlerts = true;
    this.pageStockAlert = this.lengthsStockAlert * (event - 1);
    this.getStockAlertList(this.pageStockAlert, this.lengthsStockAlert);
  }

  getOrdersList(my_start: any, my_lengths: any) {
    this.dashboadService.getOrdersList(my_start, my_lengths).subscribe(
      response => {
        this.loadingStockAlert = false;
        this.progressLatestOrder = false;
        if (response.status) {
          this.lastTowDayOrder = response.data;
          this.collectionSizeOrder = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getGetPassList(my_start: any, my_lengths: any) {
    this._gatePassService.notifyData(my_start, my_lengths).subscribe(
      response => {
        this.progressGatePass = false;
        if (response.status) {
          this.gatePassData = response.data;
          this.collectionSizeGatePass = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadGatePass(event: any) {
    this.progressGatePass = true;
    this.pageGatePass = this.lengthsGatePass * (event - 1);
    this.getGetPassList(this.pageGatePass, this.lengthsGatePass);
  }

  loadPageOrder(event: any) {
    this.progressLatestOrder = true;
    this.pageOrder = this.lengthsOrder * (event - 1);
    this.getOrdersList(this.pageOrder, this.lengthsOrder);
  }

  // modal Basic
  modalOpen(modalBasic, orderId: any) {
    this.orderDetails = [];
    this.modalService.open(modalBasic, {
      size: 'xl'
    });

    this.ordersService.getOrderDetails(orderId).subscribe(
      response => {
        if (response.status) {
          response.orders.order_details.forEach((value: any, index: any) => {
            if (response.orders.order_details[index].discount > 0) {
              response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
            }
          });
          this.orderDetails = response.orders;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  // modal Basic
  modalGatePassOpen(modalGatePass, passId: any) {
    this.orderDetails = [];
    this.modalService.open(modalGatePass, {
      size: 'xl'
    });

    this._gatePassService.edit(passId).subscribe(
      response => {
        if (response.status) {
          this.selectedGatePass = response.data;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderDetails.order_details.length; i++) {
      sum += this.orderDetails.order_details[i][index];
    }
    return sum;
  }

  copyText() {
    this.storeLink = JSON.parse(localStorage.getItem('currentUser')).user.store_alias;
    this.clipboardApi.copyFromContent('https://order.digitalorderbook.com/#/home?store=' + this.storeLink);
    this._toastrService.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }



}
