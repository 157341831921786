import { CoreMenu } from '@core/types'
import { notEqual } from 'assert'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME',
    type: 'item',
    role: ['Client', 'Admin', 'GeneralManager', 'Purchase', 'Store', 'Production'],
    icon: 'home',
    url: 'home'
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.HOME',
    type: 'item',
    role: ['SalesMarketing', 'Molding', 'Tufting', 'PackagingAssembly', 'Dispatch', 'HR', 'Account', 'MoldingPlanning', 'TuftingPlanning', 'PackagingPlanning', 'Planning'],
    icon: 'home',
    url: 'manage'
  },
  {
    id: 'production',
    title: 'Planning',
    translate: 'MENU.PRODUCTION',
    type: 'item',
    role: ['SalesMarketing', 'Molding', 'Tufting', 'PackagingAssembly', 'Dispatch', 'HR', 'Account', 'MoldingPlanning', 'TuftingPlanning', 'PackagingPlanning', 'Planning'],
    icon: 'check-square',
    url: 'production-given'
  },
 
  // {
  //   id: 'report',
  //   title: 'Reports',
  //   translate: 'MENU.REPORTS',
  //   type: 'item',
  //   role: ['SalesMarketing', 'Molding', 'Tufting', 'PackagingAssembly', 'Dispatch', 'HR', 'Account', 'MoldingPlanning', 'TuftingPlanning', 'PackagingPlanning', 'Planning'],
  //   icon: 'file',
  //   url: 'report'
  // },
  {
    id: 'master',
    title: 'Master',
    translate: 'MENU.MASTER',
    type: 'collapsible',
    role: ['Client'],
    icon: 'box',
    badge: {
      title: '13',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'categories',
        title: 'Categories',
        translate: 'MENU.CATEGORIES',
        type: 'item',
        role: ['Client'],
        icon: 'tag',
        url: 'categories'
      },
      {
        id: 'products',
        title: 'Products',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'products'
      },
      {
        id: 'sales_executive',
        title: 'Sales Executive',
        translate: 'MENU.EMPLOYEES',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'employees'
      },
      {
        id: 'factory_employee',
        title: 'Factory Employee',
        translate: 'MENU.FACTORY_EMPLOYEES',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'factory-employee'
      },
      {
        id: 'Departments',
        title: 'Departments',
        translate: 'MENU.DEPARTMENTS',
        type: 'item',
        role: ['Client'],
        icon: 'grid',
        url: 'departments'
      },
      {
        id: 'Vendors',
        title: 'Vendors',
        translate: 'MENU.VENDOR',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'vendors'
      },

      {
        id: 'rowmaterialproduct',
        title: 'Raw Material Product',
        translate: 'MENU.ROWMATERIALPRODUCT',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'row-material-product'
      },

      {
        id: 'product_combo',
        title: 'Product Combo',
        translate: 'MENU.PRODUCTCOMBO',
        type: 'item',
        role: ['Client'],
        icon: 'box',
        url: 'product-combo'
      },
      {
        id: 'transport',
        title: 'Transport',
        translate: 'MENU.TRANSPORT',
        type: 'item',
        role: ['Client'],
        icon: 'truck',
        url: 'transport'
      },
      {
        id: 'purchase_department',
        title: 'Purchase Department',
        translate: 'MENU.PURCHASEDEPARTMENT',
        type: 'collapsible',
        role: ['Client'],
        icon: 'shopping-cart',
        badge: {
          title: '1',
          translate: 'MENU.STOREDEPARTMENT',
          classes: 'badge-light-warning badge-pill'
        },
        children: [
          {
            id: 'purchaseorder',
            title: 'Purchase Order',
            translate: 'MENU.PURCHASE_ORDER',
            type: 'item',
            role: ['Client'],
            icon: 'shopping-cart',
            url: 'purchase-order'
          }
        ]
      },
      {
        id: 'production_department',
        title: 'Production Department',
        translate: 'MENU.PRODUCTIONDEPARTMENT',
        type: 'collapsible',
        role: ['Client'],
        icon: 'layers',
        badge: {
          title: '3',
          translate: 'MENU.PRODUCTIONDEPARTMENT',
          classes: 'badge-light-warning badge-pill'
        },
        children: [
          {
            id: 'production-submit',
            title: 'Planning Submit',
            translate: 'MENU.PRODUCTIONSUBMIT',
            type: 'item',
            role: ['Client'],
            icon: 'layers',
            url: 'production-submit'
          },
          {
            id: 'operators',
            title: 'Operators',
            translate: 'MENU.OPERATORS',
            type: 'item',
            role: ['Client'],
            icon: 'users',
            url: 'operator'
          },
          {
            id: 'machines',
            title: 'Machines',
            translate: 'MENU.MACHINE',
            type: 'item',
            role: ['Client'],
            icon: 'airplay',
            url: 'machines'
          },
        ]
      },
      {
        id: 'store_department',
        title: 'Store Department',
        translate: 'MENU.STOREDEPARTMENT',
        type: 'collapsible',
        role: ['Client'],
        icon: 'package',
        badge: {
          title: '3',
          translate: 'MENU.STOREDEPARTMENT',
          classes: 'badge-light-warning badge-pill'
        },
        children: [
          // {
          //   id: 'production_given',
          //   title: 'Planning Given',
          //   translate: 'MENU.PRODUCTIONGIVEN',
          //   type: 'item',
          //   role: ['Client'],
          //   icon: 'server',
          //   url: 'production-given'
          // },
          {
            id: 'warehouse',
            title: 'Warehouse',
            translate: 'MENU.WAREHOUSE',
            type: 'item',
            role: ['Client'],
            icon: 'database',
            url: 'warehouse'
          },
          {
            id: 'product_wise_rawmaterial_stock',
            title: 'Raw Material For Order',
            translate: 'MENU.PRODUCTWISERAWMATERIALSTOCK',
            type: 'item',
            role: ['Client'],
            icon: 'shopping-cart',
            url: 'product-wise-rawmaterial-stock'
          },
          {
            id: 'stock_transfer',
            title: 'Stock Transfer',
            translate: 'MENU.STOCKTRANSFER',
            type: 'item',
            role: ['Client'],
            icon: 'database',
            url: 'stock-transfer-finished-product'
          },
        ]
      },
      {
        id: 'gate_pass',
        title: 'Gate Pass',
        translate: 'MENU.GATEPASS',
        type: 'item',
        role: ['Client'],
        icon: 'users',
        url: 'gate-pass'
      },
    ]
  },
  {
    id: 'dealers',
    title: 'Customers',
    translate: 'MENU.DEALERS',
    type: 'item',
    role: ['Client'],
    icon: 'users',
    url: 'dealers'
  },
  {
    id: 'orders',
    title: 'Orders',
    translate: 'MENU.ORDERS',
    type: 'item',
    role: ['Client', 'User'],
    icon: 'shopping-cart',
    url: 'orders'
  },
  {
    id: 'delivery_challan',
    title: 'Delivery Challan',
    translate: 'MENU.DELIVERYCHALLAN',
    type: 'item',
    role: ['Client'],
    icon: 'truck',
    url: 'delivery-challan'
  },
  {
    id: 'price_per_customer',
    title: 'Price Per Customers',
    translate: 'MENU.PRICEPERCUSTOMER',
    type: 'item',
    role: ['Client'],
    icon: 'list',
    url: 'price-per-customer'
  },
  {
    id: 'reports',
    title: 'Reports',
    translate: 'MENU.REPORTS',
    type: 'collapsible',
    role: ['Client'],
    icon: 'file',
    badge: {
      title: '3',
      translate: 'MENU.REPORTS',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'order_reports',
        title: 'Order Reports',
        translate: 'MENU.ORDER_REPORTS',
        type: 'item',
        icon: 'file',
        url: 'reports'
      },
      {
        id: 'challan_reports',
        title: 'Challan Reports',
        translate: 'MENU.CHALLAN_REPORTS',
        type: 'item',
        icon: 'file',
        url: 'challan-reports'
      },
      {
        id: 'outstanding_report',
        title: 'Outstanding Report',
        translate: 'MENU.OUTSTANDING_REPORT',
        type: 'item',
        icon: 'file',
        url: 'outstanding-report'
      },
      {
        id: 'product_combo_report',
        title: 'Product Combo Report',
        translate: 'MENU.PRODUCT_COMBO_REPORT',
        type: 'item',
        icon: 'file',
        url: 'product-combo-report'
      },
      {
        id: 'top_customer_list',
        title: 'Top Customers Report',
        translate: 'MENU.TOP_CUSTOMERS_REPORT',
        type: 'item',
        icon: 'file',
        url: 'top-customer-list'
      }
    ]
  },
  {
    id: 'Supplier',
    title: 'Supplier',
    translate: 'MENU.MANUFACTURERS',
    type: 'item',
    role: ['Admin'],
    icon: 'users',
    url: 'manufacturers'
  },
  {
    id: 'units',
    title: 'Units',
    translate: 'MENU.UNIT',
    type: 'item',
    role: ['Admin'],
    icon: 'move',
    url: 'units'
  },
  {
    id: 'purchaseorder',
    title: 'Purchase Order',
    translate: 'MENU.PURCHASE_ORDER',
    type: 'item',
    role: ['Purchase'],
    icon: 'shopping-cart',
    url: 'purchase-order'
  },
  {
    id: 'Vendors',
    title: 'Vendors',
    translate: 'MENU.VENDOR',
    type: 'item',
    role: ['Purchase'],
    icon: 'users',
    url: 'vendors'
  },

  

  {
    id: 'rowmaterialproduct',
    title: 'Raw Material Product',
    translate: 'MENU.ROWMATERIALPRODUCT',
    type: 'item',
    role: ['Purchase'],
    icon: 'box',
    url: 'row-material-product'
  },

  

  {
    id: 'production-submit',
    title: 'Planning Submit',
    translate: 'MENU.PRODUCTIONSUBMIT',
    type: 'item',
    role: ['Production', 'Molding', 'Tufting', 'PackagingAssembly'],
    icon: 'layers',
    url: 'production-submit'
  },

  
  {
    id: 'masterstore',
    title: 'Master',
    translate: 'MENU.MASTER',
    type: 'collapsible',
    role: ['Store'],
    icon: 'box',
    badge: {
      title: '3',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'products',
        title: 'Products',
        translate: 'MENU.PRODUCTS',
        type: 'item',
        role: ['Store'],
        icon: 'box',
        url: 'products'
      },
      {
        id: 'warehouse',
        title: 'Warehouse',
        translate: 'MENU.WAREHOUSE',
        type: 'item',
        role: ['Store'],
        icon: 'database',
        url: 'warehouse'
      },
      {
        id: 'rowmaterialproduct',
        title: 'Raw Material',
        translate: 'MENU.ROWMATERIALPRODUCT',
        type: 'item',
        role: ['Store'],
        icon: 'box',
        url: 'row-material-product'
      },
      {
        id: 'product_combo',
        title: 'Product Combo',
        translate: 'MENU.PRODUCTCOMBO',
        type: 'item',
        role: ['Store'],
        icon: 'box',
        url: 'product-combo'
      },
      {
        id: 'stock_transfer',
        title: 'Stock Transfer',
        translate: 'MENU.STOCKTRANSFER',
        type: 'item',
        role: ['Store'],
        icon: 'code',
        url: 'stock-transfer-finished-product'
      },
    ]
  },

  // {
  //   id: 'productionstore',
  //   title: 'Planning',
  //   translate: 'MENU.PRODUCTIONGIVEN',
  //   type: 'collapsible',
  //   role: ['Store'],
  //   icon: 'box',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.PRODUCTIONGIVEN',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'production_given',
  //       title: 'Planning Given',
  //       translate: 'MENU.PRODUCTIONGIVEN',
  //       type: 'item',
  //       role: ['Store'],
  //       icon: 'upload',
  //       url: 'production-given'
  //     },

  //     {
  //       id: 'production-submit',
  //       title: 'Planning Submit',
  //       translate: 'MENU.PRODUCTIONSUBMIT',
  //       type: 'item',
  //       role: ['Store'],
  //       icon: 'download',
  //       url: 'production-submit'
  //     },
  //   ]
  // },


  {
    id: 'product_wise_rawmaterial_stock',
    title: 'Raw Material For Order',
    translate: 'MENU.PRODUCTWISERAWMATERIALSTOCK',
    type: 'item',
    role: ['Store'],
    icon: 'bar-chart-2',
    url: 'product-wise-rawmaterial-stock'
  },

  {
    id: 'stock',
    title: 'Department Wise Stock',
    translate: 'MENU.STOCK',
    type: 'item',
    role: ['Production', 'SalesMarketing', 'Molding', 'Tufting', 'PackagingAssembly', 'Dispatch','Store'],
    icon: 'grid',
    url: 'to-do'
  },
  
  


  // {
  //   id: 'rawmaterialsharing',
  //   title: 'Raw Material Sharing',
  //   translate: 'MENU.RAWMATERIALSHARING',
  //   type: 'collapsible',
  //   role: ['Store'],
  //   icon: 'share-2',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.RAWMATERIALSHARING',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'stock-given',
  //       title: 'Stock Given',
  //       translate: 'MENU.STOCKGIVEN',
  //       type: 'item',
  //       role: ['Store'],
  //       icon: 'sunrise',
  //       url: 'store-given-raw-material'
  //     },

  //     {
  //       id: 'stock-receive',
  //       title: 'Stock Receive',
  //       translate: 'MENU.STOCKRECEIVE',
  //       type: 'item',
  //       role: ['Store'],
  //       icon: 'sunset',
  //       url: 'store-return-raw-material'
  //     },
  //   ]
  // },

  {
    id: 'purchase_requisition',
    title: 'Purchase Requisition',
    translate: 'MENU.PURCHASEREQUISITION',
    type: 'item',
    role: ['Client', 'Admin', 'GeneralManager', 'Purchase', 'Store', 'Production', 'SalesMarketing', 'Molding', 'Tufting', 'PackagingAssembly', 'Dispatch', 'HR', 'Account', 'MoldingPlanning', 'TuftingPlanning', 'PackagingPlanning', 'Planning'],
    icon: 'shopping-cart',
    url: 'purchase-requisition'
  },


]
