import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TransportsService } from 'app/_services/transports.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transport-master',
  templateUrl: './transport-master.component.html',
  styleUrls: ['./transport-master.component.scss']
})
export class TransportMasterComponent implements OnInit {

  public loadingFrm: boolean = false;
  public error = '';
  public newFormAdd: FormGroup;
  public progressMainTable: boolean = false;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading: boolean = false;
  public submitted: boolean = false;
  public loadingForm: boolean = false;
  public addMode: boolean = false;
  public transportsList: any = [];
  public start: any = 0;
  public page: any = 0;
  public transportId: any = 0;

  constructor(private _router: Router, private transportsService: TransportsService, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.getTransportLists(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      transportName: ['', Validators.required],
      transportAddress: ['', Validators.required],
      contactPerson: ['', Validators.required],
      transportPhone: ['', Validators.required],
      transportGst: [''],
      transportRateFormat: ['']
    });
  }
  get f() {
    return this.newFormAdd.controls;
  }
  toggleSidebar(name, id: number = 0, index: any = null): void {

    this.submitted = false;
    this.error = '';
    this.loadingFrm = false;
    this.loadingForm = false;
    this.transportId = 0;

    if (id != 0 && index != null) {
      this.transportId = id;

      this.f.transportName.setValue(this.transportsList[index].name);
      this.f.transportAddress.setValue(this.transportsList[index].address);
      this.f.contactPerson.setValue(this.transportsList[index].contact_person);
      this.f.transportPhone.setValue(this.transportsList[index].mobile);
      this.f.transportGst.setValue(this.transportsList[index].gst_no);
      this.f.transportRateFormat.setValue(this.transportsList[index].rate_format);

    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();

  }
  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      console.log('error');
    }
    else {
      this.loadingFrm = true;

      const formdata = new FormData();
      formdata.append('name', this.f.transportName.value);
      formdata.append('address', this.f.transportAddress.value);
      formdata.append('contact_person', this.f.contactPerson.value);
      formdata.append('mobile', this.f.transportPhone.value);
      formdata.append('gst_no', this.f.transportGst.value);
      formdata.append('rate_format', this.f.transportRateFormat.value);
      formdata.append('serving_cities', '');
      formdata.append('id', this.transportId);

      this.transportsService.add(formdata, this.transportId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.newFormAdd.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.getTransportLists(this.page, this.lengths);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  getTransportLists(my_start: any, my_lengths: any) {
    this.transportsService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.transportsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  reloadList() {
    this.progressMainTable = true;
    this.getTransportLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getTransportLists(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getTransportLists(this.start, this.lengths);
  }
  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.transportsService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getTransportLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Transport Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

}
