import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';
import { ProductsService } from 'app/_services/products.service';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-combo-report',
  templateUrl: './product-combo-report.component.html',
  styleUrls: ['./product-combo-report.component.scss']
})
export class ProductComboReportComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public reportData;
  public error = '';
  public newForm: FormGroup;
  public categoryDropDown: any = [];
  public allProduct: any;
  public isLoadingProduct: boolean = false;
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  @ViewChild('auto') auto;

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder,
    private _productMasterComboService: ProductMasterComboService,
    private _productsService: ProductsService,
  ) { }

  ngOnInit(): void {
    this.getCategoryDropDown();
    this.newForm = this._formBuilder.group({
      is_moq: ['1', []],
      category_id: [null, []],
      product_id: [null, []],
    });
  }

  getCategoryDropDown() {
    this._productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getCategoryFilter(categories) {
    this.allProduct = [];
    this.newForm.get('product_id').setValue(null); // Clear the input
  }


  onChangeSearchProduct(value: string) {
    if (value.length >= 3) {
      this.isLoadingProduct = true;
      this._productsService.getProductsList({ searchByName: value, category: this.newForm.value.category_id }).subscribe(
        response => {

          this.isLoadingProduct = false
          if (response.status) {
            this.allProduct = response.data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          this.loading = false;
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }
  selectEventProduct(item: any) {
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  get f() {
    return this.newForm.controls;
  }


  /**
  * On Submit
  */
  onSubmit() {
    if (this.newForm.get('product_id').value?.id) {
      this.newForm.get('product_id').setValue([this.newForm.value.product_id.id]);
    }
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      console.log('Form is invalid');
      return;
    }
    this.loading = true;
    this._productMasterComboService.downloadReport(this.newForm.value).subscribe(
      data => {
        this.newForm.reset();
        this.submitted = false;
        let url = window.URL.createObjectURL(data);
        window.open(url, '_blank');
        this.loading = false;
        window.URL.revokeObjectURL(url);
        /* let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'product_reports.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); */
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
         this.loading = false;
        this._toastrService.error('Something went wrong! Please Try Again...', 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      });
  }

}
