<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Product Stock Maintain</h4>
                        </div>
                        <div class="card-body p-0 pb-1" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        
                        <div class="card-body p-0" *ngIf="!loading" >
                            <div class="row m-0 justify-content-between">
                                <div class="col-3 col-md-3">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLengths($event)">
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-none d-sm-inline-block pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by product name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-12 d-sm-none d-inline-block pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control" style="border-radius: 10px 0px 0px 10px;" placeholder="Search by product name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;" (click)="getFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 d-none d-sm-inline-block pb-1" *ngIf="currentUserRole == 'Client' || currentUserRole == 'Store'">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <a class="btn btn-primary ml-1" routerLink="/product-stock-maintain-add" rippleEffect  data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add Maintain Stock">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add Maintain Stock</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <table class="table table-striped table-hover valign-middle text-dark mb-1 pr-1">
                                    <thead>
                                        <tr>
                                            <th class="action-column">#</th>
                                            <th class="text-nowrap">Date</th>
                                            <th class="text-nowrap">User</th>
                                            <th class="text-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productList?.length != 0">
                                        <tr *ngFor="let item of productList; let i = index">
                                            <td>{{ i+page+1 }}</td>
                                            <td>{{ item.created }}</td>
                                            <td>{{ item.user.name }}</td>
                                            <td>
                                                <button (click)="modalOpen(modalBasic,item.created, item.user_id,item.user.name)" class="btn btn-icon btn-sm btn-info mr-1 mb-1" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View">
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="4" class="no-data-available">No Data!</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 30" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-template #modalBasic let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">
        <span> View History - </span> 
        <span *ngIf="!modalLoading">{{username}} <small class="badge badge-light-primary">{{userDate}}</small></span>
      </h4>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="col" *ngIf="modalLoading">
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div>
                Please Wait...
              </div>
            </div>
        </div>
        
        <table class="table table-hover table-striped w-100" *ngIf="!modalLoading">
            <tbody>
                <tr>
                    <th>#</th>
                    <th class="text-nowrap">Product</th>
                    <th class="text-nowrap"> Sale / Purchase Price</th>
                    <th class="text-nowrap"> Sizes</th>
                    <th class="text-nowrap"> Colors</th>
                    <th class="text-nowrap"> Units</th>
                    <th class="text-nowrap">Current Qty</th>
                    <th class="text-nowrap">Real Qty</th>
                    <th class="text-nowrap">Difference</th>
                </tr>
            </tbody>
            <tbody *ngIf="productHistory?.length != 0">
                <tr *ngFor="let ord of productHistory; let sr = index">
                    <td>{{sr+1}}</td>
                    <td>{{ ord.raw_product.product_code }} {{ ord.raw_product.product_name }}</td>
                    <td>₹ {{ ord.raw_product.sales_price | number:'1.3-3' }} / {{ ord.raw_product.purchase_price | number:'1.3-3' }}</td>
                    <td>{{ ord.raw_product.size_title }}</td>
                    <td>{{ ord.raw_product.color_title }}</td>
                    <td><span *ngIf="ord.raw_product.unit != 'null'">{{ ord.raw_product.unit }}</span></td>
                    <td>{{ord.current_qty}}</td>
                    <td>{{ord.real_qty}}</td>
                    <td>{{ord.difference_qty}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="productHistory?.length == 0">
                <tr>
                    <td colspan="6">No Data Found!</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
        Close
      </button>
    </div>
</ng-template>
  <!-- / Modal -->