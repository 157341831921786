import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { PricingService } from 'app/_services/pricing.service';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  
  public currentUserRole = '';
  public currentUser: any;
  public planList: any;

  constructor(private _coreConfigService: CoreConfigService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private pricingService:PricingService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;

    if (this.currentUserRole == 'User') {
      this._coreConfigService.config = {
        layout: {
          menu: {
            hidden: true
          }
        }
      };
    }

  }


  ngOnInit(): void {
    this.getPlan();
  }

  getPlan() {
    this.pricingService.getData().subscribe(
      response => {
        if (response.status) {
          this.planList = response.planList;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

}
