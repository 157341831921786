import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PricePerCustomerService } from 'app/_services/price-per-customer.service';
import { DealersService } from 'app/_services/dealers.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-price-per-customer-product',
  templateUrl: './price-per-customer-product.component.html',
  styleUrls: ['./price-per-customer-product.component.scss']
})
export class PricePerCustomerProductComponent implements OnInit {


  public productsList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public dealerId = 0;
  isAddMode: boolean = true;
  public dealerDetails: any = [];
  public editProduct: any = [];

  public newForm: FormGroup;
  public submitted = false;
  public priceId = 0;
  public progressMainTable: boolean = false;

  constructor(private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private dealersService: DealersService, private pricePerCustomerService: PricePerCustomerService, private _router: Router, private route: ActivatedRoute, private _coreSidebarService: CoreSidebarService, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    if (this.route.snapshot.params['id'] !== undefined) {
      this.dealerId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.dealerId;
    if (!this.isAddMode) {
      this.productList(this.start, this.lengths);
      this.dealerViewDetail(this.dealerId);
    }

    this.newForm = this._formBuilder.group({
      sales_price: ['', [Validators.required]]
    });

  }

  productList(my_start: any, my_lengths: any) {
    this.loading = true;
    this.pricePerCustomerService.getProductsById(my_start, my_lengths, this.dealerId).subscribe(
      response => {
        if (response.status) {
          this.productsList = response.data;
          this.collectionSize = response.recordsTotal;
        }
        this.loading = false;
        this.progressMainTable = false;

      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  dealerViewDetail(dealerId: any) {
    this.dealerDetails = [];

    this.dealersService.dealerViewDetails(dealerId).subscribe(
      response => {
        if (response.status) {
          this.dealerDetails = response.dealer;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }

  reloadList() {
    this.progressMainTable = true;
    this.productList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productList(this.start, this.lengths);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.pricePerCustomerService.delete(id).subscribe(
          response => {
            if (response.status) {
              this.progressMainTable = true;
              me.productList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Price Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.error,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }


  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  editPrice(index: any) {
    this.editProduct = this.productsList[index];
    this.priceId = this.editProduct.id;
    this.f.sales_price.setValue(this.editProduct.sales_price);
    this.toggleSidebar('new-sidebar');
  }

  /**
   * Submit
   *
   * @param form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const { sales_price } = this.newForm.controls;

      this.pricePerCustomerService.edit(this.priceId, sales_price.value).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.priceId = 0
            this.submitted = false;
            this.newForm.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.error.sales_price, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  get f() {
    return this.newForm.controls;
  }

  checkNumber(event: any) {
    if (event.keyCode != 16 && event.keyCode != 107 && event.keyCode != 189 && event.keyCode != 109 && event.keyCode != 69) {
      //Do nothing 
    }
    else {
      event.target.value = 0;
    }
  }

}
