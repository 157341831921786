import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';

@Component({
  selector: 'app-product-master-combo-add',
  templateUrl: './product-master-combo-add.component.html',
  styleUrls: ['./product-master-combo-add.component.scss']
})
export class ProductMasterComboAddComponent implements OnInit {


  public purchaseProducts: any[] = [{
    id: 1,
    row_material_category: '0',
    raw_material_product: '0',
    quantity: '1',
    image: '',
    image_src: 'assets/images/upload_placeholder.png',
  }];

  public loading: boolean = false;
  public progressMainTable: boolean = true;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public productsDropDown: any = [];
  public rowMaterialCategoryDropDown: any = [];
  public rowMaterialProductsDropDown: any = [];

  constructor(private _authenticationService: AuthenticationService, private productMasterComboService: ProductMasterComboService, private purchaseOrderService: PurchaseOrderService, private rowMaterialProductService: RowMaterialProductService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { }

  addMore() {
    this.purchaseProducts.push({
      id: this.purchaseProducts.length + 1,
      row_material_category: '0',
      raw_material_product: '0',
      quantity: '1',
      image: '',
      image_src: 'assets/images/upload_placeholder.png',
    });
  }

  remove(i: number) {
    this.purchaseProducts.splice(i, 1);
  }


  ngOnInit(): void {
    this.getRowMaterialProductCategoriesDropDown();
    this.getProductsDropDown();

    this.newForm = this._formBuilder.group({
      products_id: ["0", [Validators.required]],
      // combo_name: [null, [Validators.required]],
    });
  }

  get f() {
    return this.newForm.controls;
  }

  getRowMaterialProductCategoriesDropDown() {
    this.rowMaterialProductService.getRowMaterialProductCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.rowMaterialCategoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getProductsDropDown() {
    this.rowMaterialProductService.getProducts().subscribe(
      data => {
        if (data.status) {
          this.progressMainTable = false;
          this.productsDropDown = data.products;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getRowMaterialProductsDropDowns(categoryId: any, i: any) {
    this.purchaseOrderService.getRowMaterialProductsDropDown(categoryId).subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.products;

          this.purchaseProducts[i].raw_material_product = '0';
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }


  getProducts(product) {
    console.log(product);

    this.purchaseProducts = [];

    this.productMasterComboService.viewDetails(product, 1).subscribe(
      response => {
        if (response.status) {

          response.details.comboMetaRawMaterialProducts.forEach((value: any, index: any) => {
            this.purchaseProducts.push({
              id: this.purchaseProducts.length + 1,
              row_material_category: value.row_material_products.row_material_category_id,
              raw_material_product: value.row_material_product_id,
              quantity: value.required_qty,
              image: '',
              image_src: 'assets/images/upload_placeholder.png',
            });

          });

          this.purchaseOrderService.getRowMaterialProductsDropDown(1).subscribe(
            data => {
              if (data.status) {
                this.rowMaterialProductsDropDown = data.products;
              }
            },
            err => {
              if (err.error.error == 'Unauthenticated.') {
                this._authenticationService.logout();
                this._router.navigate(['/login']);
              }
            });

          this.purchaseOrderService.getRowMaterialProductsDropDown(2).subscribe(
            data => {
              if (data.status) {
                this.rowMaterialProductsDropDown = data.products;
              }
            },
            err => {
              if (err.error.error == 'Unauthenticated.') {
                this._authenticationService.logout();
                this._router.navigate(['/login']);
              }
            });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );



    // this.purchaseProducts.push({
    //   id: this.purchaseProducts.length + 1,
    //   row_material_category: '0',
    //   raw_material_product: '0',
    //   quantity: '1',
    //   image: '',
    //   image_src: 'assets/images/upload_placeholder.png',
    // });

  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.loading = false;
    }
    else {
      //this.f.combo_name.value
      this.productMasterComboService.add(this.f.products_id.value, '', this.purchaseProducts).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            console.log(data);
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/product-combo']);
          }
          else {
            this._toastrService.error(data.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });


      console.log(this.purchaseProducts);
      this.loading = false;
    }
  }
  uploadFile(event: any, index: any) {
    this.purchaseProducts[index].image = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.purchaseProducts[index].image_src = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
