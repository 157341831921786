import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class RowMaterialCategoryService {

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-row-material-categories`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-row-material-categories`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(category_name: any, description: any, categoryId: any): Observable<any> {
    if (categoryId == 0) {
      return this._http
        .post(`${environment.apiUrl}save-row-material-categories`, { category_name, description })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-row-material-categories`, { category_name, description, categoryId })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }
}
