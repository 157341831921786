import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { JwtInterceptorProviders } from './auth/helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AppRoutingModule } from './app-routing.module';
import { CategoryComponent } from './component/category/category.component';
import { NewSidebarComponent } from './component/category/new-sidebar/new-sidebar.component';
import { DealersComponent } from './component/dealers/dealers.component';
import { DealersAddComponent } from './component/dealers-add/dealers-add.component';
import { ProductsComponent } from './component/products/products.component';
import { ProductsAddComponent } from './component/products-add/products-add.component';
import { OrdersComponent } from './component/orders/orders.component';
import { OrdersAddComponent } from './component/orders-add/orders-add.component';
import { DeliveryChallanComponent } from './component/delivery-challan/delivery-challan.component';
import { DeliveryChallanAddComponent } from './component/delivery-challan-add/delivery-challan-add.component';
import { ClipboardModule } from 'ngx-clipboard';
import { PricePerCustomerAddComponent } from './component/price-per-customer-add/price-per-customer-add.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPrintModule } from 'ngx-print';
import { PricePerCustomerComponent } from './component/price-per-customer/price-per-customer.component';
import { PricePerCustomerProductComponent } from './component/price-per-customer-product/price-per-customer-product.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ReportsComponent } from './component/reports/reports.component';
import { ManufacturersComponent } from './component/manufacturers/manufacturers.component';
import { EmployeeComponent } from './component/employee/employee.component';
import { EmployeeAddComponent } from './component/employee-add/employee-add.component';
import { ChallanReportComponent } from './component/challan-report/challan-report.component';
import { HelpComponent } from './component/help/help.component';
import { CustomerGuideComponent } from './component/customer-guide/customer-guide.component';
import { UnitsComponent } from './component/units/units.component';
import { NewUnitComponent } from './component/units/new-unit/new-unit.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { UserIdleModule } from 'angular-user-idle';
import { PricingComponent } from './component/pricing/pricing.component';
import { SettingsComponent } from './component/settings/settings.component';
import { DepartmentsComponent } from './component/departments/departments.component';
import { VendorsComponent } from './component/vendors/vendors.component';
import { PurchaseOrderComponent } from './component/purchase-order/purchase-order.component';
import { PurchaseOrderAddComponent } from './component/purchase-order-add/purchase-order-add.component';
import { RowMaterialCategoryComponent } from './component/row-material-category/row-material-category.component';
import { RowMaterialProductComponent } from './component/row-material-product/row-material-product.component';
import { VendorCategoriesComponent } from './component/vendor-categories/vendor-categories.component';
import { VendorSubCategoriesComponent } from './component/vendor-sub-categories/vendor-sub-categories.component';
import { RowMaterialSubCategoriesComponent } from './component/row-material-sub-categories/row-material-sub-categories.component';
import { PurchaseOrderReceiveComponent } from './component/purchase-order-receive/purchase-order-receive.component';
import { ProductMasterComboComponent } from './component/product-master-combo/product-master-combo.component';
import { ProductMasterComboAddComponent } from './component/product-master-combo-add/product-master-combo-add.component';
import { VendorAddComponent } from './component/vendor-add/vendor-add.component';
import { TransportMasterComponent } from './component/transport-master/transport-master.component';
import { OperatorMasterComponent } from './component/operator-master/operator-master.component';
import { MachineMasterComponent } from './component/machine-master/machine-master.component';
import { ProductionSubmitComponent } from './component/production-submit/production-submit.component';
import { WarehouseMasterComponent } from './component/warehouse-master/warehouse-master.component';
import { ProductionGivenComponent } from './component/production-given/production-given.component';
import { ProductWiseRawMaterialStockComponent } from './component/product-wise-raw-material-stock/product-wise-raw-material-stock.component';
import { StockTransferFinishedProductComponent } from './component/stock-transfer-finished-product/stock-transfer-finished-product.component';
import { StoreGivenComponent } from './component/store-given/store-given.component';
import { StoreReturnComponent } from './component/store-return/store-return.component';
import { PurchaseRequisitionComponent } from './component/purchase-requisition/purchase-requisition.component';
import { ManageComponent } from './component/manage/manage.component';
import { ToDoComponent } from './component/to-do/to-do.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { PurchaseNonOrderAddComponent } from './component/purchase-non-order-add/purchase-non-order-add.component';
import { ProductComboReportComponent } from './component/product-combo-report/product-combo-report.component';
import { RowMaterialStockMaintainComponent } from './component/row-material-stock-maintain/row-material-stock-maintain.component';
import { RowMaterialStockMaintainAddComponent } from './component/row-material-stock-maintain-add/row-material-stock-maintain-add.component';
import { ProductStockMaintainComponent } from './component/product-stock-maintain/product-stock-maintain.component';
import { ProductStockMaintainAddComponent } from './component/product-stock-maintain-add/product-stock-maintain-add.component';
import { TopCustomerComponent } from './component/top-customer/top-customer.component';
import { GatePassComponent } from './component/gate-pass/gate-pass.component';
import { GatePassAddComponent } from './component/gate-pass-add/gate-pass-add.component';
import { FactoryEmployeeComponent } from './component/factory-employee/factory-employee.component';
import { FactoryEmployeeAddComponent } from './component/factory-employee-add/factory-employee-add.component';

@NgModule({
  declarations: [AppComponent, CategoryComponent, NewSidebarComponent, DealersComponent, DealersAddComponent, ProductsComponent, ProductsAddComponent, OrdersComponent, OrdersAddComponent, DeliveryChallanComponent, DeliveryChallanAddComponent, PricePerCustomerAddComponent, PricePerCustomerComponent, PricePerCustomerProductComponent, ProfileComponent, ReportsComponent, ManufacturersComponent, EmployeeComponent, EmployeeAddComponent, ChallanReportComponent, HelpComponent, CustomerGuideComponent, UnitsComponent, NewUnitComponent, OutstandingReportComponent, PricingComponent, SettingsComponent, DepartmentsComponent, VendorsComponent, PurchaseOrderComponent, PurchaseOrderAddComponent, RowMaterialCategoryComponent, RowMaterialProductComponent, VendorCategoriesComponent, VendorSubCategoriesComponent, RowMaterialSubCategoriesComponent, PurchaseOrderReceiveComponent, ProductMasterComboComponent, ProductMasterComboAddComponent, VendorAddComponent, TransportMasterComponent, OperatorMasterComponent, MachineMasterComponent, ProductionSubmitComponent, WarehouseMasterComponent, ProductionGivenComponent, ProductWiseRawMaterialStockComponent, StockTransferFinishedProductComponent, StoreGivenComponent, StoreReturnComponent, PurchaseRequisitionComponent, ManageComponent, ToDoComponent, PurchaseNonOrderAddComponent, ProductComboReportComponent, RowMaterialStockMaintainComponent, RowMaterialStockMaintainAddComponent, ProductStockMaintainComponent, ProductStockMaintainAddComponent, TopCustomerComponent, GatePassComponent, GatePassAddComponent, FactoryEmployeeComponent, FactoryEmployeeAddComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot(),


    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,
    Ng2FlatpickrModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,


    // App modules
    LayoutModule,
    SampleModule,

    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    CoreTouchspinModule,
    ClipboardModule,
    NgxPrintModule,
    PdfViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    UserIdleModule.forRoot({ idle: 1200, timeout: 1200, ping: 120 })

  ],
  providers: [JwtInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
