import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';

@Component({
  selector: 'app-product-stock-submit',
  templateUrl: './product-stock-submit.component.html',
  styleUrls: ['./product-stock-submit.component.scss']
})
export class ProductStockSubmitComponent implements OnInit {

  public purchaseProducts: any[] = [{
    id: 1,
    row_material_category: '0',
    raw_material_product: '0',
    quantity: '1'
  }];

  public loading: boolean = false;
  public progressMainTable: boolean = true;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public productsDropDown: any = [];
  public rowMaterialCategoryDropDown: any = [];
  public rowMaterialProductsDropDown: any = [];

  constructor(private _authenticationService: AuthenticationService, private productMasterComboService: ProductMasterComboService, private purchaseOrderService: PurchaseOrderService, private rowMaterialProductService: RowMaterialProductService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { }

  addMore() {
    this.purchaseProducts.push({
      id: this.purchaseProducts.length + 1,
      row_material_category: '0',
      raw_material_product: '0',
      quantity: '1'
    });
  }

  remove(i: number) {
    this.purchaseProducts.splice(i, 1);
  }

  ngOnInit(): void {
    this.getRowMaterialProductCategoriesDropDown();
  }

  getRowMaterialProductCategoriesDropDown() {
    this.rowMaterialProductService.getRowMaterialProductCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.rowMaterialCategoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getRowMaterialProductsDropDowns(categoryId: any, i: any) {
    this.purchaseOrderService.getRowMaterialProductsDropDown(categoryId).subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.products;
          this.purchaseProducts[i].raw_material_product = '0';
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  onSubmit() {

    this.loading = true;

    this.productMasterComboService.addStock(this.purchaseProducts).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.submitted = false;
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this._router.navigate(['/stock-list']);
        }
        else {
          this._toastrService.error(data.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });

    this.loading = false;

  }
}
