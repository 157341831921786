<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row match-height">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="card card-developer-meetup" *ngIf="!idEditMode && !isPasswordMode && !idBankDetails">
                        <div class="card-body profile-bg">
                            <div class="meetup-header d-flex align-items-center">
                                <div class="meetup-day">
                                    <div class="avatar avatar-new bg-primary avatar-lg" *ngIf="profile.profile_image == null">
                                        <div class="avatar-content">{{ profile.name | initials }}</div>
                                    </div>
                                    <div class="avatar avatar-lg" *ngIf="profile.profile_image != null">
                                        <span class="avatar-content">
                                            <img [src]="avatarImage" alt="{{ profile.name | initials }}" />
                                        </span>
                                    </div>
                                </div>
                                <div class="my-auto">
                                    <h4 class="card-title mb-25 headH1">{{ profile.name }}</h4>
                                    <p class="card-text mb-0">{{ profile.owner_name }}</p>
                                </div>
                            </div>
                            <div class="media">
                                <div class="avatar bg-light-primary rounded mr-1">
                                    <div class="avatar-content">
                                        <i data-feather="phone" class="avatar-icon font-medium-3"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="mb-0 card-text-other">{{ profile.phone }}</h6>
                                    <small *ngIf="profile.alternate_phone != null">{{ profile.alternate_phone }}</small>
                                    <small *ngIf="profile.alternate_phone == null"></small>
                                </div>
                            </div>
                            <div class="media">
                                <div class="avatar bg-light-primary rounded mr-1">
                                    <div class="avatar-content">
                                        <i data-feather="mail" class="avatar-icon font-medium-3"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="mb-0 card-text-other" style="padding-top:7px;">{{ profile.email }}</h6>
                                </div>
                            </div>
                            <div class="media" *ngIf="profile.gst_no != null">
                                <div class="avatar bg-light-primary rounded mr-1">
                                    <div class="avatar-content">
                                        <i data-feather="percent" class="avatar-icon font-medium-3"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="mb-0 card-text-other" style="padding-top:7px;">GST : {{ profile.gst_no | uppercase }}</h6>
                                </div>
                            </div>
                            <div class="media" *ngIf="profile.address_one != null || profile.address_two != null || profile.address_three != null">
                                <div class="avatar bg-light-primary rounded mr-1">
                                    <div class="avatar-content">
                                        <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="mb-0 card-text-other">
                                        <span *ngIf="profile.address_one != null">{{ profile.address_one }}</span>
                                        <span *ngIf="profile.address_two != null">, {{ profile.address_two }}</span>
                                        <span *ngIf="profile.address_three != null"><br/> {{ profile.address_three }}</span>
                                    </h6>
                                </div>
                            </div>
                            <div class="media">
                                <div class="avatar bg-light-primary rounded mr-1">
                                    <div class="avatar-content">
                                        <i class="avatar-icon font-medium-3 fa fa-building-o"></i>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="mb-0 card-text-other" style="padding-top:7px;">{{ profile.city_name }}<span *ngIf="profile.state_name != null">, </span>{{ profile.state_name }}<span *ngIf="profile.pincode != null"> - {{ profile.pincode }}</span></h6>
                                </div>
                            </div>
                            <div class="media">
                                <div class="row w-100">
                                    <div class="col-12 col-md-6 col-lg-3 pb-1">
                                        <button class="btn btn-primary btn-block text-center" type="button" (click)="editProfile()" rippleEffect data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="Edit your profile."><i class="fa fa-id-card"></i> Edit Profile</button>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3 pb-1">
                                        <button class="btn btn-primary btn-block text-center" (click)="changePassword()" rippleEffect data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="Change your password."><i class="fa fa-key"></i> Change Password</button>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-3 pb-1 d-none">
                                        <button type="button" (click)="copyText(profile.store_alias)" class="btn btn-success btn-block text-center" [container]="body" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="You can share this link to take orders from unknown customer."><i class="fa fa-copy"></i> Order Master Link</button>
                                    </div>
                                    <!-- <div class="col-12 col-md-12 col-lg-3 pb-1">
                                        <button type="button" (click)="bankDetailsFormMode()" class="btn btn-success btn-block text-center" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" ngbTooltip="You can add your bank details to get payment easily from your customer."><i class="fa fa-bank"></i> Bank Details</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- START :: Edit Profile -->
                    <div class="card" *ngIf="idEditMode">
                        <div class="card-header">
                            <h4 class="card-title">Edit Profile</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label for="register-enterprise_name" class="form-label">Enterprise Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="enterprise_name" class="form-control" placeholder="Enterprise Name" aria-describedby="register-enterprise_name" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.enterprise_name.errors
                                    }" />
                                        <div *ngIf="submitted && f.enterprise_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.enterprise_name.errors.required">Enterprise Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-owner_name" class="form-label">Owner Name</label>
                                        <input type="text" formControlName="owner_name" class="form-control" placeholder="Owner Name" aria-describedby="register-owner_name" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.owner_name.errors
                                    }" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-phone" class="form-label">Phone Number<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number" aria-describedby="register-phone" tabindex="3" readonly="readonly" />
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-alternate_phone" class="form-label">Alternate Phone Number 1</label>
                                        <input type="text" formControlName="alternate_phone" class="form-control" placeholder="Alternate Phone Number" aria-describedby="register-alternate_phone" tabindex="4" [ngClass]="{ 'is-invalid': submitted
                                    && f.alternate_phone.errors }" />
                                        <div *ngIf="submitted && f.alternate_phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.alternate_phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-alternate_phone_2" class="form-label">Alternate Phone Number 2</label>
                                        <input type="text" formControlName="alternate_phone_2" class="form-control" placeholder="Alternate Phone Number 2" aria-describedby="register-alternate_phone_2" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.alternate_phone_2.errors }" />
                                        <div *ngIf="submitted && f.alternate_phone_2.errors" class="invalid-feedback">
                                            <div *ngIf="f.alternate_phone_2.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-alternate_phone_3" class="form-label">Alternate Phone Number 3</label>
                                        <input type="text" formControlName="alternate_phone_3" class="form-control" placeholder="Alternate Phone Number 3" aria-describedby="register-alternate_phone_3" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.alternate_phone_3.errors }" />
                                        <div *ngIf="submitted && f.alternate_phone_3.errors" class="invalid-feedback">
                                            <div *ngIf="f.alternate_phone_3.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-alternate_phone_4" class="form-label">Alternate Phone Number 4</label>
                                        <input type="text" formControlName="alternate_phone_4" class="form-control" placeholder="Alternate Phone Number 4" aria-describedby="register-alternate_phone_4" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.alternate_phone_4.errors }" />
                                        <div *ngIf="submitted && f.alternate_phone_4.errors" class="invalid-feedback">
                                            <div *ngIf="f.alternate_phone_4.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-email" class="form-label">Email 1<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="email" class="form-control" placeholder="Email" aria-describedby="register-email" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.email">Please Enter Valid Email</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-email_2" class="form-label">Email 2</label>
                                        <input type="text" formControlName="email_2" class="form-control" placeholder="Email 2" aria-describedby="register-email_2" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email_2.errors }" />
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-email_3" class="form-label">Email 3</label>
                                        <input type="text" formControlName="email_3" class="form-control" placeholder="Email 3" aria-describedby="register-email_3" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email_3.errors }" />
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-email_4" class="form-label">Email 4</label>
                                        <input type="text" formControlName="email_4" class="form-control" placeholder="Email 4" aria-describedby="register-email_4" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email_4.errors }" />
                                    </div>
                                    <div class="form-group col-md-5th-1 col">
                                        <label for="register-email_5" class="form-label">Email 5</label>
                                        <input type="text" formControlName="email_5" class="form-control" placeholder="Email 5" aria-describedby="register-email_5" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.email_5.errors }" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_one" class="form-label">Address Line 1</label>
                                        <input type="text" formControlName="address_one" class="form-control" placeholder="Address Line 1" aria-describedby="register-address_one" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.address_one.errors
                                    }" />
                                        <div *ngIf="submitted && f.address_one.errors" class="invalid-feedback">
                                            <div *ngIf="f.address_one.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_two" class="form-label">Address Line 2</label>
                                        <input type="text" formControlName="address_two" class="form-control" placeholder="Address Line 2" aria-describedby="register-address_two" tabindex="7" [ngClass]="{ 'is-invalid': submitted && f.address_two.errors
                                    }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-address_three" class="form-label">Address Line 3</label>
                                        <input type="text" formControlName="address_three" class="form-control" placeholder="Address Line 3" aria-describedby="register-address_three" tabindex="8" [ngClass]="{ 'is-invalid': submitted && f.address_three.errors
                                    }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-state_id" class="form-label">Select State<span class="text-danger">*</span></label>
                                        <select formControlName="state_id" class="form-control" (change)="getCity($event)" placeholder="Select State" aria-describedby="register-state_id" tabindex="9" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors
                                    }">
                                            <option value="" selected disabled>Select State</option>
                                            <option *ngFor="let state of stateDropDown" [value]="state.id">{{state.state_name}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-city_id" class="form-label">Select City<span class="text-danger">*</span></label>
                                        <select formControlName="city_id" class="form-control" placeholder="Select City" aria-describedby="register-city_id" tabindex="10" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }">
                                        <option value="" selected >Select City</option>
                                        <option  *ngFor="let city of cityDropDown" [value]="city.id">
                                            {{city.city_name}}</option>
                                    </select>
                                        <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.city_id.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-pincode" class="form-label">Pincode</label>
                                        <input type="text" formControlName="pincode" class="form-control" placeholder="Pincode" aria-describedby="register-pincode" tabindex="11" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="register-gst_no" class="form-label">GST No.</label>
                                        <input type="text" formControlName="gst_no" class="form-control text-uppercase" placeholder="GST No." aria-describedby="register-gst_no" tabindex="12" [ngClass]="{ 'is-invalid': submitted && f.gst_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <!-- header media -->
                                        <div class="media">

                                            <a href="javascript:void(0);" class="mr-25">
                                                <img [src]="avatarImage" class="rounded mr-50" alt="profile image" height="76" width="80" />
                                            </a>

                                            <!-- upload and reset button -->
                                            <div class="media-body mt-75 ml-1">
                                                <button (click)="file.click()" type="button" for="account-upload" class="btn btn-sm btn-primary mb-75 mr-75" rippleEffect>
                                                    Upload
                                                </button>
                                                <input type="file" #file id="account-upload" hidden accept="image/*" (change)="uploadImage($event)" />
                                                <p class="form-label">Allowed JPG, GIF or PNG.</p>
                                            </div>
                                            <!--/ upload and reset button -->
                                        </div>
                                        <!--/ header media -->
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loading" type="button" (click)="viewProfile()" class="btn btn-secondary btn-block" tabindex="15" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="14" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- END :: Edit Profile -->

                    <!-- START :: Change Password -->
                    <div class="card" *ngIf="isPasswordMode">
                        <div class="card-header">
                            <h4 class="card-title">Change Password</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newFormPassword" (ngSubmit)="onSubmitPassword()" autocomplete="off">
                                <div class="row">
                                    <div class=" col-lg-12">
                                        <label for="register-oldPassword" class="form-label">Old Password<span class="text-danger">*</span></label>
                                    </div>
                                    <div class="form-group col-lg-12 input-group input-group-merge form-password-toggle">
                                        <input [type]="passwordTextType1 ? 'text' : 'password'" formControlName="oldPassword" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submitted && fp.oldPassword.errors }
                                   " placeholder="············" aria-describedby="register-oldPassword" />

                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType1, 'icon-eye': !passwordTextType1 }" (click)="togglePasswordTextType(1)" ></i>
                                            </span>
                                        </div>
                                        <div *ngIf="submitted && fp.oldPassword.errors" class="invalid-feedback">
                                            <div *ngIf="fp.oldPassword.errors.required">Old Password is required</div>
                                        </div>
                                    </div>
                                    <div class=" col-lg-12">
                                        <label for="register-newPassword" class="form-label">New Password<span class="text-danger">*</span></label>
                                    </div>
                                    <div class="form-group col-lg-12 input-group input-group-merge form-password-toggle">
                                        <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="newPassword" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submitted && fp.newPassword.errors }
                                   " placeholder="············" aria-describedby="register-newPassword" />

                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(2)" ></i>
                                            </span>
                                        </div>
                                        <div *ngIf="submitted && fp.newPassword.errors" class="invalid-feedback">
                                            <div *ngIf="fp.newPassword.errors.required">New Password is required</div>
                                            <div *ngIf="fp.newPassword.touched && fp.newPassword.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>
                                        </div>
                                    </div>
                                    <div class=" col-lg-12">
                                        <label for="register-confirmPassword" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                                    </div>
                                    <div class="form-group col-lg-12 input-group input-group-merge form-password-toggle">
                                        <input [type]="passwordTextType3 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submitted && fp.confirmPassword.errors }
                                   " placeholder="············" aria-describedby="register-confirmPassword" />

                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer">
                                              <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType3, 'icon-eye': !passwordTextType3 }" (click)="togglePasswordTextType(3)" ></i>
                                            </span>
                                        </div>
                                        <div *ngIf="submitted && fp.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="fp.confirmPassword.errors.required">Confirm Password is required</div>
                                            <div *ngIf="fp.confirmPassword.touched && fp.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loading" type="button" (click)="viewProfile()" class="btn btn-secondary btn-block" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- END :: Change Password -->

                    <!-- START :: Bank Details-->
                    <div class="card" *ngIf="idBankDetails">
                        <div class="card-header">
                            <h4 class="card-title">Bank Details</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="bankDetailsForm" (ngSubmit)="onSubmitBankDetails()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label for="register-account_name" class="form-label">Account Holder Name</label>
                                        <input type="text" formControlName="account_name" class="form-control" placeholder="Account Holder Name" aria-describedby="register-account_name" />
                                    </div>
                                    <div class="form-group col-lg-6"></div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-account_number" class="form-label">Account Number</label>
                                        <input type="text" formControlName="account_number" class="form-control" placeholder="Account Account Number" aria-describedby="register-account_number" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-ifsc_code" class="form-label">IFSC Code</label>
                                        <input type="text" formControlName="ifsc_code" class="form-control" placeholder="IFSC Code" aria-describedby="register-ifsc_code" />
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label for="register-google_pay" class="form-label">Google Pay</label>
                                        <input type="text" formControlName="google_pay" class="form-control" placeholder="Google Pay" aria-describedby="register-google_pay" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-phone_pay" class="form-label">Phone Pay</label>
                                        <input type="text" formControlName="phone_pay" class="form-control" placeholder="Phone Pay" aria-describedby="register-phone_pay" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-paytm" class="form-label">Paytm</label>
                                        <input type="text" formControlName="paytm" class="form-control" placeholder="Paytm" aria-describedby="register-paytm" />
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label for="register-bheem_upi" class="form-label">Bheem UPI</label>
                                        <input type="text" formControlName="bheem_upi" class="form-control" placeholder="Bheem UPI" aria-describedby="register-bheem_upi" />
                                    </div>
                                    <div class="form-group col-lg-12">
                                        <label for="register-remarks" class="form-label">Remarks</label>
                                        <textarea formControlName="remarks" class="form-control" rows="2" placeholder="Remarks" aria-describedby="register-remarks"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <ngb-alert [type]=" 'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loadingBankDetails" type="button" (click)="viewProfile()" class="btn btn-secondary btn-block" rippleEffect>
                                            <span *ngIf="loadingBankDetails" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <button [disabled]="loadingBankDetails" class="btn btn-primary btn-block" rippleEffect>
                                            <span *ngIf="loadingBankDetails" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- END :: Bank Details-->
                </div>
                <!-- Plan Card starts-->
                <div class="col-xl-3 col-lg-4 col-md-5 d-none">
                    <div class="card plan-card border-primary">
                        <div class="card-header d-flex justify-content-between align-items-center pt-75 pb-1">
                            <h5 class="mb-0">Current Plan</h5>
                            <span class="badge badge-light-secondary" data-toggle="tooltip" data-placement="top" title="Expiry Date"> {{ profile.remaining_day }} Days
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="badge badge-light-primary">{{ profile.plan_name }}</div>
                            <ul class="list-unstyled my-1">
                                <li>
                                    <span class="align-middle"><b>Active Date :</b> <br/> &nbsp; &nbsp; {{ profile.start_date }}</span>
                                </li>
                                <li class="my-25">
                                    <span class="align-middle"><b>Expiry Date :</b>  <br/> &nbsp; &nbsp; {{ profile.expire_date }}</span>
                                </li>
                            </ul>
                            <a class="btn btn-primary text-center btn-block mt-3" rippleEffect routerLink="/pricing">Upgrade Plan</a>
                            <button class="btn btn-primary text-center btn-block" rippleEffect (click)="modalOpen(modalBasic)">Plan History</button>
                        </div>
                    </div>
                </div>
                <!-- /Plan CardEnds -->
            </div>
        </section>
    </div>
</div>

<!-- Payment History Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Payment History</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="paymentHistory?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="paymentHistory?.length != 0">
            <div class="col-12 pt-1 overflow-auto">
                <table class="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>Plan Name</th>
                            <th>Amount</th>
                            <th>discount</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                        <tr *ngFor="let payment of paymentHistory; let ndx = index">
                            <td>{{ payment.plan_name }}</td>
                            <td>{{ payment.amount }}</td>
                            <td>{{ payment.discount }}</td>
                            <td>{{ payment.start_date }}</td>
                            <td>{{ payment.end_date }}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>Close </button>
    </div>
</ng-template>
<!-- / Payment History Modal -->