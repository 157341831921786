import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-unit`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(unit_name: any, unitId: any): Observable<any> {
    if (unitId == 0) {
      return this._http
        .post(`${environment.apiUrl}save-unit`, { unit_name })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-unit/` + unitId, { unit_name })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  catDelete(id): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}delete-unit/` + id,)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  catEdit(id): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}edit-unit/` + id,)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
