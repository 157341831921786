import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'app/_services/employee.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ProductsService } from 'app/_services/products.service';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { GatePassService } from 'app/_services/gate-pass.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const now = new Date();

@Component({
  selector: 'app-gate-pass-add',
  templateUrl: './gate-pass-add.component.html',
  styleUrls: ['./gate-pass-add.component.scss']
})
export class GatePassAddComponent implements OnInit {
  public gatePassId = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  isAddMode: boolean = true;
  public stateDropDown: any;
  public cityDropDown: any;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public vendorDropDown: any = [];
  public allProduct: any;
  public isLoadingProduct: boolean = false;
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public basicDPdata: NgbDateStruct = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  public minDate: NgbDateStruct;
  public selectedProductsValue: any = [];
  public selectedDates: any = [];

  constructor(private _authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    private _toastrService: ToastrService,
    private _router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _rowMaterialProductService: RowMaterialProductService,
    private _productsService: ProductsService,
    private _gatePassService: GatePassService,
  ) {

    if (this.route.snapshot.params['id'] !== undefined) {
      this.gatePassId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.gatePassId;
    this.newForm = this._formBuilder.group({
      id: [null],
      vendor_id: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
      is_product: ['0', Validators.required],
      is_returnable: ['1', Validators.required],
      transport: [''],
      remarks: [''],
      dispatched_by: [''],
      products_data: this._formBuilder.array([], Validators.required)
    });

    if (!this.isAddMode) {
      this.edit(this.gatePassId);
    } else {
      this.addProduct(); // Initialize with one product data entry
    }
    this.minDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  }


  ngOnInit(): void {
    this.getVendorsDropDown();
  }


  get productsData(): FormArray {
    return this.newForm.get('products_data') as FormArray;
  }

  onChangeProduct(val: string) {
    setTimeout(() => {
      this.newForm.get('products_data').updateValueAndValidity();
      for (let itm of this.productsData.controls) {
        itm.get('product_details').setValue('');
        itm.get('product_id').setValue(null);

        itm.get('status').updateValueAndValidity();
        itm.get('product_details').updateValueAndValidity();
        itm.get('product_id').updateValueAndValidity();
        itm.get('expected_arrival_date').updateValueAndValidity();
      }
    }, 1000);
  }

  onChangeReturnable(val: string) {
    setTimeout(() => {
      this.newForm.get('products_data').updateValueAndValidity();
      for (let itm of this.productsData.controls) {
        itm.get('status').setValue(val)
        itm.get('expected_arrival_date').setValue('');

        itm.get('status').updateValueAndValidity();
        itm.get('product_details').updateValueAndValidity();
        itm.get('product_id').updateValueAndValidity();
        itm.get('expected_arrival_date').updateValueAndValidity();
      }
    }, 1000);
  }


  onChangeSearchProduct(value: string) {
    if (value.length >= 3) {
      this.isLoadingProduct = true;
      this._productsService.getProductsList({ searchByName: value, category: this.newForm.value.category_id, isInStock: 1 }).subscribe(
        response => {
          this.isLoadingProduct = false
          if (response.status) {
            this.allProduct = response.data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }

  addProduct(id = "") {
    const productGroup = this._formBuilder.group({
      id: [id || ''],
      status: [this.newForm.get('is_returnable').value || ''],
      product_details: ['', [this.requiredIfFalse.bind(this)]],
      product_id: [0, [this.requiredIfTrue.bind(this)]],
      product_qty: [{ value: 1, disabled: !this.isAddMode }, [Validators.required]],
      expected_arrival_date: ['', this.requiredIfReturnable.bind(this)],
      comments: [null],
    });
    this.productsData.push(productGroup);
  }

  removeProduct(product: any, index: number) {
    if (!product.value.id && this.isAddMode) {
      if (index >= 0 && index < this.productsData.length) {
        this.productsData.removeAt(index);
      }
      return;
    }

    this._gatePassService.removeProduct({
      id: product.value.id
    }).subscribe(
      data => {
        if (data.status) {
          this.newForm = this._formBuilder.group({
            id: [null],
            vendor_id: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
            is_product: ['0', Validators.required],
            is_returnable: ['1', Validators.required],
            transport: [''],
            remarks: [''],
            dispatched_by: [''],
            products_data: this._formBuilder.array([], Validators.required)
          });
          this.newForm.reset();
          this.edit(this.gatePassId);
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
      });

  }

  requiredIfFalse(control) {
    const isProduct = this.newForm.get('is_product').value;
    return (isProduct === '0') ? Validators.required(control) : null;
  }

  requiredIfTrue(control) {
    const isProduct = this.newForm.get('is_product').value;
    return (isProduct === '1') ? Validators.required(control) : null;
  }

  requiredIfReturnable(control) {
    const isReturnable = this.newForm.get('is_returnable').value;
    return (isReturnable === '1') ? Validators.required(control) : null;
  }

  get f() {
    return this.newForm.controls;
  }

  getVendorsDropDown() {
    this._rowMaterialProductService.getVendors().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendors;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }


  edit(id: any) {
    this._gatePassService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.newForm.patchValue(response.data);
          this.newForm.patchValue({ id: id });
          for (let itm of Array.from(response.data.gate_pass_products)) {
            let current: any = itm;
            this.addProduct(current.id);
            if (current.expected_arrival_date) {
              let date = new Date(current.expected_arrival_date);
              let tempDate: NgbDateStruct = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
              this.selectedDates.push(tempDate)
            }
            if (current.product_id) {
              this._productsService.getProductsList({ id: current.product_id }).subscribe(
                response => {
                  this.isLoadingProduct = false
                  if (response.status) {
                    // this.allProduct = response.data;
                    let temp = response.data.shift();
                    this.selectedProductsValue.push(temp);
                  }
                  else {
                    this._toastrService.clear();
                    this._toastrService.error(response.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
                  }
                },
                err => {
                  if (err.error.error == 'Unauthenticated.') {
                    this._authenticationService.logout();
                    this._router.navigate(['/login']);
                  }
                  this._toastrService.clear();
                  this._toastrService.error(err.message, 'Oops!', { toastClass: 'toast ngx-toastr', closeButton: true });
                }
              );
            }
          }
          this.newForm.patchValue({ "products_data": response.data.gate_pass_products });
          this.newForm.updateValueAndValidity()
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  /**
   * On Submit
   */
  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {


    }
    else {

      for (let itm of this.productsData.controls) {
        let expected_arrival_date = itm.get('expected_arrival_date');
        let in_date = null;
        if (expected_arrival_date.value) {
          in_date = `${expected_arrival_date.value['year']}-${('0' + expected_arrival_date.value['month']).slice(-2)}-${('0' + expected_arrival_date.value['day']).slice(-2)}`;
          if (this.newForm.get('is_returnable').value == '0') {
            in_date = null;
          }
        }
        expected_arrival_date.setValue(in_date);
        itm.get('product_qty').enable();

        let product_id = itm.get('product_id');
        if (product_id.value) {
          product_id.setValue(product_id.value.id);
        }
      }

      this.loading = true;
      this._gatePassService.add(this.newForm.value, this.gatePassId).subscribe(
        data => {
          if (data.status) {
            this.gatePassId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/gate-pass']);
            this.loading = false;
          }
          else {

            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.errorMessage;
            }
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
}
