import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { ProductsService } from 'app/_services/products.service';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';

@Component({
  selector: 'app-product-stock-list',
  templateUrl: './product-stock-list.component.html',
  styleUrls: ['./product-stock-list.component.scss']
})
export class ProductStockListComponent implements OnInit {

  @ViewChild('auto') auto: any;

  ordersList: any = [];
  start: any = 0;
  page: any = 0;

  public order_amount = 0;

  public currentUserRole = '';
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public orderDetails: any = [];
  public orderEditDetails: any = [];
  public loading = false;
  public currentUser: any;
  StatusLable: any;
  public newForm: FormGroup;
  public newFormFilter: FormGroup;
  public dowaloadLoder = 0;
  public pageAdvancedEllipses = 1;
  public progressMainTable: boolean = false;

  public submitted = false;
  public loadingForm = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public allDealer: any;

  public employeeDropDown: any = [];
  public selectBasicLoading = false;
  public categoryDropDown: any;

  constructor(private _coreConfigService: CoreConfigService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private productMasterComboService: ProductMasterComboService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
    if (this.currentUserRole == 'User') {
      this._coreConfigService.config = {
        layout: {
          menu: {
            hidden: true
          }
        }
      };
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.orderList(this.start, this.lengths);
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.orderList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.orderList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.orderList(this.start, this.lengths);
  }

  orderList(my_start: any, my_lengths: any) {

    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.productMasterComboService.getStockData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        this.order_amount = 0;
        if (response.status) {
          this.ordersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

}
