import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WarehouseService } from 'app/_services/warehouse.service';
import { CustomvalidationService } from 'app/_services/customvalidation.service';

@Component({
  selector: 'app-warehouse-master',
  templateUrl: './warehouse-master.component.html',
  styleUrls: ['./warehouse-master.component.scss']
})
export class WarehouseMasterComponent implements OnInit {
 
  constructor(private _router: Router, private warehouseService: WarehouseService, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder, private customValidator: CustomvalidationService) { }

  public warehousesList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public submitted: boolean = false;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public warehouseId = '0';
  public error = '';
  public newForm: FormGroup;
  public loading = false;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public locationDetails: any = [];

  ngOnInit(): void {
    this.loading = true;
    this.warehouseLists(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      warehouse_code: ['', Validators.required],
      fullname: ['', Validators.required],
      email: [null, [Validators.email]],
      phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      phone_tow: [null, [Validators.pattern('^[0-9]{10}')]],
      address: [null],
      newPassword: ['', [Validators.compose([this.customValidator.patternValidator()])]],
      confirmPassword: [''],
      items: this._formBuilder.array([])
    }, {
      validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
    });
    this.addItem(); // Add the first item on load
  }
  

  warehouseLists(my_start: any, my_lengths: any) {
    this.warehouseService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.warehousesList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  // modal Basic
  modalOpen(modalBasic, id) {
    if(id == ''){
      this.modalService.open(modalBasic, {
        size: 'lg'
      });
    }else{
      this.warehouseService.edit(id).subscribe(
        response => {
          if (response.status) {
            this.warehouseId = response.warehouse.id;
            this.f.warehouse_code.setValue(response.warehouse.warehouse_code);
            this.f.fullname.setValue(response.warehouse.name);
            this.f.email.setValue(response.warehouse.email);
            this.f.phone.setValue(response.warehouse.mobile);
            this.f.phone_tow.setValue(response.warehouse.phone_tow);
            this.f.address.setValue(response.warehouse.address);
            this.f.newPassword.setValue('');
            this.f.confirmPassword.setValue('');

            this.items.clear();
            response.warehouse.location.forEach((value: any) => {
              const itemGroup = this._formBuilder.group({
                id: [value.id],
                name: [value.location_name || '', Validators.required], 
                description: [value.location_address || ''],
              });
              this.items.push(itemGroup);
            });

            if (response.warehouse.location.length > 0) {
               this.removeItem(0);
            }

            this.modalService.open(modalBasic, {
              size: 'lg'
            });

          }else{
            this._toastrService.error(response.message, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        }, err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  viewModalOpen(modalBasicView,id:any) {
    this.warehouseService.show(id).subscribe(
      response => {
        if (response.status) {
          this.locationDetails = response.location;
          this.modalService.open(modalBasicView, {
            size: 'lg'
          });
        }else{
          this._toastrService.error(response.message, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      }, err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.warehouseService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.warehouseLists(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Warehouse Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.warehouseLists(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.warehouseLists(this.page, this.lengths);
  }

  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.warehouseLists(this.start, this.lengths);
  }

  get f() {
    return this.newForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      return; // Stop if the form is invalid
    }else{
      this.loading = true;
      const formData = new FormData();
      formData.append('id', this.warehouseId);
      formData.append('warehouse_code', this.f.warehouse_code.value);
      formData.append('fullname', this.f.fullname.value);
      formData.append('email', this.f.email.value);
      formData.append('phone', this.f.phone.value);
      formData.append('phone_tow', this.f.phone_tow.value);
      formData.append('address', this.f.address.value);
      formData.append('password', this.f.newPassword.value);

      this.items.controls.forEach((item, index) => {
        formData.append('l_id[]', item.get('id')?.value);
        formData.append('name[]', item.get('name')?.value);
        formData.append('description[]', item.get('description')?.value);
      });
      
      this.warehouseService.add(formData, this.warehouseId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.warehouseId = '0';
            this.submitted = false;
            this.newForm.reset();
            this.modalService.dismissAll('modalBasic');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this.error = data.error;
          }
        },err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  /**
   * Toggle password
   */
  togglePasswordTextType(me) {
    if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }


  get items(): FormArray {
    return this.newForm.get('items') as FormArray;
  }

  addItem(): void {
    const itemGroup = this._formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
    });
    this.items.push(itemGroup);
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }
}
