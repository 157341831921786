<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12 text-center pt-3">
                    <h1>Coming Soon</h1>
                </div>
            </div>
        </section>
    </div>
</div>