import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-warehouse`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-warehouse`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(formData: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-warehouse`, formData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-warehouse`,  formData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  show(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}show-warehouse`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-warehouse`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  warehouseDropdown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-warehouse-dropdown`,{})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  
}
