<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Department List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="addDepartment()">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>PHONE NO</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="departmentsList?.length != 0">
                                        <tr *ngFor="let departments of departmentsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ departments.name }}</td>
                                            <td>{{ departments.mobile }}</td>
                                            <td>{{ departments.email }}</td>
                                            <td>
                                                <span class="w-100 badge badge-pill badge-warning mr-1" *ngIf="departments.role == 4">General Manager</span>
                                                <span class="w-100 badge badge-pill badge-success mr-1" *ngIf="departments.role == 5">Purchase</span>
                                                <span class="w-100 badge badge-pill badge-info mr-1" *ngIf="departments.role == 6">Store</span>
                                                <span class="w-100 badge badge-pill badge-primary mr-1" *ngIf="departments.role == 7">Production</span>
                                                <span class="w-100 badge badge-pill badge-secondary mr-1" *ngIf="departments.role == 9">Sales & Marketing</span>
                                                <span class="w-100 badge badge-pill badge-light-info mr-1" *ngIf="departments.role == 14">HR</span>
                                                <span class="w-100 badge badge-pill badge-light-success mr-1" *ngIf="departments.role == 15">Account</span>
                                                <span class="w-100 badge badge-pill badge-light-secondary mr-1" *ngIf="departments.role == 10">Molding</span>
                                                <span class="w-100 badge badge-pill badge-light-danger mr-1" *ngIf="departments.role == 11">Tufting</span>
                                                <span class="w-100 badge badge-pill badge-light-warning mr-1" *ngIf="departments.role == 12">Packaging & Assembly</span>

                                                <span class="w-100 badge badge-pill badge-light-primary mr-1" *ngIf="departments.role == 13">Dispatch</span>

                                                <span class="w-100 badge badge-pill badge-secondary badge-glow mr-1" *ngIf="departments.role == 16">Molding Planning</span>
                                                <span class="w-100 badge badge-pill badge-danger badge-glow mr-1" *ngIf="departments.role == 17">Tufting Planning</span>
                                                <span class="w-100 badge badge-pill badge-warning badge-glow mr-1" *ngIf="departments.role == 18">Packaging Planning</span>

                                                <span class="w-100 badge badge-pill badge-info badge-glow mr-1" *ngIf="departments.role == 19">Planning</span>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem (click)="toggleSidebar('new-sidebar',departments.id,ndx)"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(departments.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="departmentsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > lengths" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                <!-- <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Department</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">


                                <div class="form-group">
                                    <label for="register-departmentName" class="form-label">Department Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="departmentName" class="form-control" placeholder="Department Name" aria-describedby="register-departmentName" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.departmentName.errors }" />
                                    <div *ngIf="submitted && f.departmentName.errors" class="invalid-feedback">
                                        <div *ngIf="f.departmentName.errors.required">Department Name is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-departmentMobile" class="form-label">Mobile<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="departmentMobile" class="form-control" placeholder="Mobile" aria-describedby="register-departmentMobile" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.departmentMobile.errors }" />
                                    <div *ngIf="submitted && f.departmentMobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.departmentMobile.errors.required">Mobile is required</div>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label for="register-departmentEmail" class="form-label">Email<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="departmentEmail" class="form-control" placeholder="Email" aria-describedby="register-departmentEmail" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.departmentEmail.errors }" />
                                    <div *ngIf="submitted && f.departmentEmail.errors" class="invalid-feedback">
                                        <div *ngIf="f.departmentEmail.errors.required">Email is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-departmentRole" class="form-label">Select Role<span class="text-danger">*</span></label>
                                    <ng-select formControlName="departmentRole" aria-describedby="register-departmentRole" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.departmentRole.errors }">
                                        <ng-option [value]="4">General Manager</ng-option>
                                        <ng-option [value]="5">Purchase</ng-option>
                                        <ng-option [value]="6">Store</ng-option>
                                        <ng-option [value]="7">Production</ng-option>
                                        <ng-option [value]="9">Sales & Marketing</ng-option>
                                        <ng-option [value]="10">Molding</ng-option>
                                        <ng-option [value]="11">Tufting</ng-option>
                                        <ng-option [value]="12">Packaging & Assembly</ng-option>
                                        <ng-option [value]="13">Dispatch</ng-option>
                                        <ng-option [value]="14">HR</ng-option>
                                        <ng-option [value]="15">Account</ng-option>
                                        <ng-option [value]="16">Molding Planning</ng-option>
                                        <ng-option [value]="17">Tufting Planning</ng-option>
                                        <ng-option [value]="18">Packaging Planning</ng-option>
                                        <ng-option [value]="19">Planning</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.departmentRole.errors" class="invalid-feedback">
                                        <div *ngIf="f.departmentRole.errors.required">Category is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <label for="register-newPassword" class="form-label">Password<span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                        <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="newPassword" class="form-control form-control-merge" tabindex="5" [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" placeholder="············" aria-describedby="register-newPassword"
                                            autocomplete="off" />

                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer">
                                          <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(2)" ></i>
                                        </span>
                                        </div>
                                        <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.newPassword.errors.required">Password is required</div>
                                            <div *ngIf="f.newPassword.touched && f.newPassword.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <label for="register-confirmPassword" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                        <input [type]="passwordTextType3 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" tabindex="6" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" placeholder="············" aria-describedby="register-confirmPassword"
                                            autocomplete="off" />
                                        <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer">
                                          <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType3, 'icon-eye': !passwordTextType3 }" (click)="togglePasswordTextType(3)" ></i>
                                        </span>
                                        </div>
                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->