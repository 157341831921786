<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Raw Material Product List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-2 col-16">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="col-md-6 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">

                                        <ng-select formControlName="is_moq" name="is_moq" placeholder="MOQ Level"
                                            (change)="getmoqFilter($event)" id="is_moq" class="mr-1 w-50">
                                            <ng-option [value]="" selected>All</ng-option>
                                            <ng-option [value]="1" selected>MOQ Level</ng-option>

                                        </ng-select>

                                        <input type="text" [(ngModel)]="searchByName" class="form-control"
                                            style="border-radius: 10px 0px 0px 10px;"
                                            placeholder="Search by Raw Material name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;"
                                            (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                    </div>
                                </div>

                                <div
                                    class=" col-12 col-sm-12 col-md-4 col-lg-4 d-flex justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar')" data-toggle="tooltip"
                                            data-popup="tooltip-custom" placement="top" container="body"
                                            ngbTooltip="Add New">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>

                                        <div ngbDropdown  container="body" *ngIf="currentUserRole == 'Client'">
                                            <button ngbDropdownToggle class="btn btn-gradient-warning ml-1" type="button" id="dropdownMenuButton101">
                                                Other
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                <a ngbDropdownItem  routerLink="/raw-material-maintain">Stock Maintain</a>
                                                <a ngbDropdownItem  (click)="excelDownload()">Excel Download</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0"
                                        *ngIf="currentUserRole == 'Client'">
                                        <a class="btn btn-warning ml-1" routerLink="/raw-material-maintain" rippleEffect
                                            data-toggle="tooltip" data-popup="tooltip-custom" placement="top"
                                            container="body" ngbTooltip="Stock Maintain">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Stock Maintain</span>
                                        </a>
                                    </div> -->
                                </div>

                                <div
                                    class="col-12 d-sm-none d-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <input type="text" [(ngModel)]="searchByName" class="form-control"
                                            style="border-radius: 10px 0px 0px 10px;"
                                            placeholder="Search by Raw Material name..." />
                                        <button class="btn btn-primary" style="border-radius: 0 10px 10px 0;"
                                            (click)="getRawMaterialFilter(searchByName)">GO!</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th class="action-column">#</th>
                                            <th>Category</th>
                                            <th>Name</th>
                                            <th>Unit</th>
                                            <!-- <th>Price</th> -->
                                            <th>Description</th>
                                            <th>Current Stock</th>
                                            <th>MOQ Level</th>
                                            <th class="action-column">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="rowMaterialProductList?.length != 0">
                                        <tr *ngFor="let rowMaterialProduct of rowMaterialProductList; let ndx = index">
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ndx+page+1}}</td>
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ rowMaterialProduct.category_name }}</td>
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ rowMaterialProduct.product_name }}</td>
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ rowMaterialProduct.unit }}</td>
                                            <!-- <td>{{ rowMaterialProduct.price }}</td> -->
                                            <td [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }"
                                                *ngIf="rowMaterialProduct.description != null && rowMaterialProduct.description != 'null'">
                                                {{ rowMaterialProduct.description }}</td>
                                            <td [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }"
                                                *ngIf="rowMaterialProduct.description == null || rowMaterialProduct.description == 'null'">
                                            </td>
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ rowMaterialProduct.current_stock }}</td>
                                            <td
                                                [ngClass]="{ 'text-danger': (rowMaterialProduct.current_stock <= rowMaterialProduct.moq_level) }">
                                                {{ rowMaterialProduct.moq_level }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow"
                                                        ngbDropdownToggle data-toggle="dropdown">
                                                        <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>

                                                        <a ngbDropdownItem
                                                            (click)="toggleSidebar('new-sidebar',rowMaterialProduct.id,ndx)"><i
                                                                data-feather="edit-2"
                                                                class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="delete(rowMaterialProduct.id)"><i
                                                                data-feather="trash"
                                                                class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="rowMaterialProductList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize"
                                    *ngIf="collectionSize > 30" [(page)]="pageBasicText" [maxSize]="5"
                                    (pageChange)="loadPage($event)" [pageSize]="lengths"
                                    [rotate]="true"></ngb-pagination>
                                <!-- <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog modal-xl">
                <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Raw Material Product</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label>Row Material Category<span class="text-danger">*</span></label>
                                    <ng-select [items]="rowMaterialCategoryDropDown"
                                        (change)="getRowMaterialSubCategoriesDropDown($event);"
                                        id="row_material_category_id" [clearable]="false" bindValue="id"
                                        bindLabel="category_name" formControlName="row_material_category_id"
                                        placeholder="Select Row Material Category"
                                        [ngClass]="{ 'is-invalid': submitted && f.row_material_category_id.errors }">
                                    </ng-select>
                                    <div *ngIf="submitted && f.row_material_category_id.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.row_material_category_id.errors.required">
                                            Row Material Category is required
                                        </div>
                                    </div>
                                </div>

                                <!--  <div class="form-group">
                                    <label>Sub Category<span class="text-danger">*</span></label>
                                    <ng-select name="rowMaterialSubCategoryId" formControlName="rowMaterialSubCategoryId" tabindex="1" aria-describedby="register-rowMaterialSubCategoryId">
                                        <ng-option [value]="" selected>Select Sub Category</ng-option>
                                        <ng-option *ngFor="let rowMaterialSubCategory of rowMaterialSubCategoryDropDown" [value]="rowMaterialSubCategory.id">{{rowMaterialSubCategory.sub_category_name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.rowMaterialSubCategoryId.errors" class="invalid-feedback">
                                        <div *ngIf="f.rowMaterialSubCategoryId.errors.required">Sub Category is required</div>
                                    </div>
                                </div> -->

                                <div class="form-group">
                                    <label for="register-rowmaterialName" class="form-label">Name<span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="rowmaterialName"
                                        placeholder="Enter Name" aria-describedby="register-rowmaterialName"
                                        [ngClass]="{ 'is-invalid': submitted && f.rowmaterialName.errors }" />
                                    <div *ngIf="submitted && f.rowmaterialName.errors" class="invalid-feedback">
                                        <div *ngIf="f.rowmaterialName.errors.required">Name is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-unit" class="form-label">Unit</label>
                                    <select formControlName="unit" class="form-control" placeholder="Select Unit"
                                        aria-describedby="register-unit" tabindex="11"
                                        [ngClass]="{ 'is-invalid': submitted && f.unit.errors }">
                                        <option value="" selected>Select Unit</option>
                                        <option *ngFor="let unit of unitDropDown" [value]="unit.unit_name">
                                            {{unit.unit_name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.unit.errors" class="invalid-feedback">
                                        <div *ngIf="f.unit.errors.required">Unit is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="products_id" class="form-label">Select Products<span
                                            class="text-danger">*</span></label>
                                    <ng-select formControlName="products_id" multiple="true"
                                        aria-describedby="products_id" tabindex="1"
                                        [ngClass]="{ 'is-invalid': submitted && f.products_id.errors }">
                                        <ng-option disabled selected value="0">Select Products</ng-option>
                                        <ng-option *ngFor="let product of productsDropDown"
                                            [value]="product.id">{{product.full_name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.products_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.products_id.errors.required">Products is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-useQuantity" class="form-label">Use Quantity<span
                                            class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="useQuantity"
                                        placeholder="Enter Use Quantity" aria-describedby="register-useQuantity"
                                        [ngClass]="{ 'is-invalid': submitted && f.useQuantity.errors }" />
                                    <div *ngIf="submitted && f.useQuantity.errors" class="invalid-feedback">
                                        <div *ngIf="f.useQuantity.errors.required">Use Quantity is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-current_stock" class="form-label">Current Stock<span
                                            class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="current_stock"
                                        placeholder="Enter Current Stock" aria-describedby="register-current_stock"
                                        [ngClass]="{ 'is-invalid': submitted && f.current_stock.errors }" />
                                    <div *ngIf="submitted && f.current_stock.errors" class="invalid-feedback">
                                        <div *ngIf="f.current_stock.errors.required">Current Stock is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-moq_level" class="form-label">MOQ Level<span
                                            class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="moq_level"
                                        placeholder="Enter MOQ Level" aria-describedby="register-moq_level"
                                        [ngClass]="{ 'is-invalid': submitted && f.moq_level.errors }" />
                                    <div *ngIf="submitted && f.moq_level.errors" class="invalid-feedback">
                                        <div *ngIf="f.moq_level.errors.required">MOQ Level is required</div>
                                    </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label for="register-rowmaterialPrice" class="form-label">Price<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="rowmaterialPrice" placeholder="Enter Price" aria-describedby="register-rowmaterialPrice" [ngClass]="{ 'is-invalid': submitted && f.rowmaterialPrice.errors }" />
                                    <div *ngIf="submitted && f.rowmaterialPrice.errors" class="invalid-feedback">
                                        <div *ngIf="f.rowmaterialPrice.errors.required">Price is required</div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label for="register-rowmaterialDescription" class="form-label">Description</label>
                                    <textarea rows="4" class="form-control" formControlName="rowmaterialDescription"
                                        placeholder="Enter Description"
                                        aria-describedby="register-rowmaterialDescription"
                                        [ngClass]="{ 'is-invalid': submitted && f.rowmaterialDescription.errors }"></textarea>
                                    <div *ngIf="submitted && f.rowmaterialDescription.errors" class="invalid-feedback">
                                        <div *ngIf="f.rowmaterialDescription.errors.required">Description is required
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                                <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                    [disabled]="loadingForm"> <span *ngIf="loadingForm "
                                        class="spinner-border spinner-border-sm mr-1 "></span>Submit</button>
                                <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')"
                                    [disabled]="loadingForm" rippleEffect>
                                    <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->