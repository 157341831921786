<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Warehouse List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect (click)="modalOpen(modalBasic,'')">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Code</th>
                                            <th> Name</th>
                                            <th> Email</th>
                                            <th> Phone </th>
                                            <th> Address </th>
                                            <th> Added Date</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="warehousesList?.length != 0">
                                        <tr *ngFor="let warehouse of warehousesList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ warehouse.warehouse_code }}</td>
                                            <td>{{ warehouse.name }}</td>
                                            <td>{{ warehouse.email }}</td>
                                            <td>{{ warehouse.mobile }} / {{ warehouse.phone_tow }}</td>
                                            <td>{{ warehouse.address }}</td>
                                            <td>{{ warehouse.addedat }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem (click)="modalOpen(modalBasic,warehouse.id)"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem (click)="viewModalOpen(modalBasicView,warehouse.id)"><i data-feather="eye" class="mr-50"></i><span>View Location</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(warehouse.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="warehousesList?.length == 0">
                                        <tr *ngIf="!loading" class="text-center">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > lengths" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Warehouse</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-12 pt-1 overflow-auto">
                <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">

                        <div class="form-group col-lg-3 ">
                            <label for="register-warehouse_code" class="form-label">Code<span class="text-danger">*</span></label>
                            <input type="text" formControlName="warehouse_code" class="form-control" placeholder="Code" aria-describedby="register-warehouse_code" tabindex="1" [ngClass]="{ 'is-invalid': submitted && f.warehouse_code.errors }" />
                            <div *ngIf="submitted && f.warehouse_code.errors" class="invalid-feedback">
                                <div *ngIf="f.warehouse_code.errors.required">Full Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-9 ">
                            <label for="register-fullname" class="form-label">Full Name<span class="text-danger">*</span></label>
                            <input type="text" formControlName="fullname" class="form-control" placeholder="Fullname" aria-describedby="register-fullname" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" />
                            <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                                <div *ngIf="f.fullname.errors.required">Full Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-4 ">
                            <label for="register-email" class="form-label">Email<span class="text-danger">*</span></label>
                            <input type="text" formControlName="email" class="form-control" placeholder="Email" aria-describedby="register-email" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Please enter valid email</div>

                            </div>
                        </div>

                        <div class="form-group col-lg-4 ">
                            <label for="register-phone" class="form-label">Phone Number 1<span class="text-danger">*</span></label>
                            <input type="text" formControlName="phone" class="form-control" placeholder="Phone Number 1" aria-describedby="register-phone" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-4 ">
                            <label for="register-phone_tow" class="form-label">Phone Number 2</label>
                            <input type="text" formControlName="phone_tow" class="form-control" placeholder="Phone Number 2" aria-describedby="register-phone_tow" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.phone_tow.errors }" />
                            <div *ngIf="submitted && f.phone_tow.errors" class="invalid-feedback">
                                <div *ngIf="f.phone_tow.errors.pattern">Please Provide Valid Mobile No.</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6">
                            <label for="register-address" class="form-label">Address</label>
                            <textarea rows="4" formControlName="address" class="form-control" placeholder="Address" aria-describedby="register-address" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <div class="col-lg-12 p-0">
                                    <label for="register-newPassword" class="form-label">Password<span class="text-danger">*</span></label>
                                </div>
                                <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                    <input [type]="passwordTextType2 ? 'text' : 'password'" formControlName="newPassword" class="form-control form-control-merge" tabindex="7" [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" placeholder="············" aria-describedby="register-newPassword"
                                        autocomplete="off" />

                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType2, 'icon-eye': !passwordTextType2 }" (click)="togglePasswordTextType(2)" ></i>
                                </span>
                                    </div>
                                    <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.newPassword.errors.required">Password is required</div>
                                        <div *ngIf="f.newPassword.touched && f.newPassword.errors?.invalidPassword"> Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">

                                <div class="col-lg-12 p-0">
                                    <label for="register-confirmPassword" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                                </div>
                                <div class="col-lg-12 p-0 input-group input-group-merge form-password-toggle">
                                    <input [type]="passwordTextType3 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" tabindex="8" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" placeholder="············" aria-describedby="register-confirmPassword"
                                        autocomplete="off" />

                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer">
                                  <i class="feather font-small-4" [ngClass]="{ 'icon-eye-off': passwordTextType3, 'icon-eye': !passwordTextType3 }" (click)="togglePasswordTextType(3)" ></i>
                                </span>
                                    </div>
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                        <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors?.passwordMismatch">Passwords does not match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" formArrayName="items">
                            <div class="row" *ngFor="let item of items.controls; let i = index" [formGroupName]="i" >
                                <div class="col-5">
                                    <div class="form-group">
                                        <label>Location Name<span class="text-danger">*</span></label>
                                        <input type="text" formControlName="name" class="form-control" placeholder="Location name"  [ngClass]="{ 'is-invalid': submitted && item.get('name').errors }" />
                                        <div *ngIf="submitted && item.get('name').errors" class="invalid-feedback">
                                            <div *ngIf="item.get('name').errors.required">Location Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="form-group">
                                        <label>Location Address</label>
                                        <input formControlName="description" class="form-control" placeholder="Location Address" />
                                    </div>
                                </div>
                                <div class="col-2"  *ngIf="i != 0">
                                    <div class="form-group ">
                                        <label class="text-white">-</label><br>
                                        <button type="button" class="btn btn-danger" (click)="removeItem(i)">
                                            <i  data-feather="trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <button type="button" class="btn btn-outline-success float-right" (click)="addItem()"><i  data-feather="plus"></i> Add More</button>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-12 col-xs-12 col-lg-12">
                            <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                <div class="alert-body">
                                    <p>{{ error }}</p>
                                </div>
                            </ngb-alert>
                        </div>
                        <div class="col-12 col-sm-6 col-xs-6 col-lg-12 pt-1 text-right">
                            <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
                                Close
                            </button>
    
                            <button [disabled]="loading" class="btn btn-primary ml-2" tabindex="9" rippleEffect>
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modalBasicView let-modals>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Warehouse Location</h4>
        <button type="button"  (click)="modals.dismiss('Cross click')" class="close"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="locationDetails?.length != 0">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2"  *ngFor="let lo of locationDetails; let ldx = index">
                <div class="d-flex align-items-center">
                    <div class="avatar bg-light-danger avatar-lg mr-1">
                        <span class="avatar-content"><i data-feather="home" [size]="20"></i></span>
                    </div>
                    <h6 class="mb-0">{{lo.location_name}}</h6>
                    <p class="mb-0">{{lo.location_address}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Modal -->